import React from 'react'

import kopi from '../../apis/kopi'

class AddTopic extends React.Component{

    state = {title: '', description: '', level: ''}

    componentDidMount = async () => {
        if (this.props.mode === 'Edit'){
            const response = await kopi.get('/admin/gettopic', {params: {topicId: this.props.topicId}})
            const {title, description, level} = response.data
            this.setState({title, description, level})
        }
    }

    onSaveClick = async () => {
        let response
        if (this.props.mode === 'Add'){
            response = await kopi.post('/admin/addtopic', {title: this.state.title, description: this.state.description, level: this.state.level})
        } else if (this.props.mode === 'Edit'){
            response = await kopi.post('/admin/edittopic', {topicId: this.props.topicId, title: this.state.title, description: this.state.description, level: this.state.level})
        }
        if (response){
            this.props.updateTopics(response.data)
            this.props.changePage(0)
        }
    }

    render(){
        return (
            <div className="container">
                <h1>{`${this.props.mode} Topic`}</h1>
                <hr/>
                <form>
                    <div class="form-group">
                        <label htmlFor="formGroupExampleInput">Title</label>
                        <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.title} placeholder="Enter topic title" onChange={(event) => this.setState({title: event.target.value})}/>
                    </div>
                    <div class="form-group">
                        <label htmlFor="formGroupExampleInput2">Level</label>
                        <input type="text" class="form-control" id="formGroupExampleInput2" value={this.state.level} placeholder="Enter topic level (1-4)" onChange={(event) => this.setState({level: event.target.value})}/>
                    </div>
                    <div class="form-group">
                        <label htmlFor="formGroupExampleInput3">Description</label>
                        <textarea type="text" class="form-control" id="formGroupExampleInput3" value={this.state.description} placeholder="Enter topic description" onChange={(event) => this.setState({description: event.target.value})}/>
                    </div>
                </form>
                <br/>
                <div className="row" style={{marginLeft: "0px", marginRight: "0px"}}>
                    <button className="btn btn-info btn-lg" onClick={this.onSaveClick}>Save</button> &nbsp; &nbsp;
                    <button className="btn btn-secondary btn-lg" onClick={()=> this.props.changePage(0)}>Cancel</button>
                </div>
            </div>
        )
    }
}

export default AddTopic