import React from 'react'
import { InlineTex } from 'react-tex';
import { Tex } from 'react-tex';
import TextareaAutosize from 'react-autosize-textarea';

import '../Author.css';
import Solutions from './Solutions'
import LOModal from './LOModal'
import Latex from './Latex'

import kopi from '../../apis/kopi'
import Dropdown from './Dropdown';
import history from '../../history'

class QuestionCard extends React.Component {
    state = {
        showSolutions: false,
        openModal: false,
        objectives: [],
        difficultyIsOpen: false,
        showProTip: false,
        validAnswer: true
    }

    componentDidMount = async () => {
        if (this.props.qn.has_sol) {
            this.setState({ showSolutions: true }) // keeps track of whether the particular qn card has a solution
        }
        if (this.props.qn.ans) {
            this.validateCorrectAns(this.props.qn.ans)
        }
    }

    //=======================================================================================

    // Adding and deleting parts
    addPart = () => {
        this.props.addPart()
    }

    deletePart = async () => {
        const part = String.fromCharCode('a'.charCodeAt(0) + this.props.indexArray[this.props.index])
        const confirm = window.confirm(`Are you sure you want to delete part ${part}?`)
        if (confirm) {
            this.props.deletePart(this.props.index)
            this.props.autodelete(this.props.qn.id)
        }
    }

    //=======================================================================================

    // Indicates whether a solution has been added to the particular part
    addSolution = async (event) => {
        if (this.props.qn.qn_type) {
            this.setState({ showSolutions: !this.state.showSolutions })
            this.props.updateState(true, 'has_sol', this.props.index)
            await this.props.autosave(event.target.value, event.target.name, this.props.qn.id)
        } else {
            alert('Please select a question type.')
        }
    }

    //=======================================================================================

    // Add LO on click handler
    onLOClick = () => {
        this.props.onLOClick()
        this.props.selected_topics.length ? this.setState({ openModal: !this.state.openModal }) : alert('Please select a topic.')
    }

    //=======================================================================================

    // Add handler for protip to user

    _handleKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.showProTip === false) {
            this.setState({ showProTip: "Type \\\\ within the \$ \$ to start on a new line." });
        } else {
            this.setState({ showProTip: false });

        }
    }

    //=======================================================================================

    // Save question type to state and db
    saveType = async (event) => {
        event.persist()
        this.props.updateState(event.target.value, event.target.name, this.props.index)
        await this.props.autosave(event.target.value, event.target.name, this.props.qn.id)
        this.validateCorrectAns(this.props.qn.ans)

    }

    saveDifficulty = async (event) => {
        event.persist()
        this.setState({ difficultyIsOpen: !this.state.difficultyIsOpen })
        this.props.updateState(Number(event.target.value), event.target.name, this.props.index)
        await this.props.autosave(Number(event.target.value), event.target.name, this.props.qn.id)
    }

    addPrevLOs = async () => {
        this.onLOClick()
        let prevIndex = 0
        const index = this.props.indexArray.findIndex(i => i === this.props.index)
        const indexArray = this.props.indexArray.slice(0, index)
        prevIndex = indexArray.reverse().findIndex(curIndex => curIndex !== null)
        prevIndex = indexArray.length - 1 - prevIndex
        // console.log(prevIndex)

        this.props.updateState(this.props.qns[prevIndex].objectives, 'objectives', this.props.index)
        this.props.updateState(this.props.qns[prevIndex].subobjectives, 'subobjectives', this.props.index, null, true)

        const parentIds = this.props.qns[prevIndex].objectives.map(obj => obj._id)
        const childIds = this.props.qns[prevIndex].subobjectives.map(subobj => subobj._id)

        await kopi.post('/authoring/saveLOs', { qnId: this.props.qn.id, parentIds: parentIds, childIds: childIds, first_part: this.props.qns[0].id })
    }

    validateCorrectAns = (ans) => {
        const re = new RegExp('^[-+]?[0-9]+(\.[0-9]+)?$')
        const match = ans.match(re)
        if (!match && this.props.qn.qn_type === 'text') {
            this.setState({ validAnswer: false })
            this.props.getValidAns(match, this.props.index)
        } else {
            this.setState({ validAnswer: true })
            this.props.autosave(ans, 'ans', this.props.qn.id)
            this.props.getValidAns(ans, this.props.index)
        }
    }

    //=======================================================================================

    // Renders "Part (x)" on question card
    showPart = () => {
        if (this.props.isMulti(this.props.qns)) {
            const part = String.fromCharCode('a'.charCodeAt(0) + this.props.indexArray[this.props.index])
            return <div><h4>{`Part (${part})`}
            </h4>
                <small style={{color: 'white'}}>{this.props.qn.original_question}</small>
            </div>
        } else {
            return <span></span>
        }
    }

    //=======================================================================================

    // Rendering delete button on question card
    renderX = () => {
        if (this.props.index) {  // does not render 'x' button on part (a)
            return <div><span name={this.props.index} className="close x-btn" onClick={this.deletePart}>x</span><br /></div>
        } else {
            return <span></span>
        }
    }

    //=======================================================================================

    // Rendering lis tof selected LOs on question card
    renderSubobjList = (obj) => {
        return this.props.qn.subobjectives.map(subobj => {
            if (obj._id === subobj.objective) {
                return <li key={subobj._id}><small><InlineTex texSeperator={"\${1,2}"} texContent={subobj.title} /></small><br /></li>
            }
        })
    }

    renderObjList = (topic) => {
        return this.props.qn.objectives.map(obj => {
            const objIds = topic.objectives.map(obj => obj._id)
            if (objIds.includes(obj._id)) {
                return (
                    <span key={obj._id}>
                        <strong><Latex latex={obj.title} /></strong>
                        <ul>
                            {this.renderSubobjList(obj)}
                        </ul>
                    </span>
                )
            }
        })
    }

    renderTopicList = () => {
        if (this.props.qn && this.props.qn.subobjectives.length) {
            return this.props.selected_topics.map(topic => {
                let showTopic = false
                const objIds = topic.objectives.map(obj => obj._id)
                this.props.qn.objectives.forEach(obj => {
                    if (objIds.includes(obj._id)) {
                        showTopic = true
                    }
                })
                if (showTopic) {
                    return (
                        <div key={topic._id}>
                            <h5>{topic.title}</h5>
                            {this.renderObjList(topic)}
                        </div>
                    )
                }
            })
        }
    }

    // Renders the entire LO section on question card
    renderLO = () => {
        return (
            <div>
                <hr />
                <button type="button" className="btn btn-dark btn-block" data-toggle="modal" data-backdrop="static" data-target="#exampleModalLong" onClick={this.onLOClick}>
                    Add Learning Objectives
                </button>
                <br />
                {this.renderTopicList()}
                <hr />
            </div>
        )
    }

    //=======================================================================================

    // Renders question type selector
    renderQnTypeToggle = () => {
        const text = this.props.qn.qn_type === 'text' ? ' active' : ''
        const mcq = this.props.qn.qn_type === 'mcq' ? ' active' : ''
        const selfAssess = this.props.qn.qn_type === 'self' ? ' active' : ''
        return (
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className={`btn btn-secondary${text}`}>
                    <input type="radio" name="qn_type" id="option" value="text" autoComplete="off" onClick={this.saveType} /> Text
                </label>
                <label className={`btn btn-secondary${mcq}`}>
                    <input type="radio" name="qn_type" id="option" value="mcq" autoComplete="off" onClick={this.saveType} /> MCQ
                </label>
                <label className={`btn btn-secondary${selfAssess}`}>
                    <input type="radio" name="qn_type" id="option" value="self" autoComplete="off" onClick={this.saveType} /> Self Assessment
                </label>
            </div>
        )
    }

    renderDifficultyItem = () => {
        return ['Foundational', 'Easy', 'Intermediate', 'Advanced', 'Challenging'].map((level, index) => {
            return <button key={level} className="dropdown-item" type="button" name="difficulty" value={index == 0 ? 0.5 : index} onClick={this.saveDifficulty} onBlur={() => this.setState({ difficultyIsOpen: false })}>{level}</button>
        })
    }

    renderDifficulty = () => {
        const difficultyArray = ['Foundational', 'Easy', 'Intermediate', 'Advanced', 'Challenging']
        const difficultyDropdownDisplay = this.props.qn.difficulty ? difficultyArray[this.props.qn.difficulty == 0.5 ? 0 : this.props.qn.difficulty] : 'Select Difficulty Level'
        return <Dropdown display={difficultyDropdownDisplay}
            isOpen={this.state.difficultyIsOpen}
            updateFlag={() => this.setState({ difficultyIsOpen: !this.state.difficultyIsOpen })}
            closeMenu={() => this.setState({ difficultyIsOpen: false })}
            renderListItem={this.renderDifficultyItem}
            color="btn-dark" />
    }

    renderComment = () => {
        if (this.props.edit) {
            return (
                <div style={{ color: "red" }}>
                    <br />
                    <strong>Question Comments</strong>
                    <TextareaAutosize
                        rows={3}
                        className="form-control"
                        type="text"
                        placeholder="enter comments"
                        name="question_comments"
                        value={this.props.qn.question_comments}
                        onChange={(e) => this.props.updateState(e.target.value, e.target.name, this.props.index)}
                        onBlur={(e) => this.props.autosave(e.target.value, e.target.name, this.props.qn.id)}>
                    </TextareaAutosize>
                </div>
            )
        }
    }

    renderWeightage = () => {
        if (this.props.userId === '5cde0767f1a1203958228704') {
            return <>
                <div className="form-group">
                            Marks
                        <input className="form-control" type="number" placeholder="Enter marks" name="weightage" value={this.props.qn.weightage}
                            onChange={(e) => this.props.updateState(e.target.value, e.target.name, this.props.index)}
                            onBlur={(e) => this.props.autosave(e.target.value, e.target.name, this.props.qn.id)}
                            onKeyDown={this._handleKeyDown}>
                        </input>  
                        </div>
            </>
        }
        
    }

    //=======================================================================================

    // Renders the entire question card
    renderCard = () => {
        return (
            <div className="card">
                <div className="card-body">
                    {this.renderX()}
                    {this.showPart()}
                    <fieldset disabled={this.props.edit && window.location.pathname.slice(1, 7) === 'lol'}>
                        {this.renderLO()}
                        {this.renderDifficulty()}
                        <br/>
                        {this.renderWeightage()}
                        <br />
                        <hr />
                        <span className="row justify-content-end">
                            <span className="text-right"><button className="btn btn-secondary btn-sm" onClick={(e) => this.props.updateState(`${this.props.qn.qn_txt} \$x\$`, "qn_txt", this.props.index)}>{`\$x\$`}</button></span>&nbsp;
                    <span className="text-right"><button className="btn btn-secondary btn-sm" onClick={(e) => this.props.updateState(`${this.props.qn.qn_txt} \$\\times\$`, "qn_txt", this.props.index)}>{`\$\\times\$`}</button></span>&nbsp;
                    <span className="text-right"><button className="btn btn-secondary btn-sm" onClick={(e) => this.props.updateState(`${this.props.qn.qn_txt} \$\\div\$`, "qn_txt", this.props.index)}>{`\$\\div\$`}</button></span>&nbsp;
                    <span className="text-right"><button className="btn btn-secondary btn-sm" onClick={(e) => this.props.updateState(`${this.props.qn.qn_txt} \$\\frac{1}{2}\$`, "qn_txt", this.props.index)}>{`\$\\frac{1}{2}\$`}</button></span>&nbsp;
                    <span className="text-right"><button className="btn btn-secondary btn-sm" onClick={(e) => this.props.updateState(`${this.props.qn.qn_txt} \$\\sqrt[3]{x}\$`, "qn_txt", this.props.index)}>{`\$\\sqrt[3]{x}\$`}</button></span>&nbsp; &nbsp;
                    </span>
                        <div className="form-group">
                            Question
                        <TextareaAutosize rows={2} className="form-control" type="text" placeholder="enter the question text for this part" name="qn_txt" value={this.props.qn.qn_txt}
                                onChange={(e) => this.props.updateState(e.target.value, e.target.name, this.props.index)}
                                onBlur={(e) => this.props.autosave(e.target.value, e.target.name, this.props.qn.id)}
                                onKeyDown={this._handleKeyDown}
                            />
                            <small className="protip">{this.state.showProTip}</small>
                        </div>
                        <div className="form-group">
                            <span>
                                <input className="form-control-file" type='file' name='qn_img'
                                    onChange={(e) => this.props.updateState(e.target.value, e.target.name, this.props.index, e.target.files)}
                                />
                                {this.props.renderDeleteFileButton('qn_img', this.props.index)}
                            </span>
                            <small>Upload question image (optional)</small>
                        </div>
                        Question Type <br />
                        {this.renderQnTypeToggle()}
                    </fieldset>
                    <fieldset disabled={window.location.pathname.slice(1, 5) === 'edit'}>
                        {this.renderComment()}
                    </fieldset>
                    {this.state.showSolutions ?
                        <Solutions
                            qn={this.props.qn}
                            qns={this.props.qns}
                            updateState={this.props.updateState}
                            autosave={this.props.autosave}
                            index={this.props.index}
                            qn_type={this.props.qn.qn_type}
                            subobjs={this.props.subobjs}
                            removePrereqFromState={this.props.removePrereqFromState}
                            edit={this.props.edit}
                            selected_topics={this.props.selected_topics}
                            renderDeleteFileButton={this.props.renderDeleteFileButton}
                            indexArray={this.props.indexArray}
                            validateCorrectAns={this.validateCorrectAns}
                            validAnswer={this.state.validAnswer}
                            getValidAns={this.props.getValidAns} />
                        : null}
                    <br /><br />
                    <fieldset disabled={this.props.edit && window.location.pathname.slice(1, 7) === 'lol'}>
                        <div className="card-btn">
                            {this.state.showSolutions ? null :
                                <button className="btn btn-primary" name="has_sol" value={true} onClick={this.addSolution}>
                                    Add Solution</button>
                            }
                            &nbsp;
                        <button className="btn btn-primary" onClick={this.addPart}>Add Another Part</button>
                        </div>
                    </fieldset>
                </div>
            </div>
        )
    }

    //=======================================================================================

    // To render LO modal with card or not
    render() {
        if (this.props.qn) {
            if (this.state.openModal) {
                return (
                    <span key={this.props.key}>
                        <LOModal onLOClick={this.onLOClick} updateState={this.props.updateState} selected_topics={this.props.selected_topics} getObjectives={this.getObjectives} qn={this.props.qn} index={this.props.index} first_part={this.props.qns[0].id} addPrevLOs={this.addPrevLOs} />
                        {this.renderCard()}
                    </span>
                )
            } else {
                return (
                    <div key={this.props.key}>{this.renderCard()}</div>
                )
            }
        } else {
            return <span></span>
        }
    }
}

export default QuestionCard