import React from 'react'

import kopi from '../../apis/kopi'
import history from '../../history'

import Contact from '../Contact';

class EditorNavbar extends React.Component{
    // /review /editorlocked
    
    publishQn = async () => {
        this.props.toggleLoadingState();
        const response = await kopi.post('/edit/publishqn', {qn: this.props.qns[0]})
        if (response.data){
            history.push('/locked/' + this.props.qns[0].id)
        }
        // if response.data history.push to editor dashboard
        // Alert to be sent to qusetion creator that question has been published
    }

    rejectQn = async () => {
        // Status of question to be set to "Draft" and shown on question creator's dashboard
        this.props.toggleLoadingState();
        const response = await kopi.post('/edit/rejectqn', {qnId: this.props.qns[0].id})
        if (response.data){
            history.push('/dashboard')
        }
    }

    unpublishQn = async () => {
        this.props.toggleLoadingState();
        const response = await kopi.post('/edit/unpublishqn', {qnId: this.props.qns[0].id, userId: this.props.userId})
        if (response.data){
            history.push('/review/' + this.props.qns[0].id)
        }
    }

    render(){
        const review = (
            <span>
                <span className="col-2">
                    <button className="nav-btn btn btn-info" style={{justifyContent:"center"}} onClick={this.props.viewFlags}>View Flags</button> &nbsp;
                    <button className="nav-btn btn btn-warning" style={{justifyContent:"center"}} onClick={this.rejectQn}>Reject</button>
                </span>
                <span className="col-2">
                    <button className="nav-btn btn btn-dark" style={{justifyContent:"center"}} onClick={this.publishQn}>Publish</button>
                </span>
            </span>
        )
        const published = <span>          
            <span className="col-4">
                <button className="nav-btn btn btn-info" style={{justifyContent:"center"}} onClick={this.props.viewFlags}>View Flags</button> &nbsp;
                <button className="nav-btn btn btn-danger" style={{justifyContent:"center"}} onClick={this.unpublishQn}>Unpublish</button>
            </span>
            </span>
        
        return (
            <>
            <Contact sourceRef={window.location.pathname} />
            <nav className="navbar sticky-top navbar-light bg-light">
            <div className="container-fluid row">
                <span className="col-4">
                    <button className="btn nav-btn btn-secondary btn-nav" onClick={()=>history.push('/dashboard')}>Back To Dashboard</button>
                </span>
                {window.location.pathname.slice(1,7) === 'review' ? review : <span className="col-4"></span>}
                {window.location.pathname.slice(1,7) === 'locked' && this.props.privilege === 1 ? published : <span className="col-4"></span>}
            </div>
            </nav>
            </>
        )
    }
}

export default EditorNavbar