// localStorage has key "mode": "Draft", "Review", "Published"

import React from 'react';

import Draft from './Draft';
import Review from './Review';
import Published from './Published';
import EditorReview from './EditorReview';
import EditorPublished from './EditorPublished';
import Loading from './Loading'
import kopi from '../../apis/kopi';
import history from '../../history';
import { ReactComponent as Logo} from '../../white-joni-authors-logo.svg';

import {connect} from 'react-redux';
import { signOut } from '../../actions';
import Wallet from './Wallet'

import Contact from '../Contact';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-140836063-2');
ReactGA.pageview(window.location.pathname + window.location.search);


class Dashboard extends React.Component{
    // mode: Draft, Review, Published, Wallet
    state = {mode: '', showMode: false, showModal: false, moo: 'S$', thirtyscheme: 0}

    componentDidMount = async () => {
        const mode = localStorage.getItem('mode')

        if (this.props.isAngel){
            console.log("33")
            let response1 = await kopi.get("/moolah/300",{params: {userId: this.props.currentUser}});
            this.setState({thirtyscheme: response1.data.balance})
        } else {
            if (this.props.privilege===0){
                let response = await kopi.get("/moolah/wallet",{params: {userId: this.props.currentUser}});
                this.setState({moo: `Wallet: S$${(response.data[0].earnings-response.data[0].withdrawals).toFixed(2)}`})
            } 
        }

        if (mode){
            this.setState({mode})
        } else {
            if (this.props.privilege === 0){
                this.setState({mode: 'Draft'})
            } else if (this.props.privilege === 1){
                this.setState({mode: 'Review'})
            }
        }
    }

    updateMode = (mode) => {
        this.setState({mode, showMode: false})
        localStorage.setItem('mode', mode)
    }

    renderModal(){
        if (this.props.isAngel){
            return <></>
        } else {
            if (this.state.showModal){
                return <div className="modal show fade bd-example-modal-xl" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content modal-content-bonus">
                        <div className="lead">
                        <table class="table table-incentives">
                            <thead>
                            <tr>
                                <h3>Bonus Rates </h3>
                            </tr>
                                <tr>
                                <th scope="col">Parts Published</th>
                                <th scope="col">Remuneration In Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th scope="row">25</th>
                                <td>S$10.00</td>
                                {/* <td>$0.40</td> */}
                                </tr>
                                <tr>
                                <th scope="row">50</th>
                                <td>S$17.50</td>
                                {/* <td>$0.35</td> */}
                                </tr>
                                <tr>
                                <th scope="row">75</th>
                                <td>S$22.50</td>
                                {/* <td>$0.30</td> */}
                                </tr>
                                <tr>
                                <th scope="row">100</th>
                                <td>S$25.00</td>
                                {/* <td>$0.25</td> */}
                                </tr>
                                <tr>
                                <th scope="row">125</th>
                                <td>S$41.25</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                                <tr>
                                <th scope="row">150</th>
                                <td>S$49.50</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                                <tr>
                                <th scope="row">175</th>
                                <td>S$57.75</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                                <tr>
                                <th scope="row">200</th>
                                <td>S$66.00</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                                <tr>
                                <th scope="row">225</th>
                                <td>S$74.25</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                                <tr>
                                <th scope="row">250</th>
                                <td>S$82.50</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                                <tr>
                                <th scope="row">275</th>
                                <td>S$90.75</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                                <tr>
                                <th scope="row">300</th>
                                <td>S$100.00</td>
                                {/* <td>$0.33</td> */}
                                </tr>
                            </tbody>
                            <br/>
                            <small> Bonuses will be paid out for each part as soon as your question gets approved and published!</small>
    
                            <br/> <br/>
                            <small>For more info regarding the regular payment rates, go to your Wallet page.</small>
    
                        </table>
                            <hr/>            
                            <div align="center">
                                <button onClick={()=>this.setState({showModal: false})} className="btn btn-block btn-dark">Return To Dashboard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            } else {
                return <></>
            }
        }
    }

    renderMoo = () => {
        return this.state.moo
    }

    renderNavbar = () => {
        let walletBtn;
        if (this.props.privilege === 1){
            walletBtn = <span></span>;
        } else if (this.props.isAngel){
            console.log("173")
            walletBtn = <span><button className="btn btn-sm btn-info">&nbsp; S${this.state.thirtyscheme} &nbsp;</button> &nbsp;</span>;
        } else {
            walletBtn = <span><button onClick={()=>this.setState({mode: 'Wallet'})} className="btn btn-sm btn-info">&nbsp; {this.renderMoo()} &nbsp;</button> &nbsp;</span>;
        }
        let modeOptions = this.props.privilege === 1 ? ['Review','Published'] : ['Draft', 'Review', 'Published', 'Wallet']
        modeOptions = this.props.isAngel ? ['Draft', 'Review', 'Published'] : modeOptions;
        return (
            <>
                <Contact sourceRef={window.location.pathname} />
                {this.renderModal()}
                <nav onClick={()=>this.setState({showModal: true})} class="navbar navbar-dark bg-black navbar-msg">
                    <span class="navbar-text mx-auto">
                        <button className="btn btn-black">💰 Earn additional bonuses for a limited time only!</button>
                    </span>
                </nav>            
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="container-fluid" style={{padding: "0px"}}>
                            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                                <div className="dropdown col-5" style={{padding: "0px"}}>
                                    <button className="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>this.setState({showMode: !this.state.showMode})}>
                                        {this.state.mode}
                                    </button> &nbsp;
                                    <div className={`dropdown-menu${this.state.showMode ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                                        {modeOptions.map(mode => <button key={mode} className="dropdown-item" value={mode} onClick={(e)=>this.updateMode(e.target.value)}>{mode}</button>)}
                                    </div>
                                </div> 
                                <div className="col-2" style= {{textAlign: "center"}}><Logo onClick={() => this.props.privilege === 0 ? this.setState({mode: 'Draft'}) : this.setState({mode: 'Review'})} style={{ width: "60%"}}/></div>
                                {/* <div className="col-3">{this.props.currentUser}</div> */}
                                <div className="col-5" align="right">
                                    {walletBtn}
                                    <button onClick={() => this.props.signOut()}  className="btn btn-sm btn-black">&nbsp; Sign Out &nbsp;</button>
                                    &nbsp;
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render(){
        if (this.props.privilege === 1){
            if (this.state.mode === "Review"){
                return (
                    <div>
                        {this.renderNavbar()}<br/>
                        <EditorReview userId={this.props.currentUser} privilege={this.props.privilege} />
                    </div>
                )
            } else if (this.state.mode === "Published"){
                return (
                    <div>
                        {this.renderNavbar()}<br/>
                        <EditorPublished userId={this.props.currentUser} privilege={this.props.privilege} />
                    </div>
                )
            } else {
                return <Loading />
            }
        } else{
            if (this.state.mode === "Draft"){
                return (
                    <div>
                        {this.renderNavbar()}<br/>
                        <Draft userId={this.props.currentUser} privilege={this.props.privilege} />
                    </div>
                )
            } else if (this.state.mode === "Review"){
                return (
                    <div>
                        {this.renderNavbar()}<br/>
                        <Review userId={this.props.currentUser} privilege={this.props.privilege} />
                    </div>
                )
            } else if (this.state.mode === "Published"){
                return(
                    <div>
                        {this.renderNavbar()}<br/>
                        <Published userId={this.props.currentUser} privilege={this.props.privilege} />
                    </div>
                )
            } else if (this.state.mode === "Wallet"){
                if (this.props.privilege === 1){
                    return <div> {this.renderNavbar()}</div>
                } else {
                    return <div>
                        {this.renderNavbar()}<br/>
                        <Wallet />
                    </div>
                }
            } else {
                return <Loading />
            }
        }  
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        privilege: Number(state.auth.privilege),
        isAngel: state.auth.isAngel
    };
};

export default connect(mapStateToProps, {signOut})(Dashboard);
