import React from 'react'

import kopi from '../../apis/kopi'
import SubobjCard from './SubobjCard'

class AddSubobj extends React.Component{

    state = {subobjectives: [{title: '', description: '', example: ''}]}

    onSaveClick = async () => {
        // console.log(this.props.objective._id)
        const response = await kopi.post('/admin/addsubobjectives', {subobjectives: this.state.subobjectives, objectiveId: this.props.objective._id})
        this.props.updateTopics(response.data[0])
        this.props.updateObjective(response.data[1])
        this.props.changePage(1)
    }

    updateState = (value, name, index) => {
        const subobjectives = [...this.state.subobjectives]
        const subobjective = subobjectives[index]
        subobjective[name] = value
        subobjectives[index] = subobjective
        this.setState({subobjectives})
    }

    addNewSubobj = () => {
        const newSubobj = {title: '', description: '', example: ''}
        const subobjectives = [...this.state.subobjectives]
        subobjectives.push(newSubobj)
        this.setState({subobjectives})
    }

    renderSubobjCards = () => {
        return this.state.subobjectives.map((subobjective, index) => {
            return (
                <div>
                    <SubobjCard key={index} addNewSubobj={this.addNewSubobj} updateState={this.updateState} index={index} />
                    <br/>
                </div>
            )
        })
    }

    render(){
        // console.log(this.state)
        return (
            <div className="container">
                <h1>{`${this.props.mode} Subobjectives`}</h1>
                <hr/>
                {this.renderSubobjCards()}
                <div className="row" style={{marginLeft: "0px", marginRight: "0px"}}>
                    <button className="btn btn-info btn-lg" onClick={this.onSaveClick}>Save</button> &nbsp; &nbsp;
                    <button className="btn btn-secondary btn-lg" onClick={()=> this.props.changePage(0)}>Cancel</button>
                </div>
            </div>
        )
    }
}

export default AddSubobj