import React from 'react'

import kopi from '../../apis/kopi'
import history from '../../history'
import PopoverStickOnHover from './PopoverStickOnHover'

import Contact from '../Contact'

class AuthorNavbar extends React.Component{

    deleteFullQn = async () => {
        const confirm = window.confirm('Are you sure you want to delete this question and all its parts?')
        if (confirm){
            const response = await kopi.delete('/authoring/deletefullqn', {data: {qnId: this.props.qns[0].id, userId: this.props.userId}})
            if (response.data){
                history.push('/dashboard')
            } else {
                this.props.renderLoading()
            }
        }
    }

    sendForApproval = async () => {
        const response = await kopi.post('/authoring/sendforapproval', {qnId: this.props.qns[0].id, userId: this.props.userId}) // post to /sendforapproval once there are userIds, with {qnId: this.props.qns[0].id}
        if (response.data){
            history.push('/dashboard')
        }
    }

    publishAsReference = async () => {
        const response = await kopi.post('/edit/publishasreference', {qn: this.props.qns[0]})
        if (response.data){
            history.push('/dashboard')
        }
    }

    removeReference = async () => {
        const response = await kopi.post('/edit/unpublishreferenceqn', {qnId: this.props.qns[0].id})
        if (response.data){
            history.push('/dashboard')
        }
    }


    renderReadyPopover = (ready) => {
        return Object.keys(ready).map(key => {
            const icon = ready[key] ? 'check' : 'clear'
            if (key === "prereq_subobjectives"){key = 'prerequisite objectives (optional)'}
            if (key === "qn_type"){key = 'question type'}
            if (key === "subobjectives"){key = 'objectives'}
            if (key === "ans"){key = 'answer'}
            if (key === "ans_alt"){key = 'alternative answers (MCQ only)'}
            return (
            <span key={key}>
                {key}
                <i className="material-icons">{icon}</i>
                <br/>
            </span>
            )
        })
    }

    render(){
        const {readyNum, readyStatus, ready} = this.props.saveReadyToDb()
        let dummyReadyStatus
        if (readyNum >= 4 ) {
            dummyReadyStatus = true
        }
    
        return (<>
            <Contact sourceRef={window.location.pathname} />
            <nav className="navbar sticky-top navbar-light bg-light navbar-author">
            <div className="container-fluid row" style={{paddingLeft: "0px"}}>
                <button className="btn nav-btn btn-secondary col-4 btn-nav" onClick={()=>history.push('/dashboard')}>Back To Dashboard</button>
                
                <span className="col-3">
                    <fieldset disabled={window.location.pathname.slice(1,7) === 'locked'}>
                        <button className="btn nav-btn btn-danger btn-nav" onClick={this.deleteFullQn}>Delete Question</button>
                    </fieldset>
                </span>
                <PopoverStickOnHover component={this.renderReadyPopover(ready)} placement="bottom" onMouseEnter={() => { }} title="Status" > 
                <span className="col-2 justify-content-end">
                    <fieldset disabled={window.location.pathname.slice(1,7) === 'locked'}>
                        <small>{readyStatus ? `Ready for review (${readyNum}/10)`: `Not ready for review (${readyNum}/10)`}</small>
                    </fieldset>
                </span>
                </PopoverStickOnHover>
                <span className="col-3">
                    <fieldset disabled={window.location.pathname.slice(1,7) === 'locked'}>
                        <button className={`nav-btn btn-nav btn btn-dark${readyStatus ? '' : ' disabled'}`} onClick={this.sendForApproval} disabled={!readyStatus} aria-disabled={!readyStatus}>Submit for approval</button>
                        {this.props.userId === '5cde0767f1a1203958228704' ? <button className={`nav-btn btn-nav btn btn-dark${dummyReadyStatus ? '' : ' disabled'}`} disabled={!dummyReadyStatus} aria-disabled={!dummyReadyStatus} onClick={this.publishAsReference}>Publish as Reference</button> : ''}    
                    </fieldset>
                    {this.props.userId === '5cde0767f1a1203958228704' ? <button className={`nav-btn btn-nav btn btn-dark${dummyReadyStatus ? '' : ' disabled'}`} disabled={!dummyReadyStatus} aria-disabled={!dummyReadyStatus} onClick={this.removeReference}>Remove Reference</button> : ''}        
                </span>
            </div>
            </nav>
            </>
        )
    }
}

export default AuthorNavbar