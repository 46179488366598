import React from 'react';
import {OverlayTrigger} from 'react-bootstrap';

import kopi from '../../apis/kopi'
import LOPopover from './LOPopover'
import PopoverStickOnHover from './PopoverStickOnHover'
import Latex from './Latex'
import '../Author.css'

class LOModal extends React.Component{    

    state = {objectives: [], selected_subobjectives: [], selected_objectives: [], loading: true}

    componentDidMount = async() => {
        const response = await kopi.post('/authoring/getLOs', {topics: this.props.selected_topics}) 
        console.log(this.props.qn.objectives)
        this.setState({objectives: response.data.all_objectives, loading: false, selected_subobjectives: this.props.qn.subobjectives ? this.props.qn.subobjectives : [], selected_objectives: this.props.qn.objectives ? this.props.qn.objectives : []})
    }

    //=======================================================================================

    // Saving LOs
    saveToState = async (event, objective) => {
        // save to state in LOModal
        event.persist()
        
        const value = JSON.parse(event.target.value)
        let selected_subobjectives
        let selected_objectives

        if (event.target.checked){
            console.log(this.state.selected_objectives)
            selected_objectives = [...this.state.selected_objectives]
            selected_subobjectives = [...this.state.selected_subobjectives]
            
            const objectivesId = selected_objectives.map(obj => obj._id)
            if (!objectivesId.includes(objective._id)){
                selected_objectives.push(objective)
            }
            selected_subobjectives.push(value)
        } else {
            selected_subobjectives = []
            this.state.selected_subobjectives.forEach(subobj => subobj._id === value._id ? null : selected_subobjectives.push(subobj))
            let storeObj = false
            const childObjId = objective.subobjectives.map(subobj => subobj._id)
            selected_subobjectives.forEach(subobj => {
                if (childObjId.includes(subobj._id)){
                    storeObj = true
                }
            })
            if (!storeObj){
                selected_objectives = []
                this.state.selected_objectives.forEach(obj => obj._id === objective._id ? null : selected_objectives.push(obj))
            } else {
                selected_objectives = [...this.state.selected_objectives]
            }
        }    
        this.setState({selected_objectives, selected_subobjectives}) 
    }

    saveAndClose = async () => {
        // save to db and state in parent class (CreateQuestion.js)
        this.props.onLOClick()
        this.props.updateState(this.state.selected_objectives, 'objectives', this.props.index)
        this.props.updateState(this.state.selected_subobjectives, 'subobjectives', this.props.index, null, true)

        const parentIds = this.state.selected_objectives.map(obj => obj._id)
        const childIds = this.state.selected_subobjectives.map(subobj => subobj._id)
        
        await kopi.post('/authoring/saveLOs', {qnId: this.props.qn.id, parentIds: parentIds, childIds: childIds, first_part: this.props.first_part})
        
    }

    //=======================================================================================

    // Rendering subobjectives
    renderChild = (objective, childrenLO) => {
        return childrenLO.map(childLO => {
            const prereq_subobj_array = this.props.qn.prereq_subobjectives.map(subobj => subobj._id)
            let disabled = false 
            let style = {}
            if (prereq_subobj_array.includes(childLO._id)){
                disabled = true
                style = {color: "grey"}
            } 
            const checked = this.state.selected_subobjectives.find(subobj => subobj._id === childLO._id) ? true : false
            const popover = <LOPopover subobj={childLO} />
                
            return (
                <PopoverStickOnHover key={childLO._id} component={popover} placement="auto" onMouseEnter={() => { }} title={<Latex latex={childLO.title} />} > 
                    <div className="form-check">
                        <label className="form-check-label" htmlFor={childLO._id} style={style}>
                            <input type="checkbox" className="form-check-input" disabled={disabled} checked={checked} id={childLO._id} name="subobjectives" value={JSON.stringify(childLO)} 
                            onChange={(event) => this.saveToState(event, objective)} />
                            <Latex latex={childLO.title} />
                        </label>
                    </div>
                </PopoverStickOnHover>
            )
        })
    }

    // Rendering objectives
    renderLO = (topicId) => {
        return this.state.objectives.map(objective => {
            if (topicId === objective.topic){
                return (
                    <div key={objective._id} className="container-fluid LO-container">
                        <strong><Latex latex={objective.title} /></strong><br/>
                        <ul style={{listStyleType:"none", padding: 0}}>
                            {this.renderChild(objective, objective.subobjectives)}
                        </ul>
                        <br/>
                    </div>
                )
            }
        })  
    }

    // Rendering topics
    renderTopic = () => {
        if (this.state.loading){
            return (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status" style={{position:"absolute", top:"50%", left:"50%"}}>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
        } else {
            return this.props.selected_topics.map(topic => {
                return (
                    <div key={topic._id} className="container-fluid LO-container">
                        <h4>{topic.title}</h4>
                        {this.renderLO(topic._id)}
                        <br/>
                    </div>
                )
            }) 
        }
    }

    renderDuplicateLOButton = () => {
        if (this.props.index){
            return <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.addPrevLOs}>Duplicate from previous part</button>
        } else {
            return <span></span>
        }
    }
    
    //=======================================================================================
    
    // Rendering modal
    render(){
        console.log(this.state)
        return (
            <div className="modal show fade" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{display:"block"}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Add Learning Objectives</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onLOClick}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.renderTopic()}
                        </div>
                        <div className="modal-footer">
                            {this.renderDuplicateLOButton()}
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.saveAndClose}>Save and Close</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.onLOClick}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LOModal