import {FILTER_LEVEL, FILTER_TOPIC} from '../actions/types';

export default (state={}, action) => {
    switch(action.type){
        case FILTER_LEVEL:
            return {...state, level: action.payload[0], topic: action.payload[1] ? action.payload[1] : ''};
        case FILTER_TOPIC:
            return {...state, topic: action.payload};
        default:
            return {...state}
    }
}
