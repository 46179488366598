import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import history from '../history'
import Home from './Home'
import Admin from './admin/Admin'
import EditQuestion from './edit/EditQuestion'
import Wallet from './dashboard/Wallet'
import Dashboard from './dashboard/Dashboard'
import ImageCropper from './image_cropper/ImageCropper'
import Onboarding from './Onboarding'
import EmailVerified from './Onboarding/EmailVerified'
import Legal from './Legal'
import Privacy from './Privacy';
import Auth from './Auth'
import NeedVerification from './Onboarding/NeedVerification';
import { connect } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import CreateQuestion from './Editor/CreateQuestion';
import AssignQuestion from './AssignQuestion/index.js';
import Prophet from './Prophet/index.js';


import './Author.css';
import renderMathInElement from 'katex/dist/contrib/auto-render';

class App extends React.Component {

  componentDidMount() {
    // Include the Crisp code here, without the <script></script> tags
    // window.$crisp = [];
    // window.CRISP_WEBSITE_ID = "772b536d-191a-44f7-969f-970da19f492d";

    // (function() {
    //   var d = document;
    //   var s = d.createElement("script");

    //   s.src = "https://client.crisp.chat/l.js";
    //   s.async = 1;
    //   d.getElementsByTagName("head")[0].appendChild(s);
    // })();
  };

  render() {
    return (
      <HttpsRedirect>
        <Router history={history}>
          <Switch>
            <Route path='/onboarding' exact component={Onboarding} />
            <Route path='/login' exact component={Auth} />
            <Route path='/email-verified' exact component={EmailVerified} />
            <Route path="/verify" exact component={NeedVerification} />
            <Route path="/legal" exact component={Legal} />
            <Route path="/privacy" exact component={Privacy} />


            <Route path="/" exact component={Home} />
            <Route path="/create_latex" exact component={ImageCropper} />
            <Route path="/create" exact component={CreateQuestion} />
            <Route path="/create/:id" exact component={CreateQuestion} />

            {/* <Route path="/admin" exact component={Admin} /> */}
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/wallet" exact component={Wallet} />

            /*Review mode: question unlocked for author to edit*/
            <Route path="/edit/:id" exact component={EditQuestion} />

            /*Review mode: question locked for editor to give comments*/
            <Route path="/review/:id" exact component={EditQuestion} />

            /*Published mode: question locked for viewing only*/
            <Route path="/locked/:id" exact component={CreateQuestion} />

            <Route path="/assign" exact component={AssignQuestion} />
            <Route path="/banana" exact component={Prophet} />

          </Switch>
        </Router>
      </HttpsRedirect>
    )
  }

}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    isSignedIn: state.auth.isSignedIn,
    privilege: Number(state.auth.privilege),
  };
};

export default connect(mapStateToProps, {})(App);
