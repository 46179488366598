import React, { useState, useEffect } from 'react';
import { useLocation, Redirect, Link } from 'react-router-dom';
import qs from 'qs';
import kopi from '../../apis/kopi';
import papa from 'papaparse';
import { connect } from 'react-redux';
import history from '../../history';
import axios from 'axios';

const Prophet = (props) => {

    const style = {
        minHeight: "100vh",
        width: "100vw",
        display: 'flex'
    }

    const [csvfile, uploadFile] = useState(null);
    const [parsedData, parseData] = useState([]);
    const [uploaded, upload] = useState(false);

    useEffect(() => {
        if (csvfile != null) importCSV();
    }, [csvfile]);

    useEffect(() => {
        if (uploaded) history.push('/dashboard');
    }, [uploaded]);

    let filesInput;

    const handleChange = event => {
        uploadFile(event.target.files[0])
        // this.setState({
        //     csvfile: event.target.files[0]
        // });
    };

    const importCSV = () => {
        papa.parse(csvfile, {
            complete: updateData,
            header: true
        });
    };

    const updateData = (result) => {
        let data = result.data;
        let formattedData = data.map(row => {
            return { student_name: row.student_name, student_email: row.student_email, class_name: row.class_name, mentor_email: row.mentor_email, secondary_level: row.secondary_level }
        })
        parseData(formattedData.filter(d => d.student_name))
    }

    const renderTable = () => {
        return <div style={{ height: "100vh", marginTop: "20px", marginLeft: '0', marginRight: '0' }} class="container">
            <div class="row">
                <div class="col-md-10">
                    <div style={{
                        position: "relative",
                        height: "100vh",
                        overflow: "auto"
                    }}>
                        <div style={{ display: "block" }}>
                            <table class="table" style={{ overflow: "scroll" }} >
                                <thead>
                                    <tr>
                                        <th scope="col">Student Name</th>
                                        <th scope="col">Student Email</th>
                                        <th scope="col">Class Name</th>
                                        <th scope="col">Secondary Level</th>
                                        <th scope="col">Mentor Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parsedData.map(d => <tr>
                                        <td>{d.student_name}</td>
                                        <td>{d.student_email}</td>
                                        <td>{d.class_name}</td>
                                        <td>{d.secondary_level}</td>
                                        <td>{d.mentor_email}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-2" style={{ position: "sticky" }}>
                    <br /><br /><br /><br />
                    <div style={{ width: "100%", height: "0", paddingBottom: "100%", position: "relative" }}>
                        <iframe src="https://giphy.com/embed/pHYaWbspekVsTKRFQT" style={{ position: "absolute", width: "100%", height: "100%" }} frameBorder="0" class="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/neurads-dance-yas-yass-pHYaWbspekVsTKRFQT"></a></p>
                    <button onClick={async () => {
                        axios.post("https://k8sapi.joni.ai/api/mentor/admin/populate_batch", { parsedData });
                        window.alert('Done')
                        // upload(true);
                    }} class="btn btn-sm btn-dark">Looks good to me</button>
                </div>
            </div>

        </div>
    }

    const renderButt = () => {
        return <div style={{ width: '100vw', alignSelf: 'center', textAlign: 'center' }}>
            <img onClick={() => filesInput.click()} src="https://image.flaticon.com/icons/svg/1598/1598004.svg" style={{ width: "150px" }} class="img-fluid" />
            <br /><br />
            <h5>Up Joni's Butt They Go</h5>
        </div>
    }

    return (
        <div style={style}>
            <input onChange={handleChange} hidden={true} className="form-control-file col-6" type='file' ref={input => {
                filesInput = input;
            }} />
            {csvfile ? renderTable() : renderButt()}
        </div >
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        privilege: Number(state.auth.privilege),
        isAngel: state.auth.isAngel
    };
};

export default connect(mapStateToProps, {})(Prophet);
