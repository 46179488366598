import React from 'react';
import ReactDOM from 'react-dom';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

import './ImageCropper.css';

import kopi from '../../apis/kopi';

class ImageCropper extends React.Component {
    state = {
        src: null,
        crop: {
          x: 10,
          y: 10,
          // aspect: 1,
          width: 50,
        },
        blob: null,
        inline: "",
        block_display: "",
        inline_stack: [],
        block_stack: []
      }
    
      onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            this.setState({ src: reader.result });
          });
          reader.readAsDataURL(e.target.files[0]);
        }
      }

      onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
        this.makeClientCrop(this.state.crop, pixelCrop);
      }
    
      onCropComplete = (crop, pixelCrop) => {
        // console.log('onCropComplete', { crop, pixelCrop });
        this.makeClientCrop(crop, pixelCrop);
      }
    
      onCropChange = (crop) => {
        // // console.log('onCropChange', crop);
        this.setState({ crop });
      }
    
      onDragStart = () => {
        // console.log('onDragStart');
      }
    
      onDragEnd = () => {
        // console.log('onDragEnd');
      }
    
      getCroppedImg(image, pixelCrop, fileName) {
        // console.log('getCroppedImg', { image, pixelCrop, fileName });
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );
    
        return new Promise((resolve) => {
          canvas.toBlob((blob) => {
            blob.name = fileName; // eslint-disable-line no-param-reassign
            this.setState({blob: blob});
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
      }
    
      makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
          this.getCroppedImg(
            this.imageRef,
            pixelCrop,
            'newFile.jpeg',
          ).then(croppedImageUrl => this.setState({ croppedImageUrl }));
        }
      }
    
      renderSelectionAddon = () => (
        <button
          type="button"
          style={{
            position: 'absolute',
            bottom: -25,
            right: 0,
          }}
          onClick={() => window.alert('You click addon!')}
        >
          custom addon
        </button>
      );

      callMathPix = () => {
        let reader = new FileReader();
        reader.readAsDataURL(this.state.blob); 
        reader.onloadend = () => {
            let base64data = reader.result;  
            let data = {
              "src": base64data,
              "ocr": ["math", "text"],  
              "skip_recrop": true,
               "formats":[
                  {
                     "latex":[
                        "styled",
                        "rm_spaces"
                     ]
                  },
                  {
                     "text":[
                        "text",
                        "rm_spaces",
                        {
                           "math":[
                              "@@",
                              "@@"
                           ]
                        },
                        {
                           "displaymath":[
                              "\n$$\n",
                              "\n$$\n"
                           ]
                        }
                     ]
                  }
               ]
            }
            return fetch("https://api.mathpix.com/v3/latex", {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, cors, *same-origin
              credentials: "same-origin", // include, *same-origin, omit
              headers: {
                  "app_id": "zonghangoh_gmail_com",
                  "app_key": "82372c714bdb54970e59",
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(data), // body data type must match "Content-Type" header
          })
          .then(response => response.json())
          .then(data => {
            // you can access your data here
            // console.log(data);
            if (data.text && data.latex){
              this.setState({inline: data.text, block_display: data.latex});
            } else {
              window.alert("oh no");
            }
        })
        }
      }

      renderBlock = () => {
        let seperator = "@{1,2}";
        if (this.state.block_display.startsWith("\\begin")){
          return <div className="table-responsive">
              <table className="table">
                  <tbody>
                  <tr>
                      <td> 
                          <Tex texContent={this.state.block_display} />
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>    
      } else {
         return <div><InlineTex texSeperator={seperator} texContent={this.state.block_display} /></div>
      }
      } 

      renderInline = () => {
        let seperator = "@{1,2}";
        return <div><InlineTex texSeperator={seperator} texContent={this.state.inline} /></div>
      }

      addToInlineStack = () => {
      }

      addToBlockStack = () => {}
      
      
    
      render() {
        const { croppedImageUrl } = this.state;
    
        return (
          <div className="container">
            <br/><br/>
            <h1 className="lead">Life Damn Good Sia</h1>
            <div className="row">
              <div className="col-lg-8">
                <div>
                  <input type="file" onChange={this.onSelectFile} />
                </div>
                <div>
                  {this.state.src && (
                    <ReactCrop
                      src={this.state.src}
                      crop={this.state.crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                      onDragStart={this.onDragStart}
                      onDragEnd={this.onDragEnd}
                      renderSelectionAddon={this.renderSelectionAddon}
                    />
                  )}
              </div>
            </div>
            <div className="col-lg-4">
              {/* {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />} */}
              <button onClick={this.callMathPix} className="btn btn-lg btn-info">Render Equation</button><br/><br/>
              {/*<a download="custom-filename.jpg" href={croppedImageUrl} title="ImageName">
                  <img alt="" src={this.state.src} />
              </a>*/}
              <div>
              <small>Inline:</small> &nbsp;
              <button className="btn-sm btn-dark" onClick={this.addToInlineStack}> Add to Stack</button> <br/><br/>
              <h4>{this.renderInline()}</h4>
              <textarea className="form-control" value={this.state.inline} onChange={(e)=>{this.setState({inline: e.target.value})}} />
              <br/>
              <small>Block:</small> &nbsp;
              <button className="btn-sm btn-dark" onClick={this.addToBlockStack}> Add to Stack</button><br/><br/>
              <h4>{this.renderBlock()}</h4>
              <textarea className="form-control" value={this.state.block_display} onChange={(e)=>{this.setState({block_display: e.target.value})}} />
              <br/><br/>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
        );
      }
};

export default ImageCropper;