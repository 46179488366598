import React from 'react'
import { InlineTex } from 'react-tex';
import { Tex } from 'react-tex';

class LOPopover extends React.Component{
    
    renderLatex(latex){
        let seperator = "\${1,2}";
        if (latex.startsWith("\\begin")) {
            return(
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={latex} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return <div><InlineTex texSeperator={seperator} texContent={latex} /></div>
        }
    }

    render(){
        // console.log(this.props.subobj.description)
        // console.log(this.props.subobj.example)
        if (this.props.subobj.description && this.props.subobj.example){
            return (
                <>
                <strong>Description of Objective</strong>
                <span className="popover-body">
                    {this.renderLatex(this.props.subobj.description)}
                    <hr/>
                    <strong>Example</strong>
                    {this.renderLatex(this.props.subobj.example)}
                </span>
                </>
            )
        } else if (this.props.subobj.description && !this.props.subobj.example){
            return (
                <>
                <strong>Description of Objective</strong>
                <span className="popover-body">
                    {this.renderLatex(this.props.subobj.description)}
                </span>
                </>
            )
        } else if (!this.props.subobj.description && this.props.subobj.example){
            return (
                <>
                <strong>Example</strong>
                <span className="popover-body">
                    {this.renderLatex(this.props.subobj.example)}
                </span>
                </>
            )
        } else if (!this.props.subobj.description && !this.props.subobj.example){
            return <span></span> 
        }
    } 
}

export default LOPopover