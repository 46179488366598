import React from 'react'
import kopi from '../../apis/kopi';
import { connect } from 'react-redux';
import Confirmation from './Confirmation';
import history from '../../history'


class AssignQuestion extends React.Component {
    state = { topics: [], selected_topics: [], email: "", level: 1, subject_key: "", qnId: ""}


    componentDidMount = () => {

        if (!this.props.currentUser || !this.props.isSignedIn || this.props.privilege != '1'){
            history.push('/login')
        }
    }


    renderLevel = () => {
        return <input onChange={e => this.setState({ level: Number(e.target.value) })} type="text" className="form-control" style={{ width: "50%" }} placeholder="Secondary School Level" />
    }

    renderSubject = () => {
        const updateSubject = async (subject_key) => {
            const response = await kopi.get('/authoring/topics', { params: { subject_key } })
            this.setState({ topics: response.data, selected_topics: [] })
        }
        return <input onChange={e => updateSubject(e.target.value)} type="text" className="form-control" style={{ width: "50%" }} placeholder="Subject" />
    }

    renderId = () => {
        return <input onChange={e => this.setState({qnId : e.target.value})} type="text" className="form-control" style={{ width: "50%" }} placeholder="Qn Id" />
    }



    renderEmail = () => {
        return <input onChange={e => this.setState({ email: e.target.value })} type="text" className="form-control" style={{ width: "50%" }} placeholder="Author Email" />
    }

    selectTopics = () => {
        const toggleTopic = (topic) => {
            let selected_topics = [...this.state.selected_topics];
            if (this.state.selected_topics.some(t => t._id == topic._id)) {
                this.setState({ selected_topics: selected_topics.filter(t => t._id != topic._id) })
            } else {
                selected_topics.push({ _id: topic._id, title: topic.title, quantity: 0 })
                this.setState({ selected_topics })

            }
        }
        return this.state.topics.filter(t => t.level == this.state.level).map(t => {
            return <div style={{ color: this.state.selected_topics.some(topic => topic._id == t._id) ? "blue" : "black" }} on><div onClick={() => toggleTopic(t)} ><p>{t.title} </p></div></div>
        })
    }

    updateAssignedTopics = (numOfParts, topic_id) => {
        let selected_topics = [...this.state.selected_topics];
        this.setState({
            selected_topics: selected_topics.map(t => {
                if (t._id == topic_id) {
                    t.quantity = Number(numOfParts);
                }
                return t
            })
        })
    }

    render = () => {
        return <div className="container" style={{ paddingTop: "5vh" }}>
            <div className="row">
                <div className="col-6">
                    {this.renderEmail()} <br />
                    {this.renderId()}<br/>
                    {this.renderLevel()} <br />
                    {this.renderSubject()} <br />
                    {this.selectTopics()}
                </div>
                <div className="col-6">
                    <Confirmation updateAssignedTopics={this.updateAssignedTopics} selected_topics={this.state.selected_topics} email={this.state.email} qnId={this.state.qnId}/>
                </div>
            </div>
        </div>
    }
}

// this.setState((state) => {
//     return {quantity: state.quantity + 1};
//   });

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        privilege: Number(state.auth.privilege),
    };
};

export default connect(mapStateToProps, {})(AssignQuestion);