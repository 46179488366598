import React from 'react'
import { InlineTex } from 'react-tex';
import { Tex } from 'react-tex';

const Latex = (props) => {
    let seperator = "\${1,2}";
    if (props.latex){
        if (props.latex.startsWith("\\begin")) {
            return(
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={props.latex} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return <div><InlineTex texSeperator={seperator} texContent={props.latex} /></div>
        }
    } else {
        return <span></span>
    }   
}

export default Latex