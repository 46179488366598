import React from 'react'

class ObjCard extends React.Component{
    
    updateState = (event) => {
        this.props.updateState(event.target.value, event.target.name, this.props.index)
    }

    render(){
        return (
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="form-group">
                                <label htmlFor="formGroupExampleInput">Title</label>
                                <input type="text" class="form-control" id="formGroupExampleInput" name="title" placeholder="Enter objective title" onChange={this.updateState}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="formGroupExampleInput3">Description</label>
                                <textarea type="text" class="form-control" id="formGroupExampleInput3" name="description" placeholder="Enter objective description" onChange={this.updateState}/>
                            </div>
                        </form>
                        <br/>
                        <button className="btn btn-info" onClick={this.props.addNewObj}>Add another objective</button>
                    </div>
                </div>
        )
    }
}

export default ObjCard