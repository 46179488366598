import React from 'react';
import { connect } from 'react-redux';
import { signUp } from '../../actions';

const Legal = (props) => {
  return (
    <div className="container-fluid">

<br/>
<h1> Joni.Authors Terms and Conditions of Use</h1> <br/>

The parties to this Agreement wish to publish Author’s Mathematics Practice Questions (“the User Content”). The two parties agree as follows:
<br/>
<br/>

<h2>1. Introduction</h2>

<p>Thanks for choosing Joni.Authors (“Joni.Authors,” “we,” “us,” “our”). Joni.Authors is a Platform as a Service (PaaS) created and owned by Joni.AI Pte Ltd (“Joni.AI”, "Joni", “we”, “us”, “our”) Joni.Authors provides an authoring platform for authors to publish their Mathematics Practice Questions as well as other products and services that may be developed from time to time. Aside from the rights specifically granted herein, you retain ownership of all rights, including intellectual property rights, in the User Content. By signing up or otherwise using any of these Joni.Authors services, including all associated features and functionalities, websites and user interfaces, as well as all content and software applications associated with our services (collectively, the “Joni Service” or “Service”), or accessing any content or material that is made available through the Service (the “Content”), you are entering into a binding contract with the Joni.Authors entity.</p>
<p>Your agreement with us includes these Terms and any additional terms that you agree to, as discussed in the Entire Agreement section below, other than terms with any third parties (collectively, the “Agreements”). The Agreements include terms regarding future changes to the Agreements, export controls, limitations of liability, privacy, and resolution of disputes by arbitration instead of in court. If you wish to review the terms of the Agreements, the current effective version of the Agreements can be found on Joni.Author’s website. You acknowledge that you have read and understood the Agreements, accept these Agreements, and agree to be bound by them. If you don’t agree with (or cannot comply with) the Agreements, then you may not use the Joni.Authors Service or access any Content.
</p>
<p>In order to use the Joni.Authors Service and access any Content, you need to (1) meet the age requirement of 13 years old and (2) have the power, or the authorisation from guardian, to enter a binding contract with us and not be barred from doing so under any applicable laws. You also promise that any registration information that you submit to Joni.Authors is true, accurate, and complete, and you agree to keep it that way at all times. 
</p>
<p>Joni.Authors will attempt to verify the identity of each member, such as using date of birth to determine the appropriate age of Joni.Authors account holders. HOWEVER, YOU ACKNOWLEDGE THAT JONI.AUTHORS CANNOT GUARANTEE THE ACCURACY OF ANY INFORMATION SUBMITTED BY ANY USER AND JONI.AUTHORS IS NOT OBLIGATED TO VERIFY THE IDENTITY OF ANY USER. 
</p>

<h2>2. Changes to the Agreements</h2>
<p>Occasionally we may make changes to the Agreements for valid reasons, such as improving the existing functions or features or adding new functions or features to the Service, implementing advancements in science and technology, and reasonable technical adjustments to the Service, ensuring the operability or the security of the Service, and for legal or regulatory reasons. Although we may attempt to notify you when major changes are made to these Terms of Service, you should periodically review the most up-to-date version (https://authors.joni.ai/legal). Joni.Authors may, in its sole discretion, modify or revise these Terms of Service and policies at any time, and you agree to be bound by such modifications or revisions. Nothing in these Terms of Service shall be deemed to confer any third-party rights or benefits. Please therefore make sure you read any such notice carefully. If you do not wish to continue using the Service under the new version of the Agreement, you may terminate your account by contacting us.
</p>
<h2>3. Payments</h2>
<p>As an initial sign up bonus, Authors will receive up to SGD 100 for 300 published parts, in payouts via cheque or bank transfer. Thereafter, Authors will receive a royalty per attempt of a part by a student account. This royalty is priced at a base price of SGD 0.02 per attempt currently and may be subject to revisions in the future. Authors can cash out their accumulated royalties when the cumulative amount reaches at least SGD 25. 
</p>
<p>
  Joni.AI provides free accounts to students from less privileged backgrounds as part of our social efforts. Authors can indicate their deicsion to support this social cause via Joni.Authors. By doing so, the authors relinquish their rights to the royalties generated when these student beneficiaries access their questions. This waiver is limited to students under Joni.AI's probono programme and other less privileged students supported by Joni.AI's social efforts. Authors will continue to earn royalties from other students on the Joni.AI platform. Authors acknowledge that they have faithfully and diligently read this clause and fully understand the intentions and consequences of deciding to support Joni.AI's social cause.
</p>
<h2>4. Using our Service</h2>
<p>These Terms of Service apply to all users of the Service, including users who are also contributors of User Generated Content on the Service. “Content” includes the text, mathematical symbols, software, scripts, graphics, photos, sounds, music, videos, audiovisual combinations, interactive features and other materials you may view on, access through, or contribute to the Service. The Service includes all aspects of Joni.Authors, including but not limited to all products, software and services offered via the Joni.Authors website, such as the Authors Profile, the Joni.Authors Question Bank, the Joni.Authors Question "Uploader" and other applications.
The Service may contain links to third party websites that are not owned or controlled by Joni.Authors. Joni.Authors and Joni.AI have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party websites. In addition, Joni.Authors will not and cannot censor or edit the content of any third-party site. By using the Service, you expressly relieve Joni.Authors and Joni.AI from any and all liability arising from your use of any third-party website and materials.
Accordingly, we encourage you to be aware when you leave the Service and to read the terms and conditions and privacy policy of each other website that you visit.
Besides User Generated Content, the Joni.Authors Service and the Contents generated by Joni.Authors are the property of Joni.AI or Joni.AI’s licensors. We grant you limited, non-exclusive, revocable permission to make use of the Joni.Authors Service, and limited, non-exclusive, revocable permission to make personal use of the Content (collectively, “Access”). This Access shall remain in effect until and unless terminated by you or Joni.AI. You promise and agree that you are using the Joni.Authors Service and Content for your own personal use and that you will not redistribute or transfer the Joni.Authors Service or the Contents generated by Joni.Authors.
</p>
<p>The Joni.Authors software applications and the Contents generated by Joni.Authors are not sold or transferred to you, and Joni.Authors and its licensors retain ownership of all copies of the Joni.Authors software applications and Content even after installation on your personal computers, mobile handsets, tablets, wearable devices, speakers, and/or other devices (“Devices”).
</p>
<p>All Joni.Authors trademarks, service marks, trade names, logos, domain names, and any other features of the Joni brand (“Joni Brand Features”) are the sole property of Joni.Authors or its licensors. The Agreements do not grant you any rights to use any Joni Brand Features whether for commercial or non-commercial use.
</p>
<p>You agree to abide by our User guidelines and not to use the Joni Service, the Content, or any part thereof in any manner not expressly permitted by the Agreements. Except for the rights expressly granted to you in the Agreements, Joni.AI grants no right, title, or interest to you in the Joni Service or Content.
</p>
<p>Third party software (for example, open source software libraries) included in the Joni Service are made available to you under the relevant third party software library’s license terms as published in the help or settings section of our desktop and mobile client and/or on our website.
</p>
<h2>5. User-Generated Content </h2>
<p>Joni.Authors users may post, upload, or otherwise contribute content to the Service (which may include, for example, pictures, text and/or other types of content) (“User Content”). For the avoidance of doubt, “User Content” includes any such content posted to the Joni.Authors Community as well as any other part of the Joni Service.
</p>
<p>You agree that you will be solely responsible (to Joni.AI, and to others) for all activities that occur under your Joni.Authors account. You promise that, with respect to any User Content you post on Joni.Authors, (1) you own or have the right to post such User Content as well as the permissions required to enable Joni.AI to use your Content for the purposes of the provision of the Service by Joni.AI, and (2) such User Content, or its use by Joni.AI as contemplated by the Agreement, does not violate the Agreements or any other rights set forth within the User guidelines, applicable law, or the intellectual property, publicity, personality, or other rights of others or imply any affiliation with or endorsement of you or your User Content by Joni.AI or any authors or publishers without express written consent from Joni.AI or such individual or entity.
</p>
<p>Joni.Authors will monitor, review, and edit User Content. In all cases, Joni.Authors reserves the right to remove or disable access to any User Content for any or no reason, including User Content that, in Joni.Author’s sole discretion, violates the Agreements. Joni.Authors may take these actions without prior notification to you or any third party. Removal or disabling of access to User Content shall be at our sole discretion, and we do not promise to remove or disable access to any specific User Content.
</p>
<p>You are solely responsible for all User Content that you post, and the consequences of posting or publishing it. Joni.Authors is not responsible for User Content nor does it endorse any opinion, recommendation or advice contained in any User Content. Joni.Authors expressly disclaims any and all liability in connection with content. 
</p>
<p>YOU AGREE THAT IF ANYONE BRINGS A CLAIM AGAINST JONI.AUTHORS RELATED TO USER CONTENT THAT YOU POST, THEN, TO THE EXTENT PERMISSIBLE UNDER LOCAL LAW, YOU WILL INDEMNIFY AND HOLD JONI.AUTHORS HARMLESS FROM AND AGAINST ALL DAMAGES, LOSSES, AND EXPENSES OF ANY KIND (INCLUDING REASONABLE ATTORNEY FEES AND COSTS) ARISING OUT OF SUCH CLAIM.
</p>
<h2>6. Rights you grant us</h2>
<p>In consideration for the rights granted to you under the Agreements, you grant us the right to (1) allow the Joni.Authors to use the processor, bandwidth, and storage hardware on your Device in order to facilitate the operation of the Service, (2) to provide advertising and other information to you, and (3) to allow our business partners to do the same. In any part of the Joni Service, the Content you access, including its selection and placement, may be influenced by commercial considerations, including Joni’s agreements with third parties. Some Content licensed by, provided to, created by or otherwise made available by Joni  may contain advertising as part of the Content. The Joni Service makes such Content available to you unmodified.
</p>
<p>If you provide feedback, ideas, or suggestions to Joni.Authors in connection with the Joni Service or Content (“Feedback”), you acknowledge that the Feedback is not confidential and you authorize Joni to use that Feedback without restriction and without payment to you. Feedback is considered a type of User Content.
</p>
<p>You grant Joni.Authors a non-exclusive, transferable, sub-licensable, royalty-free, perpetual, irrevocable, fully paid, worldwide license to use, reproduce, make available to the public (e.g. publish or display), publish, translate, modify, create derivative works from, and distribute any of your User Content in connection with the Service through any medium, whether alone or in combination with other Content or materials, in any manner and by any means, method or technology, whether now known or hereafter created. Aside from the rights specifically granted herein, you retain ownership of all rights, including intellectual property rights, in the User Content. Where applicable and permitted under applicable law, you also agree to waive and not enforce any “moral rights” or equivalent rights, such as your right to be identified as the author of any User Content, including Feedback, and your right to object to derogatory treatment of such User Content.
</p>
<h2>7. User Guidelines</h2>
<p> Joni.Authors respects intellectual property rights and expects you to do the same. We’ve established a few ground rules for you to follow when using the Service, to make sure Joni.Authors stays enjoyable for everyone. You must follow these rules and should encourage other users to do the same. You agree that you are solely responsible for (and that Joni.Authors has no responsibility to you or to any third party for) any breach of your obligations under the Terms and for the consequences (including any loss or damage which Joni.Authors may suffer) of any such breach.The following is not permitted for any reason whatsoever: 
</p>
<ul><li>copying, redistributing or reproducing any part of the Joni.Authors Service or the Content, or otherwise making any use of the Joni.Authors Service or the Content which is not expressly permitted under the Agreements or applicable law or which otherwise infringes the intellectual property rights (such as copyright) in the Joni.Authors Service or the Content or any part of it;
</li>
<li>using the Joni.Authors Service to import or copy any local files that you do not have the legal right to import or copy in this way;
</li>
<li>transferring copies of cached Content from an authorized Device to any other Device via any means;
</li>
<li>
circumventing any technology used by Joni.Authors, its licensors, or any third party to protect the Content or the Service;
</li>
<li>
selling, renting, sublicensing, or leasing of any part of the Joni.Authors Service or the Content;
</li>
<li>
artificially increasing question attempts or otherwise manipulating the Service by (i) using any bot, script, or other automated process; (ii) providing or accepting any form of compensation (financial or otherwise), or (iii) any other means;
</li>
<li>
removing or altering any copyright, trademark, or other intellectual property notices contained on the Content or the Service or provided through the Service (including for the purpose of disguising or changing any indications of the ownership or source of any Content);
</li>
<li>
circumventing or blocking advertisements in the Joni.Authors Service, or creating or distributing tools designed to block advertisements in the Joni.Authors Service
</li>
<li>
artificially promoting User Content by automated means or otherwise.
</li>
</ul>

<p>Please respect Joni.Authors and other users of the Joni.Authors service. Don’t engage in any activity, post any User Content, or register and/or use a username, which is or includes material that:
</p>
<ul>
<li>
is offensive, abusive, defamatory, pornographic, threatening, or obscene;
</li>
<li>
is illegal, or intended to promote or commit an illegal act of any kind, including violations of intellectual property rights, privacy rights, or proprietary rights of Joni.Authors or a third party;
</li>
<li>
includes your password or purposely includes any other user’s password or purposely includes personal data of third parties or is intended to solicit such personal data;
</li>
<li>
includes malicious content such as malware, Trojan horses, or viruses, or otherwise interferes with any user’s access to the Service;
</li>
<li>
is intended to or does harass or bully other users;
</li>
<li>
impersonates or misrepresents your affiliation with another user, person, or entity, or is otherwise fraudulent, false, deceptive, or misleading;
</li>
<li>
involves the transmission of unsolicited mass mailings or other forms of spam (“spam”), junk mail, chain letters, or similar;
</li>
<li>
involves commercial or sales activities, such as advertising, promotions, contests, sweepstakes, or pyramid schemes, that are not expressly authorized by Joni.Authors;
</li>
<li>
links to, references, or otherwise promotes commercial products or services, except as expressly authorized by Joni.Authors;
</li>
<li>interferes with or in any way disrupts the Joni.Authors Service, tampers with, breaches, or attempts to probe, scan, or test for vulnerabilities in the Service or Joni.Authors computer systems, network, usage rules, or any of Joni.Author’s security components, authentication measures or any other protection measures applicable to the Service, the Content or any part thereof; or
</li>
<li>conflicts with the Agreements, as determined by Joni.Authors.</li>
<li>
contains any third party copyright materials, or material subject to other third party proprietary rights (including rights of privacy or rights of publicity), unless you have a formal licence or permission from the rightful owner, or are otherwise legally entitled, to post the material in question and to grant Joni.Authors the licence
</li>
</ul>

<p>You acknowledge and agree that posting any User Content that violates these User guidelines (or that Joni.Authors reasonably believes violates these User guidelines) may result in immediate termination or suspension of your Joni.Authors account. You also agree that Joni.Authors may reclaim your username where it is reasonable for us to do so, including if you have violated the Agreements.
</p>
<p>
On becoming aware of any potential violation of these Terms, Joni.Authors reserves the right (but shall have no obligation) to decide whether Content complies with the content requirements set out in these Terms and may remove such Content and/or terminate a User’s access for uploading Content which is in violation of these Terms at any time, without prior notice or at its sole discretion. 
</p>
<p>
Please be thoughtful about how you use the Joni.Authors Service and the User Content you create. 
</p>
<p>
Your password protects your user account, and you are solely responsible for keeping your password confidential and secure. You understand that you are responsible for all use (including any unauthorized use) of your username and password on the Service. If your username or password is lost or stolen, or if you believe there has been unauthorized access to your account by a third party, you must notify us immediately and change your password as soon as possible.
</p>

<h2>8. Infringement and reporting User Content</h2>
<p>Joni.Authors respects the rights of intellectual property owners. If you believe that any Content infringes your intellectual property rights or other rights, see Joni’s copyright policy. If Joni.Authors is notified by a copyright holder, through email or otherwise, that any Content infringes a copyright, Joni.Authors may in its sole discretion remove such Content from the Service, or take other steps that Joni.Authors deems appropriate, without prior notification to the user or other party who supplied or posted that Content. If such user or other party believes that the Content is not infringing, he or she may in certain circumstances submit a counter-notification to Joni.Authors with a request to restore the removed content, which Joni.Authors may or may not honor, in Joni’s sole discretion.
</p>
<p>
If you believe that any Content does not comply with the User guidelines, please drop us an email at support@joni.ai.
</p>
<h2>9. Term and Termination </h2>
<p>
The Agreements will continue to apply to you until terminated by Joni.Authors. You acknowledge and agree that the perpetual license granted by you in relation to User Content, including Feedback, is irrevocable and will therefore continue after expiry or termination of any of the Agreements for any reason. Joni.Authors may terminate the Agreements or suspend your access to the Joni Service at any time, including in the event of your actual or suspected unauthorised use of the Joni Service and/or Content, non-compliance with the Agreements, or if we withdraw Services and/or Content (in which case we shall provide you reasonable notice in advance of doing so). If you or Joni.Authors terminate the Agreements, or if Joni.Authors suspends your access to the Joni Service, you agree that Joni.Authors shall have no liability or responsibility to you. You may terminate the Agreements at any time. This section will be enforced to the extent permissible by applicable law. Even after your account is terminated for any reason, all terms of this Agreement survive such termination, and continue in full force and effect, except or any terms that by their nature expire or are fully satisfied. 
</p>
<h2>10. Warranty Disclaimer </h2>
<p>
YOU UNDERSTAND AND AGREE THAT THE JONI.AUTHORS SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY KIND. JONI.AUTHORS AND ALL OWNERS OF THE CONTENT MAKE NO REPRESENTATIONS AND DISCLAIM ANY WARRANTIES OR CONDITIONS OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NEITHER JONI.AUTHORS NOR ANY OWNER OF CONTENT WARRANTS THAT THE JONI.AUTHORS SERVICE IS FREE OF MALWARE OR OTHER HARMFUL COMPONENTS. IN ADDITION, JONI.AUTHORS MAKES NO REPRESENTATION NOR DOES IT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY THIRD PARTY APPLICATIONS (OR THE CONTENT THEREOF), USER CONTENT, DEVICES OR ANY OTHER PRODUCT OR SERVICE ADVERTISED, PROMOTED OR OFFERED BY A THIRD PARTY ON OR THROUGH THE JONI.AUTHORS SERVICE OR ANY HYPERLINKED WEBSITE, OR FEATURED IN ANY BANNER OR OTHER ADVERTISING AND JONI.AUTHORS IS NOT RESPONSIBLE OR LIABLE FOR ANY TRANSACTION BETWEEN YOU AND THIRD PARTY PROVIDERS OF THE FOREGOING.
</p>
<p>
WITHOUT LIMITING THE FOREGOING, NOTHING IN THIS SECTION SHALL HAVE THE EFFECT OF LIMITING JONI.AI’S LIABILITY IN THE EVENT OF TOTAL OR PARTIAL NON-PERFORMANCE OR INADEQUATE PERFORMANCE OF ITS ESSENTIAL OBLIGATIONS FOR PROVIDING THE SERVICE UNDER THE AGREEMENTS. THIS SECTION APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
</p>
<p>
THIS SECTION DOES NOT AFFECT YOUR STATUTORY RIGHTS AS A CONSUMER.
</p>
<h2>11. Joni.Authors Content on the Website</h2>
<p>
With the exception of Content submitted to the Service by you, all other Content on the Service is either owned by or licensed to Joni.Authors, and is subject to copyright, trade mark rights, and other intellectual property rights of Joni.Authors or Joni.Authors’ licensors. Any third party trade or service marks present on Content not uploaded or posted by you are trade or service marks of their respective owners. Such Content may not be downloaded, copied, reproduced, distributed, transmitted, displayed, sold, licensed, or otherwise exploited for any other purpose whatsoever without the prior written consent of Joni.Authors or, where applicable, Joni.Authors’ licensors. Joni.Authors and its licensors reserve all rights not expressly granted in and to their Content.
</p>
<h2>12. Third Party Rights</h2>
<p>
When you upload or post Content to Joni.Authors, you grant to Joni.Authors a worldwide, non-exclusive, royalty-free, transferable licence (with right to sub-licence) to use, reproduce, distribute, prepare derivative works of or display the Content in connect with the provision of the Service, and otherwise in connection with the provision of the Service and Joni.AI’s business, including without limitation for promoting and redistributing part or all of the Service (and derivative works thereof) in any media formats and through any media channels. 
</p>

<p>
You also grant to each user of the Service, a worldwide, non-exclusive, royalty-free licence to access your Content through the Service, and to use, reproduce, distribute, prepare derivative works of and display such Content to the extent permitted by the functionality of the Service and under these Terms.
</p>

<p>
You acknowledge and agree that the owners of the Content and certain distributors (such as app store providers) are intended beneficiaries of the Agreements and have the right to enforce the Agreements directly against you. Other than as set out in this section, the Agreements are not intended to grant rights to anyone except you and Joni.Authors, and in no event shall the Agreements create any third party beneficiary rights. Furthermore, the right to terminate, rescind, or agree to any variation, waiver, or settlement of the Agreements are not subject to the consent of any other person.
</p>
<h2>13. Entire Agreement</h2>

<p>
Other than as stated in this section or as explicitly agreed upon in writing between you and Joni.Authors, the Agreements constitute all the terms and conditions agreed upon between you and Joni.AI and supersedes any prior agreements in relation to the subject matter of these Agreements, whether written or oral.
</p>

<p>
Please note, however, that certain aspects of your use of the Joni Service may be governed by additional agreements. That could include, for example, a one-time sign up bonus or referral. When you are presented with an offer for such aspects of your use, you will be presented with any related additional agreement, and you may have an opportunity to agree to additional terms. To the extent that there is any irreconcilable conflict between any additional terms and these Terms, the additional terms shall prevail.
</p>
<h2>14. Severability, Waiver and Interpretation</h2>
<p>
Unless as otherwise stated in the Agreements, should any provision of the Agreements be held invalid or unenforceable for any reason or to any extent, such invalidity or enforceability shall not in any manner affect or render invalid or unenforceable the remaining provisions of the Agreements, and the application of that provision shall be enforced to the extent permitted by law.
</p>
<p>
Any failure by Joni.Authors or any third party beneficiary to enforce the Agreements or any provision thereof shall not waive Joni.Authors’ or the applicable third party beneficiary’s right to do so.
</p>
<p>
As used in these Terms, the words “include” and “including,” and variations thereof, will be deemed to be followed by the words “without limitation.”
</p>
<h2>15. Assignment</h2>
<p>
Joni.Authors may assign the Agreements, and any of its rights under the Agreements, in whole or in part, and Joni.Authors may delegate any of its obligations under the Agreements. You may not assign the Agreements, in whole or in part, nor transfer or sub-license your rights under the Agreements, to any third party.
</p>
<h2>16. Governing law and jurisdiction</h2>
<p>
These Terms shall be governed by and construed in accordance with the laws of Singapore. Both you and Joni.Authors agree to submit to the exclusive jurisdiction of the courts of Singapore as regards any dispute or matter arising under these Terms.
</p>
<h2>17. Exclusion of Warranties</h2>
<p>
Nothing in the Terms shall affect any statutory rights that you are always entitled to as a consumer and that you cannot contractually agree to alter or waive.
</p>

<p>The Service is provided "as is" and Joni.Authors makes no warranty or representation to you with respect to them.
</p>

<p>
In particular Joni.Authors does not represent or warrant to you that:
</p>
<ol>
<li>
your use of the Service will meet your requirements,
</li>
<li>
your use of the Service will be uninterrupted, timely, secure or free from error,
</li>
<li>
any information obtained by you as a result of your use of the Service will be accurate or reliable, and
</li>
<li>
that defects in the operation or functionality of any software provided to you as part of the Service will be corrected.
</li>
</ol>
<p>
No conditions, warranties or other terms (including any implied terms as to satisfactory quality, fitness for purpose or conformance with description) apply to the Service except to the extent that they are expressly set out in the Terms.
</p>

<h2>18. Limitation of Liability</h2>
Nothing in these Terms shall exclude or limit Joni.Authors’ liability for losses which may not be lawfully excluded or limited by applicable law.
Subject to the overall provision in paragraph above Joni.Authors shall not be liable to you for:
<ol>
<li>
any indirect or consequential losses (damages, losses and expenses of any kind) which may be incurred by you. This shall include; (i) any loss of profit (whether incurred directly or indirectly); (ii) any loss of goodwill or business reputation; (iii) any loss of opportunity; or (iv) any loss of data suffered by you;
</li>
<li>
your violation of any law or the rights of a third party 
</li>
<li>
any activity in which you engage on or through the Joni.Authors Service;
</li>
<li>
any loss or damage which may be incurred by you as a result of:
</li>
<ol>
<li>
any reliance placed by you on the completeness, accuracy or existence of any advertising, or as a result of any relationship or transaction between you and any advertiser or sponsor whose advertising appears on the Service;
</li>
<li>
any changes which Joni.Authors may make to the Service, or for any permanent or temporary cessation in the provision of the Service (or any features within the Service);
</li>
<li>
the deletion of, corruption of, or failure to store, any Content and other communications data maintained or transmitted by or through your use of the Service;
</li>
<li>
your failure to provide Joni.Authors with accurate account information;
</li>
<li>
your failure to keep your password or Joni.Authors account details secure and confidential.
</li>
</ol></ol>
<p>The limitations on Joni.Authors’ liability to you in paragraph above shall apply whether or not Joni.Authors has been advised of or should have been aware of the possibility of any such losses arising.
</p>
<h2>19. Contact Us</h2>
<p>
If you have any questions concerning the Joni.Authors Service or the Agreements, please contact Joni.AI Customer Service by visiting the About Us section of https://www.joni.ai.
Thank you for reading our terms, we hope you enjoy Joni.Authors! 
</p>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, { signUp })(Legal);