import React from 'react';
import { InlineTex } from 'react-tex';
import { Tex } from 'react-tex';
import Question from './Question';
import '../Author.css';

import Loading from '../dashboard/Loading'


class MultiPartQuestion extends React.Component {
    state = { selected_qn: this.props.multiparts[this.props.qn_index], selected_index: this.props.qn_index };

    selectQuestion = (qn, index) => {
        this.setState({ selected_qn: qn, selected_index: index })
        this.props.selectQuestion(index);
    }

    componentDidUpdate = () => {
        if (this.state.selected_qn && this.props.multiparts[this.props.qn_index]){
            // console.log('diff text?', this.state.selected_qn.qn_txt !== this.props.multiparts[this.props.qn_index].qn_txt)
            if (this.state.selected_qn.qn_txt !== this.props.multiparts[this.props.qn_index].qn_txt||
                this.state.selected_qn.qn_img !== this.props.multiparts[this.props.qn_index].qn_img){
                    // console.log(this.state.selected_qn.qn_txt)
                    // console.log(this.props.multiparts[this.props.qn_index].qn_txt)
                    // console.log(this.props.multiparts[this.props.qn_index])
                    this.setState({selected_qn: this.props.multiparts[this.props.qn_index], selected_index: this.props.qn_index})
            }
        }
    }

    render() {
        function nextChar(c, index) {
            return String.fromCharCode(c.charCodeAt(0) + index);
        }

        let indexArray = []
        let curIndex = -1
        let navigation = this.props.multiparts.map((qn, index) => {  // generate multipart navigation bar
            if (qn){
                curIndex++
                indexArray.push(curIndex)
                //console.log(indexArray[index], console.log(this.state.selected_index))
                if (indexArray[index] === this.state.selected_index) { 
                    if (this.state.selected_qn !== this.props.multiparts[index]){
                        this.setState({selected_qn: this.props.multiparts[index]})
                    }
                    return <li className="nav-item" key={qn.id} onClick={() => this.selectQuestion(qn, indexArray[index])}>
                        <small className="nav-link active">({nextChar("a", indexArray[index])})</small>
                    </li>
                } else {
                    return <li className="nav-item" key={qn.id} onClick={() => this.selectQuestion(qn, indexArray[index])}>
                        <small className="nav-link">({nextChar("a", indexArray[index])})</small>
                    </li>
                }
            }else{
                indexArray.push(null)
            }
        })

        let header_txt;
        let header_img;

        if (this.props.multiparts[0].header_img === '') {  // qn_img = null if there is no image for that part
            header_img = <span></span>
        } else {
            header_img = <div>
                <img className="img-fluid" src={this.props.multiparts[0].header_img} alt="lol" />
                <br /><br/>
            </div>
        }

        if (this.props.isLoading){
            header_img = <Loading />
        }

        if (this.props.multiparts[0].header_txt){
            let seperator = "\${1,2}";
            if (this.props.multiparts[0].header_txt.startsWith("\\begin")) {
                header_txt = <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={this.props.multiparts[0].header_txt} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            } else {
                header_txt = <div><InlineTex texSeperator={seperator} texContent={this.props.multiparts[0].header_txt} /></div>
            }
        } else {
            header_txt = <span>Your header will appear here.</span>
        }
        
        return <span>
            <p className="question-text">{header_txt}</p>
            {header_img}
            <ul className="nav nav-pills nav-fill">
                {navigation}
            </ul>
            <Question multi={true} qn={this.state.selected_qn} />
        </span>
    }
}

export default MultiPartQuestion