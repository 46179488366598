import React from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {connect} from 'react-redux'

import {filterLevel, filterTopic} from '../../actions'

import kopi from '../../apis/kopi'

class Filter extends React.Component{
    _isMounted = false;
    state = {showTopics: false, showLevel: false, topics: []}

    componentDidMount = async () => {
        this._isMounted = true;
        const response = await kopi.get('/authoring/topics')
        // if (this.props.level){
        //     this.filterLevel(this.props.level)
        // }
        // if (this.props.topic){
        //     this.filterTopic(this.props.topic)
        // }
        if (this._isMounted){
            this.setState({topics: response.data})
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }
    
    filterLevel = async (level) => {
        await this.props.filterLevel(level, '')
        if (this._isMounted){
            await this.setState({showLevel: false})
            this.props.filterQuestions()
        }
    }
    

    filterTopic = async (selected_topic) => {
        await this.props.filterTopic(selected_topic)
        if (this._isMounted){
            await this.setState({showTopics: false})
            this.props.filterQuestions()
        }
    }  

    clearFilter = async (event) => {
        let questions = [];
        if (event.currentTarget.id === 'level'){
            await this.props.filterLevel(0, '')
            this.props.filterQuestions(questions)
        } else if (event.currentTarget.id === 'topic'){
            await this.props.filterTopic('')
            this.props.filterQuestions(questions)
        }
    }

    renderClear = (name) => {
        if ((name === 'level' && this.props.level) || (name === 'topic' && this.props.topic)){
            return <span className="clear-filter" id={name} onClick={this.clearFilter}><small>clear</small></span>
        } else {
            return <span></span>
        }
    }
    
    renderTopics = () => {
        let topics = []
        if (this.props.level){
            this.state.topics.forEach(topic => topic.level === this.props.level ? topics.push(topic) : null)
        } else {
            topics = this.state.topics
        }
        return topics.map(topic => <button  key={topic._id} className="dropdown-item long-button-item" value={JSON.stringify(topic)} onClick={(e)=>this.filterTopic(e.target.value)}>{topic.title}</button>)
    }

    renderLevel = (level) => {
        if (level === "3, 4 & 5" || level === 3) {
            return "3, 4 & 5"
        } else {
            return level
        }
    }


    renderSort = (level) => {
        if (level === "3, 4 & 5" || level === 3) {
            return "3, 4 & 5"
        } else {
            return level
        }
    }

    renderSubmitButton = () => {
        // if (this.props.mode === 'Published' || this.props.mode === 'EditorReview'){
        //     return <span></span>
        // } else {
        //     const popover = <Popover>{this.props.submitForReview ? 'All selected questions are ready for review!' : this.props.selectedQns.length ? 'One or more selected questions are not ready for review. Please check through your selection.' : 'No questions selected.'}</Popover>
        //     const style= {pointerEvents: this.props.submitForReview ? 'all' : 'none'}
        //     const text = this.props.mode === 'Draft' ? 'Submit for Review' : 'Re-submit for Review'

        //     return (
        //         <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
        //             <div className="col-4 text-center">
        //                 <button className={`btn btn-sm btn-dark${this.props.submitForReview ? '' : ' disabled'}`} onClick={this.props.onClickHandler} disabled={!this.props.submitForReview} aria-disabled={!this.props.submitForReview} style={style}>{text}</button>
        //             </div>
        //         </OverlayTrigger>
        //     )
        // }
    }

    render(){
        // console.log(this.props.topic)
        return (
            <div className="container" style={{paddingBottom: "10px"}}>
                <div className="row">
                <div className="dropdown col-4" style={{padding: "0px"}}>
                    <small>Filter by Level: </small>&nbsp;
                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>this.setState({showLevel: !this.state.showLevel})}>
                        {this.props.level ? `Sec ${this.renderLevel(this.props.level)}` : 'Select level'}
                    </button> &nbsp;
                    {this.renderClear('level')}
                    <div className={`dropdown-menu${this.state.showLevel ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                        {[1,2,"3, 4 & 5"].map(level => <button key={level} className="dropdown-item" value={level} onClick={(e)=>{
                                if (e.target.value === "3, 4 & 5") {
                                    this.filterLevel(3)
                                } else {
                                    this.filterLevel(Number(e.target.value))
                                }
                            }
                        }>Sec {this.renderLevel(level)}</button>)}
                    </div>
                    
                </div> 
             
                <div className="dropdown col-4" style={{padding: "0px"}} >
                    <small>Filter by Topic: </small>&nbsp;
                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>this.setState({showTopics: !this.state.showTopics})}>
                        {this.props.topic ? JSON.parse(this.props.topic).title :'Select topic'}
                    </button> &nbsp;
                    {this.renderClear('topic')}
                    <div className={`dropdown-menu${this.state.showTopics ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                        {this.renderTopics()}
                    </div>
                </div>

                {this.renderSubmitButton()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { level: state.filter.level, topic: state.filter.topic }
}

export default connect(mapStateToProps, {filterLevel, filterTopic})(Filter)