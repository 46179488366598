import React from 'react'

const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
    if (relatedTarget === null) return false;
    
    var node = relatedTarget.parentNode;
          
    while (node !== null) {
      if (node === currentTarget) return true;
      node = node.parentNode;
    }
  
    return false;
}

const onBlur = (e, props) => {
    if (!focusInCurrentTarget(e)) {
        props.closeMenu();
    }
}

const Dropdown = (props) => {
    const className = `dropdown-menu${props.isOpen ? " show" : ""}`;
    return (
        <div className="dropdown" onBlur={(e)=>onBlur(e,props)}>
        <button className={`btn ${props.color} dropdown-toggle btn-block`} type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={props.updateFlag}>
            {props.display}
        </button>
        <div className={className} aria-labelledby="dropdownMenu2" style={{width:'100%'}} onBlur={(e)=> props.type === 'topic' || props.type === 'tag' ? onBlur(e,props) : null}>
            {props.renderListItem()}
        </div>
        </div>
    )
}


  
  

export default Dropdown