import React from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import {connect} from 'react-redux'

import Filter from './Filter'
import Loading from './Loading'
import kopi from '../../apis/kopi'
import history from '../../history'

const columns = [
    {name: 'Author Name', selector: 'author',  wrap: true},
    {name: 'Name', selector: 'name',  wrap: true},
    {name: 'Topic', selector: 'topics',  wrap: true},
    {name: 'Level', selector: 'level',  grow: true},
    {name: 'Parts', selector: 'parts', grow: true},
    {name: 'Published on', selector: 'published_at'},
    {name: 'No. of Issues Flagged', selector: 'flags'}  // change the selector for issues flagged
];

let data = []
let qns;

class Published extends React.Component{
    _isMounted = false;
    state = {allQnCount: 0, page: 1, rerender: false, qns: [], flags: [],loading: false}

    componentDidMount = async () => {
        this._isMounted = true;
        if (this._isMounted){
            this.setState({loading: true})
        }
        const response = await kopi.get('/dashboard/geteditorpublished', {params: {userId: this.props.userId,  page: this.state.page, level_filter: this.props.level, topic_filter: this.props.topic}}) // page number for pagination?
        if (response.data === 'not logged in'){
            history.push('/login')
        } else {
            qns = this.modifyFormat(response.data[0], response.data[1])        
            data = qns
            if (this._isMounted){
                this.setState({qns: response.data[0], flags: response.data[1], allQnCount: response.data[2], loading: false})      
            } 
        }     
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    modifyFormat = (qns, flags) => {
        const modified_qns = qns.map((qn,index) => {
            const modified = {}
            
            Object.keys(qn).forEach((key) => {
                if (key === 'topics'){
                    const titles = qn.topics.map(topic => topic.title)
                    modified.topics = titles.join(', ')
                } else if (key === 'published_at'){
                    modified[key] = moment(qn[key]).format('YYYY-MM-DD HH:mm:ss') // .fromNow()
                } else if (key==='level'){
                    if (qn.level === 3){
                        modified.level = `Upper Sec`
                    } else {
                        modified.level = `Sec ${qn.level}`
                    }
                } else if (key==='author'){
                    modified.author = qn.author.first_name + ' ' + qn.author.last_name
                } else if (key==='flags') {
                    modified[key] = `${flags[index]}`
                } else {
                    modified[key] = qn[key]
                }
            })
            return modified
        })
        return modified_qns
    }

    onSelect = (state) => {
        history.push(`/locked/${state._id}`)
    }

    filterQuestions = async (page_change) => {
        let response
        if (page_change){
            response = await kopi.get('/dashboard/geteditorpublished', {params: {userId: this.props.userId,  page: this.state.page+Number(page_change), level_filter: this.props.level, topic_filter: this.props.topic}}) // page number for pagination?
         } else {
            response = await kopi.get('/dashboard/geteditorpublished', {params: {userId: this.props.userId,  page: 1, level_filter: this.props.level, topic_filter: this.props.topic}}) // page number for pagination?
         }
         qns = this.modifyFormat(response.data[0], response.data[1])
         data = qns
         if (page_change){
             this.setState({qns: response.data[0], flags: response.data[1], allQnCount: response.data[2], loading: false ,page: this.state.page + Number(page_change)})    
         } else {
             this.setState({qns: response.data[0], flags: response.data[1], allQnCount: response.data[2], loading: false ,page: 1})    
         } 
 
    }

    renderFilter = () => {
        return <Filter qns={this.state.qns} filterQuestions={this.filterQuestions} mode="Published" />
    }


   
    renderPagination = (position) => {
        let showing = (this.state.page-1)*10+1 === (this.state.page-1)*10 + this.state.qns.length ? (this.state.page-1)*10 + this.state.qns.length : ((this.state.page-1)*10+1) + " to " + ((this.state.page-1)*10 + this.state.qns.length)
        if (this.state.allQnCount === 0){
            showing = 0;
        }
        let pagination = <div>
                            <nav>
                                <ul className="pagination justify-content-end">
                                <li class={`page-item ${this.state.page === 1 ? 'disabled' : ''}`}><span class="page-link" onClick={async ()=>{
                                        if (this.state.page !== 1) {
                                            this.filterQuestions(-1);
                                        } 
                                    }} className="page-link">Previous</span></li>
                                      <li className="page-item"><span className="page-link" className="page-link">Showing {showing} of {this.state.allQnCount}</span></li>
                                    <li className={`page-item ${qns && ((this.state.page-1)*10+qns.length)===this.state.allQnCount ? 'disabled' : ''}`}><span className="page-link" onClick={async ()=>{
                                        if (qns && qns.length===10) {
                                            this.filterQuestions(1);
                                        }
                                    }}>Next</span></li>                                
                                </ul>
                            </nav>
                        </div>
        if (position=="top"){
            return pagination
        } else {
            if (qns && qns.length > 5) {
                return pagination
            } else {
                return <></>
            }
        }
    }

    render(){
        if (this.state.loading){
            return <Loading />
        } else {
            return (
                <div>
                    <div className="container">
                        <div className="container-fluid row">
                            <h2 className="" style={{paddingBottom: "10px"}}>Questions</h2>
                        </div>
                        {this.renderFilter()}
                        {this.renderPagination("top")}
                        <DataTable 
                        title="Questions" 
                        data={data}
                        columns={columns}
                        onRowClicked={this.onSelect} pointerOnHover
                        striped = {true} noHeader={true}
                        />
                    <br/>
                    {this.renderPagination("bottom")}
                    </div>
                    <br/><br/><br/>
                </div>
    
            )
        }
    }
}

const mapStateToProps = (state) => {
    return { level: state.filter.level, topic: state.filter.topic }
}

export default connect(mapStateToProps, {})(Published)