import React from 'react';

import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

import Loading from '../dashboard/Loading'

import '../Author.css';

const Solution = props => {
    let seperator = "\${1,2}";
    let sol_txt, sol_img, sol_ans;

    if (!props.qn.ans && props.qn.qn_type !== 'self'){
        sol_ans = <p className="question-text"><span>Your answer will appear here.</span></p>
    } else if (props.qn.qn_type !== 'self') {
        if (props.qn.units === "\$"){
            sol_ans = <div className="alert alert-success">
                <InlineTex texSeperator={seperator} texContent={props.qn.prefix + " " + props.qn.units + props.qn.ans + " " + props.qn.suffix} />               
            </div>
        } else {
            sol_ans = <div className="alert alert-success">
                <InlineTex texSeperator={seperator} texContent={props.qn.prefix + " " + props.qn.ans + props.qn.units + " " + props.qn.suffix} />               
            </div>
        }
    } 
    
    if (props.qn.sol_txt){
        if (props.qn.sol_txt.startsWith("\\begin")){
            sol_txt = <div className="table-responsive">
                <table className="table table-solution">
                    <tbody>
                    <tr>
                        <td>
                            <p className="solution-text">
                                <Tex texContent={props.qn.sol_txt} />
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        } else {
            sol_txt = <div className="solution-text"><InlineTex texSeperator={seperator} texContent={props.qn.sol_txt} /></div>
        }
    } else {
        sol_txt = <span>Your solution will appear here.</span>
    }

    if (!props.qn.sol_img || props.qn.sol_img === ' ') {
        sol_img = <span></span>
    } else {
        sol_img = <div><img className="img-fluid" src={`${props.qn.sol_img}`} alt="lol" /></div>
    }

    if (props.isLoading){
        sol_img = <Loading />
    }

    return (
        <div>  
            {sol_ans}
            {sol_img}
            <p className="question-text">{sol_txt}</p>
        </div>
    )
}

export default Solution