import React from 'react';
import kopi from '../../apis/kopi'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class EmailVerified extends React.Component{

  state = {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        email: "",
        password: ""
  }

  componentWillMount = () => {
    window.scrollTo(0, 0);
   }
    render() {
        return (
            <div class="jumbotron jumbotron-fluid">
                <div class="container">
                    <h1 class="display-4">Email verified!</h1>
                    <p>Click <Link to="/login">here to log in</Link></p>
                </div>
            </div>
        );
      }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {})(EmailVerified);
