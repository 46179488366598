import React from 'react'
import history from '../../history'

const Error = () => {
    return (
        <div className="container justify-content-center align-content-center">
            <h1 className=""><strong>Error</strong></h1>
            <h6 className="">You currently do not have permission to enter this page.</h6>
            <button className="btn btn-secondary btn-lg" onClick={()=>history.push('/dashboard')}>Back to Dashboard</button>
        </div>
    )
}

export default Error