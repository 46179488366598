import React from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'

import { connect } from 'react-redux'

import Filter from './Filter'
import Loading from './Loading'
import kopi from '../../apis/kopi'
import history from '../../history'

import { filterLevel, filterTopic } from '../../actions'

const columns = [
    { name: 'Name', selector: 'name', wrap: true },
    { name: 'Topic', selector: 'topics', wrap: true },
    { name: 'Level', selector: 'level', grow: true },
    { name: 'Parts', selector: 'parts', grow: true },
    { name: 'Created on', selector: 'created_at', },
    { name: 'Last modified', selector: 'modified_at', },
    { name: 'Ready for Review?', selector: 'ready', }
];

let data = []
let qns

class Draft extends React.Component {
    // mode: Draft, Review, Published
    _isMounted = false;
    state = { allQnCount: 0, page: 1, rerender: false, qns: [], loading: false, submitForReview: false, selectedQns: [] }

    componentDidMount = async () => {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ loading: true })
        }
        // const response = await kopi.get('/dashboard/getdrafts', {params: {userId: this.props.userId}}) // page number for pagination?

        const response = await kopi.get('/dashboard/getdrafts', { params: { userId: this.props.userId, page: this.state.page, level_filter: this.props.level, topic_filter: this.props.topic } }) // page number for pagination?

        // console.log(response.data)
        if (response.data === 'not logged in') {
            history.push('/login')
        } else if (response.data === 'verify email') {
            history.push('/verify')
        } else {
            qns = this.modifyFormat(response.data[0])
            data = qns
            if (this._isMounted) {
                this.setState({ qns: response.data[0], allQnCount: response.data[1], loading: false })
            }
        }

    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    modifyFormat = (qns) => {
        const modified_qns = qns.map(qn => {
            const modified = {}

            Object.keys(qn).forEach(key => {
                if (key === 'topics') {
                    const titles = qn.topics.map(topic => topic.title)
                    modified.topics = titles.join(', ')
                } else if (key === 'created_at' || key === 'modified_at') {
                    modified[key] = moment(qn[key]).format('YYYY-MM-DD HH:mm:ss') // .fromNow()
                } else if (key === 'ready') {
                    qn.ready_number = qn.ready_number === 10 ? String(qn.ready_number) : String(qn.ready_number).startsWith('0') ? qn.ready_number : `0${qn.ready_number}`
                    modified[key] = qn.ready ? `Yes (${qn.ready_number}/10)` : `No (${qn.ready_number}/10)`
                } else if (key === 'level') {
                    if (qn.level === 3) {
                        modified.level = `Upper Sec`
                    } else {
                        modified.level = `Sec ${qn.level}`
                    }
                } else {
                    modified[key] = qn[key]
                }
            })

            return modified
        })

        return modified_qns
    }

    showQuestion = (state) => {
        history.push(`/create/${state._id}`)
    }

    filterQuestions = async (page_change) => {
        // const modified_qns = this.modifyFormat(qns)
        // data = modified_qns
        // if (this._isMounted){
        //     this.setState({rerender: !this.state.rerender})    
        // }
        // if (this.props.level)

        let response;
        if (page_change) {
            response = await kopi.get('/dashboard/getdrafts', { params: { userId: this.props.userId, page: this.state.page + Number(page_change), level_filter: this.props.level, topic_filter: this.props.topic } }) // page number for pagination?
        } else {
            response = await kopi.get('/dashboard/getdrafts', { params: { userId: this.props.userId, page: 1, level_filter: this.props.level, topic_filter: this.props.topic } }) // page number for pagination?
        }
        qns = this.modifyFormat(response.data[0])
        data = qns
        if (page_change) {
            this.setState({ qns: response.data[0], allQnCount: response.data[1], loading: false, page: this.state.page + Number(page_change) });
        } else {
            this.setState({ qns: response.data[0], allQnCount: response.data[1], loading: false, page: 1 });
        }
    }

    submitForReview = async () => {
        // change status to "Review in db"
        // console.log('submitting')
        const response = await kopi.post('/dashboard/updatestatus', { qns: this.state.selectedQns, userId: this.props.userId })
        // post to /sendforapproval once there are userIds with {qnId: this.props.qns[0].id}, 
        // but you need to make adjustments to /sendforapproval so that it will iterate over all the questions
        if (response.data) {
            window.location.reload()
        }
    }

    handleChange = (state) => {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        let ready
        if (state.selectedRows.length === 0) {
            ready = false
        } else {
            ready = true
            state.selectedRows.forEach(qn => {
                if (qn.ready.slice(0, 2) === 'No') {
                    ready = false
                }
            })
        }
        if (this._isMounted) {
            if (ready) {
                this.setState({ submitForReview: true, selectedQns: state.selectedRows })
            } else {
                this.setState({ submitForReview: false, selectedQns: state.selectedRows })
            }
        }
    };


    renderFilter = () => {
        // if (this.state.qns.length){
        return <Filter qns={this.state.qns} filterQuestions={this.filterQuestions} onClickHandler={this.submitForReview} submitForReview={this.state.submitForReview} selectedQns={this.state.selectedQns} mode="Draft" />
        // } else {
        //     return <span></span>
        // }
    }

    renderPagination = (position) => {
        let showing = (this.state.page - 1) * 10 + 1 === (this.state.page - 1) * 10 + this.state.qns.length ? (this.state.page - 1) * 10 + this.state.qns.length : ((this.state.page - 1) * 10 + 1) + " to " + ((this.state.page - 1) * 10 + this.state.qns.length)
        if (this.state.allQnCount === 0) {
            showing = 0;
        }
        let pagination = <div>
            <nav>
                <ul className="pagination justify-content-end">
                    <li class={`page-item ${this.state.page === 1 ? 'disabled' : ''}`}><span class="page-link" onClick={async () => {
                        if (this.state.page !== 1) {
                            this.filterQuestions(-1);
                        }
                    }} className="page-link">Previous</span></li>
                    <li className="page-item"><span className="page-link" className="page-link">Showing {showing} of {this.state.allQnCount}</span></li>
                    <li className={`page-item ${qns && ((this.state.page - 1) * 10 + qns.length) === this.state.allQnCount ? 'disabled' : ''}`}><span className="page-link" onClick={async () => {
                        if (qns && qns.length === 10) {
                            this.filterQuestions(1);
                        }
                    }}>Next</span></li>
                </ul>
            </nav>
        </div>
        if (position == "top") {
            return pagination
        } else {
            if (qns && qns.length > 5) {
                return pagination
            } else {
                return <></>
            }
        }
    }


    render() {
        if (this.state.loading) {
            return <Loading />
        } else {
            return (
                <div>
                    <div className="container">
                        <div className="container-fluid row">
                            <h2 className="col-4" style={{ paddingBottom: "10px", paddingLeft: '0px' }}>Draft Questions</h2>
                            <span className="col-8 text-right">
                                <button className="btn btn-info" onClick={() => history.push('/create/e')}>Create 'E Math' Question</button> &nbsp; &nbsp;
                                <button className="btn btn-info" onClick={() => history.push('/create/a')}>Create 'A Math' Question</button>      <br/>                          <br/>      
                                <a onClick={
                                    () => kopi.post('/admin/author-guide', {
                                        userId: this.props.currentUser
                                    })
                                }
                                    download href="./pdf/User Guide.pdf">Download User Guide</a>
                            </span>
                        </div><br />
                        {this.renderFilter()} <br />
                        {this.renderPagination("top")}
                        <DataTable
                            title="Questions"
                            data={data}
                            noDataComponent='Create a new question for it to appear here.'
                            columns={columns}
                            onRowClicked={this.showQuestion} pointerOnHover
                            selectableRows onTableUpdate={this.handleChange}
                            striped={true} noHeader={true}
                        />
                        <br />
                        {this.renderPagination("bottom")}
                    </div>
                    <br /><br /><br />
                </div>

            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        level: state.filter.level, topic: state.filter.topic,
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        privilege: Number(state.auth.privilege),
    }
}

export default connect(mapStateToProps, { filterLevel, filterTopic })(Draft)