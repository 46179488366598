import React, { Component } from 'react';

import JoniHeader from './JoniHeader'
import Question from './Question'
import MultipartQuestion from './MultipartQuestion'
import AnswerForm from './AnswerForm'
import Solution from './Solution'
import MultipartSolution from './MultipartSolution'

import '../Author.css';

class Smartphone extends Component{

  state = { 
    selected_qn_index: this.props.selectedIndex
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selected_qn_index !== this.props.selectedIndex){
      this.setState({selected_qn_index: this.props.selectedIndex})
    }
  }

  selectQuestion = (index) => {
    this.setState({selected_qn_index: index});  // will re-render page when another part is selected in preview
    this.props.updateSelectedIndex(index)
  }

  render(){
    // To change the size of the smartphone
    // Smartphone dimensions: (class smartphone in css file)
      // height: auto, width: 290px
    // Screen dimensions: (class container-fluid in css file)
      // height: 420px, width: 260px
    // Navbar bottom dimensions: (answer form - rendersubmit)
      // width: 240px
    if (this.props.view === 'question'){
      if (this.props.qns.length > 1 && this.props.isMulti(this.props.qns)){  // so that when qns[1] = null, single part view will be rendered
        return (
          <div className="smartphone">
            <div className='container-fluid screen'>
              <JoniHeader />
              <br/>
              <div className="">
                <div className="">
                  <MultipartQuestion isLoading={this.props.isLoading} selectQuestion={this.selectQuestion} qn_index={this.state.selected_qn_index} multiparts={this.props.qns} />
                </div>
                <div className="">
                  <AnswerForm isLoading={this.props.isLoading} qn={this.props.qns[this.state.selected_qn_index]} />
                  <br/><br/><br/>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="smartphone">
            <div className='container-fluid screen'>
              <JoniHeader />
              <br/>
              <div className="">
                  <div className="">
                      <Question isLoading={this.props.isLoading} multi={false} qn={this.props.qns[0]} />
                  </div>
                  <div className="">
                    <AnswerForm isLoading={this.props.isLoading} qn={this.props.qns[this.state.selected_qn_index]} />
                    <br/><br/><br/>
                  </div>
              </div> 
            </div>
          </div>
        )
      }
    } else {  
      if (this.props.qns.length > 1 && this.props.isMulti(this.props.qns)){
        return (
          <div className="smartphone">
            <div className='container-fluid screen'>
              <JoniHeader />
              <br/>
              <div className="">
                  <MultipartSolution isLoading={this.props.isLoading} selectQuestion={this.selectQuestion} qn_index={this.state.selected_qn_index} multiparts={this.props.qns} />
                  <br/><br/><br/>
              </div> 
            </div>
          </div>
        )
      } else {
        return (
          <div className="smartphone">
            <div className='container-fluid screen'>
              <JoniHeader />
              <br/>
              <div className="">
                <div className="">
                  <Solution isLoading={this.props.isLoading} multi={false} qn={this.props.qns[this.state.selected_qn_index]} />
                  <br/><br/><br/>
                </div>
              </div> 
            </div>
          </div>
        )
      }
    }
  }
}

export default Smartphone

// qns:
//     [{header_txt: 'It is given that $y$ is inversely proportional to the\r\nsquare root of $x+2$ and $y=3$ when $x=-1 $.',
//     header_img: 'http://www.petmd.com/sites/default/files/what-does-it-mean-when-cat-wags-tail.jpg',
//     qn_txt: 'Express $y$ in terms of $x$',
//     qn_img: '',
//     sol_txt: '\\begin{aligned}y=\\frac{k}{\\sqrt{x+2}}, \\text { where } k \\text { is a constant. }\\end{aligned}\\\\\r\n\\begin{aligned}\\text { When } x &=-1, y=3 \\\\ 3 &=\\frac{k}{\\sqrt{(-1)+2}} \\\\ 3 &=\\frac{k}{\\sqrt{1}} \\\\ \\therefore k &=3 \\\\ \\therefore y &=\\frac{3}{\\sqrt{x+2}} \\end{aligned}',
//     sol_img: '',
//     prefix: 'this has a prefix too',
//     suffix: '',
//     units: '',
//     ans: '$y=\\frac{3}{\\sqrt{x+2}}$',
//     alt_ans_1: '$y=\\frac{2}{\\sqrt{x+2}}$',
//     alt_ans_2: '$y=\\frac{3}{\\sqrt{x+3}}$',
//     alt_ans_3: '$y=\\frac{3}{x+3}$',
//     qn_type: 'mcq'},
//     {header_txt: 'header2',
//     header_img: '',
//     qn_txt: 'question2',
//     qn_img: 'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80',
//     sol_txt: 'solution',
//     sol_img: 'http://res.cloudinary.com/dwxuq2vjl/image/upload/v1553004367/demo/om0eoxz5yzscmmxasv9w.png',
//     prefix: 'a prefix',
//     suffix: 'a suffix',
//     units: '$',
//     ans: '23',
//     alt_ans_1: '45',
//     alt_ans_2: '67',
//     alt_ans_3: '89',
//     qn_type: 'text'}],