import React from 'react';
import kopi from '../../apis/kopi'
import {connect} from 'react-redux';
import { signIn } from '../../actions';
import { ReactComponent as Logo} from '../../white-joni-authors-logo.svg';
import {Link} from 'react-router-dom';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-140836063-2');
ReactGA.pageview(window.location.pathname + window.location.search);



class Auth extends React.Component{

  state = {
        email: "",
        password: "",
  }

  renderError = () => {
      if (this.props.location.state){
        if (this.props.location.state.wrongInfo) {
          return "Wrong Username/Password"
        } else {
        return <></>
      }
      } else {
        return <></>
      }
  }

  componentWillMount = () => {
    window.scrollTo(0, 0);
   }

   loginUser = () => {
        this.props.signIn({
            email: this.state.email.toLowerCase(),
            password: this.state.password,
        });

    }
  
    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        this.props.signIn({
          email: this.state.email.toLowerCase(),
          password: this.state.password,
        });
      }
    }

    render() {
        return (
          <div className ="" style={{ background: "#212121", minHeight: "100vh" }}>
          <div className="container centralise" align="center">
          <Link to='/'><Logo style={{width: "75%", paddingBottom:"2rem"}}/></Link>
            <h1 className="lead">Login</h1> 
            <small className="error-messages">{this.renderError()}</small>
            <br/>
            <input onChange={(e)=>{this.setState({email: e.target.value})}} value={this.state.email} type="email" className="no-border" placeholder="Email"/> <br/>
            <input onChange={(e)=>{this.setState({password: e.target.value})}} value={this.state.password} type="password" className="no-border" placeholder="Password" onKeyDown={this._handleKeyDown}/> <br/> <br/>
            <button onClick={this.loginUser} className="btn btn-dark btn-block"> Login </button> 
            <p ><Link className='txt-white txt-register' to="/onboarding">Register an account</Link></p>
          </div>
          </div>
        );
      }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {signIn})(Auth);
