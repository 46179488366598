import React from 'react';

import {InlineTex} from 'react-tex';

import '../Author.css';

class AnswerForm extends React.Component {

    renderOption(ans){
        if (!ans){
            return <span></span>
        } else {
            let test = "\${1,2}";
            return (
                <div className="alert alert-primary">
                    <InlineTex texSeperator={test} texContent={ans} />               
                </div>
            )
        }
    }

    isMCQ(){
        return <div>
            <div align="center">
                {this.renderOption(this.props.qn.ans)}
                {this.renderOption(this.props.qn.ans_alt1)}
                {this.renderOption(this.props.qn.ans_alt2)}
                {this.renderOption(this.props.qn.ans_alt3)}
            </div>
        </div>;
    }
    
    isText(){
        const {prefix, suffix} = this.props.qn 
        let seperator = "\${1,2}";
        if (this.props.qn.units && this.props.qn.units !== ""){ // if question has units
            if (this.props.qn.units === "\$"){
                return <div>
                <InlineTex texSeperator={seperator} texContent= {prefix} />
                    <form>
                        <div className="input-group">
                            <div className="input-group-append">
                                <span className="input-group-text">
                                <InlineTex texSeperator={seperator} texContent= {this.props.qn.units} />              
                                </span>
                            </div>
                            <input className="form-control" type="text" placeholder="" />
                        </div> 
                    </form>                    
                    <InlineTex texSeperator={seperator} texContent= {suffix} />
                    <br/><br/>
                </div>;
            } else {
                return <div>
                <InlineTex texSeperator={seperator} texContent= {prefix} />
                <form>
                    <div className="input-group">
                        <input className="form-control" type="text" placeholder="" />
                        <div className="input-group-append">
                            <span className="input-group-text">
                            <InlineTex texSeperator={seperator} texContent= {this.props.qn.units} />              
                            </span>
                        </div>
                    </div> 
                </form>                    
                <InlineTex texSeperator={seperator} texContent= {suffix} />
                <br/><br/>
            </div>; 
            }
        } else {
            return <div>
                <InlineTex texSeperator={seperator} texContent= {prefix} />
                <form>
                <div className="input-group">
                        <input className="form-control" type="text" placeholder="Your Answer" />
                    </div> 
                </form>
                <InlineTex texSeperator={seperator} texContent= {suffix} />
             </div>;
        }
    }

    renderSubmitBar(){
        return  <nav className="navbar navbar-light bg-light" style={{width: '240px'}}>
            <ul className="navbar-nav ml-auto">
                <form>
                    <button className="btn btn-md btn-primary">Confirm Answer</button>
                </form>
            </ul>
        </nav>  
    }
    
    render(){
        let seperator = "\${1,2}";
        if (this.props.qn){
            if (this.props.qn.qn_type === "text") {
                if (this.props.qn.prefix || this.props.qn.suffix){
                    return (
                        <span>
                            <small>Answer</small><br/>
                            {this.isText()}
                        </span>
                    );
                    } else {
                        return <span>
                            <small>Answer</small>
                            {this.isText()}
                        </span>
                    }
            } else if (this.props.qn.qn_type === "mcq"){
                    if (this.props.qn.prefix || this.props.qn.suffix){
                        if (this.props.qn.units === "\$"){
                            return <span>
                                <small>Answer</small><br/>
                                <InlineTex texSeperator={seperator} texContent={this.props.qn.prefix} /> &nbsp;
                                <InlineTex texSeperator={seperator} texContent={this.props.qn.units} /> 
                                _____  &nbsp;
                                <InlineTex texSeperator={seperator} texContent={this.props.qn.suffix} /> 
                                <br/><br/>
                                {this.isMCQ()}
                            </span>
                        } else {
                            return <span>
                                <small>Answer</small><br/>
                                <InlineTex texSeperator={seperator} texContent={this.props.qn.prefix} /> &nbsp;
                                _____ <InlineTex texSeperator={seperator} texContent={this.props.qn.units} />  &nbsp;
                                <InlineTex texSeperator={seperator} texContent={this.props.qn.suffix} /> 
                                <br/><br/>
                                {this.isMCQ()}
                            </span>
                        }
                    } else {
                        return <span>
                            {this.isMCQ()}
                        </span>
                    }
            } else if (this.props.qn.qn_type === "self"){
                return <div>
                    <div align="center">
                        {this.renderOption("It's perfect")}
                        {this.renderOption("I almost got it")}
                        {this.renderOption("I am able to make some progress")}
                        {this.renderOption("I don't know how to start")}
                    </div>
                </div>;
            }
            
            else {
                return <span></span>
            }
        } else {
            return <span></span>
        }  
    }
}

export default AnswerForm