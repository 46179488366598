import React from 'react'
import {OverlayTrigger} from 'react-bootstrap';
import Fuse from 'fuse.js'
import TextareaAutosize from 'react-autosize-textarea';

import kopi from '../../apis/kopi'
import '../Author.css';
import LOPopover from './LOPopover'
import PopoverStickOnHover from './PopoverStickOnHover'
import Latex from './Latex'

class Solutions extends React.Component{
    
    state = {
        subobjs: [], 
        search: '', 
        searchResults: [],
        showResults: false,
        showProTip: false,
    }

    componentDidMount = () => {
        const levels = ['Secondary One','Secondary Two','Upper Secondary']
        const subobjs = this.props.subobjs.map(subobj => {
            subobj.title = subobj.title.startsWith(levels[subobj.level-1]) ? subobj.title : `${levels[subobj.level-1]}: ${subobj.title}`
            return subobj
        })
        this.setState({subobjs})
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.search !== this.state.search && !this.state.search){
            this.setState({searchResults: []})
        }
    }

    updateState = (event) => {
        this.props.updateState(event.target.value, event.target.name, this.props.index)
    }

    //=======================================================================================

    // Prerequisites Search
    search = (event) => {
        const options = {
          shouldSort: true,
          tokenize: true,
          matchAllTokens: true,
          includeMatches: true,
          includeScore: true,
          threshold: 0.3,
          location: 0,
          distance: 500,
          maxPatternLength: 32,
          minMatchCharLength: 2,
          keys: [
            "title"
          ]
        };
        // console.log('searching')
        const fuse = new Fuse(this.state.subobjs, options); 
        const results = fuse.search(event.target.value);
        if (event.target.value.length > 1){
            this.setState({search:event.target.value, searchResults:results, showResults: true})
        } else {
            this.setState({search:event.target.value, searchResults:results, showResults: false})
        }
      }
    
    //=======================================================================================

    // Add handler for protip to user

    _handleKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.showProTip === false) {
          this.setState({showProTip: "Type \\\\ within \\begin and \\end to start on a new line."});
          } else {
            this.setState({showProTip: false});

          }
        }
    

    highlighter = function(resultItem){
        resultItem.matches.forEach((matchItem) => {
            const text = resultItem.item[matchItem.key];
            const result = []
            const matches = [].concat(matchItem.indices); // clear reference
            let pair = matches.shift()
          
            for (let i = 0; i < text.length; i++) {
                const char = text.charAt(i)
                if (pair && i == pair[0]) {
                    result.push('<strong>')
                }
                result.push(char)
                if (pair && i == pair[1]) {
                    result.push('</strong>')
                    pair = matches.shift()
                }
            }
            resultItem.highlight = result.join('');
        
            if(resultItem.children && resultItem.children.length > 0){
                resultItem.children.forEach((child) => {
                this.highlighter(child);
                });
            }
        });
    };

    selectPrereq = async (event) => {
        event.persist()
    try {
            await this.setState({showResults: false, search: ''})
            await this.props.updateState(event.target.id, "prereq_subobjectives", this.props.index)
            await kopi.post('/authoring/addprereq', {qnId: this.props.qn.id, objId: JSON.parse(event.target.id).objective, subobjId: JSON.parse(event.target.id)._id, first_part: this.props.qns[0].id })
        } catch(e) {
            try {
                await this.setState({showResults: false, search: ''})
                await this.props.updateState(event.target.id, "prereq_subobjectives", this.props.index)
                await kopi.post('/authoring/addprereq', {qnId: this.props.qn.id, objId: JSON.parse(event.target.id).objective, subobjId: JSON.parse(event.target.id)._id, first_part: this.props.qns[0].id })
            } catch(e) {
                window.location.reload(true)
            }
        }
    }
       

    deletePrereq = async (event) => {
        event.persist()
        const path = window.location.pathname.slice(1,7)
        if (path !== 'locked'){
            try {
                this.props.removePrereqFromState(this.props.index, event.target.id)
                await kopi.delete('/authoring/deleteprereq', {data: {qnId: this.props.qn.id, objId: JSON.parse(event.target.id).objective, subobjId: JSON.parse(event.target.id)._id, first_part: this.props.qns[0].id }})
            } catch(e) {
                try {
                    this.props.removePrereqFromState(this.props.index, event.target.id)
                    await kopi.delete('/authoring/deleteprereq', {data: {qnId: this.props.qn.id, objId: JSON.parse(event.target.id).objective, subobjId: JSON.parse(event.target.id)._id, first_part: this.props.qns[0].id }})
                } catch(e) {
                    window.location.reload(true)
                }
            }

        }
            
    }

    clearInput = () => {
        this.setState({search: '', showResults: false})
    }

    duplicatePrereqs = async () => {
        let prevIndex=0
        const index = this.props.indexArray.findIndex(i => i === this.props.index)
        const indexArray = this.props.indexArray.slice(0, index)
        prevIndex = indexArray.reverse().findIndex(curIndex => curIndex !== null)
        prevIndex = indexArray.length - 1 - prevIndex
        // console.log(prevIndex)

        for (let prereq of this.props.qns[prevIndex].prereq_subobjectives){
            prereq = JSON.stringify(prereq)
            await this.props.updateState(prereq, "prereq_subobjectives", this.props.index)
            await kopi.post('/authoring/addprereq', {qnId: this.props.qn.id, objId: JSON.parse(prereq).objective, subobjId: JSON.parse(prereq)._id, first_part: this.props.qns[0].id })
        }
    }

    renderSearchResult = () => {
           
        const cur_topic_subobj_array = []
        let prereq_subobj_array

        try {
            prereq_subobj_array = this.props.qn.prereq_subobjectives.map(subobj => subobj._id)
            this.props.selected_topics.forEach(topic => topic.objectives.forEach(obj => obj.subobjectives.forEach(subobj => cur_topic_subobj_array.push(subobj))))
                // console.log(this.props.qn)
        } catch (e){
            try {
                prereq_subobj_array = this.props.qn.prereq_subobjectives.map(subobj => subobj._id)
                this.props.selected_topics.forEach(topic => topic.objectives.forEach(obj => obj.subobjectives.forEach(subobj => cur_topic_subobj_array.push(subobj))))
 
            } catch(e){
                window.location.reload(true)
            }
        }
                return this.state.searchResults.map(subobj => {
                    if (subobj.score < 0.3 && !cur_topic_subobj_array.includes(subobj.item._id) && !prereq_subobj_array.includes(subobj.item._id)){
                        this.highlighter(subobj)
                    
                        const popover = <LOPopover subobj={subobj.item}/>
                        return (
                            <PopoverStickOnHover key={subobj.item._id} component={popover} placement="right" onMouseEnter={() => { }} title={<Latex latex={subobj.item.title} />} > 
                                <li className="dropdown-item subobj-title" 
                                name="prereq_subobjectives"
                                key={subobj.item._id} 
                                dangerouslySetInnerHTML={{__html: subobj.highlight}}
                                id={JSON.stringify(subobj.item)}
                                onClick={(e) => this.selectPrereq(e)}
                                >
                                
                                </li>
                            </PopoverStickOnHover>
                        )
                    } else {
                        return <span key={subobj.item.title}></span>
                    }
                })
    }

    renderSelected = () => {
        if (this.props.qn) {
            if (this.props.qn.prereq_subobjectives){

                if (this.props.qn.prereq_subobjectives.length > 0){
                    return this.props.qn.prereq_subobjectives.map(subobj => {
                        return (
                            <li key={subobj._id + this.props.qn.qnId}>
                                <span className="row">
                                <small className="prereq-text col-10"><Latex latex={subobj.title} /></small>
                                <button className="clear-button"><i className="material-icons prereq-delete col-2" id={JSON.stringify(subobj)} onClick={this.deletePrereq}>close</i></button>
                                <br/>
                                </span>
                            </li>
                        )
                    })
                } else {
                    return <span></span>
                }
            } else {
                return <span></span>
            }
        } else {
            return <span></span>
        }
    }

    renderDuplicateButton = () => {
        if (this.props.index){
            return <span className="col-6 text-right"><button className="btn btn-secondary btn-sm" onClick={this.duplicatePrereqs}>Duplicate from previous part</button></span>
        } else {
            return <span></span>
        }
    }

    renderPreReq = () => {
        let results
        const show = this.state.showResults ? ' show' : ''
        if (this.state.searchResults.length){
            results = (
                <div className="prereq-menu" aria-labelledby="dropdownMenuLink">
                    <ul className={`dropdown-menu${show}`} style={{listStyle: "none", paddingLeft: 0}}>
                        {this.renderSearchResult()}
                    </ul>
                </div>
            )
        } else {
            results = <span></span>
        }

        return (
            <div className="form-group dropdown-container">
                <span className="row justify-content-between">
                    <span className="col-4">Prerequisites</span>
                    {this.renderDuplicateButton()}
                </span>
                
                <br/>
                <ul className="container-fluid prereq-container">
                    {this.renderSelected()}
                </ul>
                <div className="input-group input-group-btn" style={{width: "100%"}}>
                    <input id="dropdownMenuLink" className="form-control dropdown-toggle prereq-search" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" value={this.state.search} type="text" placeholder="Search prerequisites" onChange={this.search} onClick={() => this.setState({showResults: true})} />
                    <button className="btn bg-transparent" onClick={this.clearInput} style={{marginLeft: "-40px", zIndex: 100}}>
                    <i className="fa fa-times"></i>
                    </button>
                    {results}
                </div>
            </div>
        )
    }

    //=======================================================================================

    // Solution Input Form
    renderMCQ = () => {
        return (
            <div id="alternative" className="form-group">
                Alternative MCQ Options (Wrong Answers)
                <input id="11" name="ans_alt1" type="text" className="form-control form-control-sm"  placeholder="Alternative Answer 1" onChange={this.updateState} onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)} value={this.props.qn.ans_alt1} />
                <input id="12" name="ans_alt2" type="text" className="form-control form-control-sm"  placeholder="Alternative Answer 2" onChange={this.updateState} onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)} value={this.props.qn.ans_alt2} />
                <input id="13" name="ans_alt3" type="text" className="form-control form-control-sm"  placeholder="Alternative Answer 3" onChange={this.updateState} onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)} value={this.props.qn.ans_alt3} />
            </div>
        )
    }

    renderNonSelfAssess = () => {
        return (
            <div>
                <div className="form-group">
                    Correct Answer
                    <input name="ans" type="text" className="form-control form-control-sm"  placeholder="Correct Answer" onChange={this.updateState} onBlur={(e)=>this.props.validateCorrectAns(e.target.value)} value={this.props.qn.ans} style={this.props.validAnswer ? null : {backgroundColor: "#FFF0F0"}} />
                    <small style={this.props.validAnswer ? {display: "none"} : {display: "block"}}>Error: Only integers and decimals are accepted as answers for Text Question Type</small>
                </div>
                <div className="form-group">
                    Prefix (optional)
                    <input name="prefix" type="text" className="form-control form-control-sm"  placeholder="Prefix (if any)" onChange={this.updateState} onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)} value={this.props.qn.prefix} />
                </div>
                <div className="form-group">
                    Suffix (optional)
                    <input name="suffix" type="text" className="form-control form-control-sm"  placeholder="Suffix (if any)" onChange={this.updateState} onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)} value={this.props.qn.suffix} />
                </div>
                <div className="form-group">
                    Units (optional)
                    <input name="units" type="text" className="form-control form-control-sm"  placeholder="Units (if any)" onChange={this.updateState} onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)} value={this.props.qn.units} />
                </div>
            </div>
        )
    }

    renderSolutionForm(){
        return (
            <div>
                <span className="row justify-content-end">
                        <span className="text-right"><button className="btn btn-secondary btn-sm" onClick={(e)=>this.props.updateState(`${this.props.qn.sol_txt}\\begin{aligned} \n\n \\end{aligned}`, "sol_txt", this.props.index)}>{"\\begin{aligned} ... \\end{aligned}"}</button></span> &nbsp;
                    <span className="text-right"><button className="btn btn-secondary btn-sm" onClick={(e)=>this.props.updateState(`${this.props.qn.sol_txt}\\begin{array}{lcl} \n\n \\end{array}`, "sol_txt", this.props.index)}>{"\\begin{array} ... \\end{array}"}</button></span> &nbsp; &nbsp;
                </span>
                <div className="form-group">
                    Solution
                    <TextareaAutosize 
                        rows={2} 
                        className="form-control" 
                        type="text" 
                        placeholder="enter detailed working/solution for the question" 
                        name="sol_txt" 
                        value={this.props.qn.sol_txt} 
                        onChange={this.updateState} 
                        onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)}
                        onKeyDown={this._handleKeyDown}    
                    />
                <small className="protip">{this.state.showProTip}</small>
                </div>
                <div className="form-group">
                    <span>
                        <input className="form-control-file" type='file' name='sol_img' onChange={(e)=>this.props.updateState(e.target.value, e.target.name, this.props.index, e.target.files)} />
                        {this.props.renderDeleteFileButton('sol_img',this.props.index)}
                    </span>
                    <small>Upload solution image (optional)</small>
                </div>
            </div>
        )
    }

    renderComment = () => {
        if (this.props.edit){
            return (
                <div style={{color: "red"}}>
                    <br/>
                    <strong>Solution Comments</strong>
                    <TextareaAutosize 
                        rows={3} 
                        className="form-control" 
                        type="text" 
                        placeholder="enter comments" 
                        name="solution_comments" 
                        onChange={this.updateState} 
                        onBlur={(e)=>this.props.autosave(e.target.value, e.target.name,this.props.qn.id)} 
                        value={this.props.qn.solution_comments}>
        
                    </TextareaAutosize>
                </div>
            )    
        } else {
            return <div></div>
        }
    }

    render(){
        if (this.props.qn_type === "text"){
            return <div><hr/><br/>
                <fieldset disabled={this.props.edit && window.location.pathname.slice(1,7)==='lol'}>
                    {this.renderSolutionForm()}
                    {this.renderNonSelfAssess()}<hr/>                
                    {this.renderPreReq()}
                </fieldset>
                <fieldset disabled={window.location.pathname.slice(1,5)==='edit'}>
                    {this.renderComment()}
                </fieldset>
            </div>
        } else if (this.props.qn_type === "mcq") {
            return (
                <div>
                    <hr/>
                    <fieldset disabled={this.props.edit && window.location.pathname.slice(1,7)==='lol'}>
                        {this.renderSolutionForm()}
                        {this.renderNonSelfAssess()}
                        {this.renderMCQ()}<hr/>
                        {this.renderPreReq()}
                    </fieldset>
                    <fieldset disabled={window.location.pathname.slice(1,5)==='edit'}>
                        {this.renderComment()}
                    </fieldset>
                </div>
            )
        } else if (this.props.qn_type === "self"){
            return <div><hr/><br/>
                <fieldset disabled={this.props.edit && window.location.pathname.slice(1,7)==='lol'}>
                    {this.renderSolutionForm()}<hr/>                
                    {this.renderPreReq()}
                </fieldset>
                <fieldset disabled={window.location.pathname.slice(1,5)==='edit'}>
                    {this.renderComment()}
                </fieldset>
            </div>
        }
        else {
            return <span></span>
        }
    }
}

export default Solutions