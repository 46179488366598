import React from 'react';
import kopi from '../../apis/kopi'
import { connect } from 'react-redux';
import { signUp } from '../../actions';
import { ReactComponent as Logo} from '../../white-joni-authors-logo.svg';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-140836063-2');
ReactGA.pageview(window.location.pathname + window.location.search);

class Onboarding extends React.Component {


    // 13 years old, email is valid, password is valid), T&C checkbox, upload T&C for Joni Authors


    state = {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        email: "",
        password: "",
        error: {name: "", age: "", email: "", password: "", toc: ""},
        toc: false,
        submitted: false
    }

  componentWillMount = () => {
    window.scrollTo(0, 0);
   }

   registerUser = async () => {
       if (!this.state.submitted){

        await this.setState({ 
            submitted: true,
            error : {name: "", age: "", email: "", password: "", toc: ""}
        });
        let valid = await this.validateInput();
        if (valid){
                this.props.signUp({
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    date_of_birth: this.state.date_of_birth,
                    email: this.state.email.toLowerCase(),
                    password: this.state.password,
                });
                setTimeout(() => {
                    this.setState({
                        error: { age: this.state.error.age, email: "Email already exists", password: this.state.error.password }
                    });
                }, 3000);
            }
        }       
    }

    validateInput = async () => {
        let current_year = new Date().getFullYear();
        let dob = new Date(this.state.date_of_birth);

        let name = this.state.name;
        let age = this.state.age;
        let email = this.state.email;
        let password = this.state.password;
        let toc = this.state.toc;
        
        if (this.state.first_name === "" || this.state.last_name === "") {
            name = "❌ First name & last name cannot be blank"
        } else {
            name = ""
        }

        if (current_year - dob.getFullYear() < 13 || this.state.date_of_birth === "") {
            age = "❌ You need to be at least 13 years old"
        } else {
            age = ""
        }

        if (this.state.password.length < 8) {
            password = "❌ Password must be at least 8 characters"

        } else {
            password = ""
        }


        if (!this.state.toc) {
            toc = "❌ Please accept the T&C and Privacy Policy"

        } else {
            toc = ""
        }

        let re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;

        if (!re.test(this.state.email)){
            email = "❌ Invalid Email";
        } else {
            email = ""
        }

        await this.setState({error: {name, age, password, email, toc}})

        if (this.state.error.name !== "" || this.state.error.age !== "" || this.state.error.password !== "" || this.state.error.email !== "" || !this.state.toc){
            return false
        } else {
            return true
        }
    }

    renderErrorMessages = () => {
        let name = <></>
        let age = <></>
        let password = <></>
        let email = <></>
        let toc = <></>

        if (this.state.error.age !== "") {
            age = <div>
                {this.state.error.age}
            </div>
        }
        
        if (this.state.error.password !== "") {
            password = <div>
                {this.state.error.password}
            </div>
        }

        if (this.state.error.email !== "") {
            email = <div>
                {this.state.error.email}
            </div>
        }

        if (this.state.error.name !== "") {
            name = <div>
                {this.state.error.name}
            </div>
        }

        if (this.state.error.toc !== "") {
            toc = <div>
                {this.state.error.toc}
            </div>
        }

        return <div className='error-messages'>
            {name}
            {age}
            {password}
            {email}
            {toc}
        </div>
    }

    render() {
        return (
            <div className ="" style={{ background: "#212121", minHeight: "100vh" }}>
                <div className="container centralise" align="center">
                    <Link to='/'><Logo style={{width: "75%", paddingBottom:"2rem"}}/></Link>
                    <h1 className="lead">Create an account</h1>
                    <small>
                        {this.renderErrorMessages()}
                    </small>
                    <hr />
                    <input onChange={(e) => { this.setState({ first_name: e.target.value }) }} value={this.state.first_name} type="text" className="no-border" placeholder="First Name" /> <br />
                    <input onChange={(e) => { this.setState({ last_name: e.target.value }) }} value={this.state.last_name} type="text" className="no-border" placeholder="Last Name" /> <br />
                    {/* validate that at least 13 years old */}
                    <input onChange={(e) => { this.setState({ date_of_birth: e.target.value }) }} value={this.state.date_of_birth} type="date" className="no-border" placeholder="Date of Birth" /> <br />
                    <input onChange={(e) => { this.setState({ email: e.target.value }) }} value={this.state.email} type="email" className="no-border" placeholder="Email" /> <br />
                    <input onChange={(e) => { this.setState({ password: e.target.value }) }} value={this.state.password} type="password" className="no-border" placeholder="Password" /> <br /> <br />
                    <div class="form-check" style={{color: "white"}}>
                        <input
                        id="toc"
                        class="form-check-input" 
                        name="isGoing"
                        type="checkbox"
                        checked={this.state.toc}
                        onChange={(e)=>{
                            if (this.state.toc){
                                this.setState({toc: false})
                            } else {
                                this.setState({toc: true})
                            }
                        }} />
                        <label class="form-check-label" for="toc">
                            <small>I have read and agree to the <a target="_blank" href="/legal">Terms & Conditions</a> and <a target="_blank" href="/privacy">Privacy Policy</a></small>
                        </label>
                    </div>
                    <br/>
                    <button onClick={this.registerUser} className="btn btn-dark btn-block"> Register </button>
                    <p ><Link className='txt-white txt-register' to="/login">I already have an account</Link></p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { signUp })(Onboarding);
