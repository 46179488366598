import React from 'react'
import kopi from '../../apis/kopi';
import { connect } from 'react-redux';

class Confirmation extends React.Component {
    state = { assigned_topics: [], message: "", error: "" }


    assignQuestions = async () => {
        if (this.props.email && (this.props.selected_topics.length > 0 || this.props.qnId)) {
            const topics = this.props.selected_topics.map(t => { return { topic_id: t._id, quantity: t.quantity } })
            const response = await kopi.post('/admin/assign', { email: this.props.email, topics, qnId: this.props.qnId })
            this.setState({ message: response.data.message, error: response.data.error })
        } else {
            window.alert("Oi")
        }
    }

    renderMessage = () => {
        if (this.state.message) return <div className="alert alert-success">{this.state.message}</div>
        return <></>
    }

    renderError = () => {
        if (this.state.error) return <div className="alert alert-danger">{this.state.error}</div>
        return <></>
    }

    render = () => {
        return <div className="container">
            {this.renderError()}
            {this.renderMessage()}

            <div className="card">
                <div className="card-body">
                    <strong>
                        {this.props.email}
                    </strong> <br />
                    <hr />
                    {this.props.selected_topics.map(t => <div className="row">  
                        <div className="col-8">{t.title}</div>
                        <div className="col-4"><input onChange={e => this.props.updateAssignedTopics(Number(e.target.value), t._id)} className="form-control" type="number" /> <br />
                        </div>
                    </div>)}
                    <hr />
                    <button onClick={this.assignQuestions} className={`btn ${this.props.email && (this.props.selected_topics.length > 0 || this.props.qnId) ? "btn-dark" : "btn-light"} btn-block`}>
                        Assign
                    </button>
                </div>
            </div>
        </div>
    }
}

// this.setState((state) => {
//     return {quantity: state.quantity + 1};
//   });

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        privilege: Number(state.auth.privilege),
    };
};

export default connect(mapStateToProps, {})(Confirmation);