// localStorage has key "mode": "Draft", "Review", "Published"

import React from 'react';

import Draft from './Draft';
import Review from './Review';
import Published from './Published';
import EditorReview from './EditorReview';
import EditorPublished from './EditorPublished';
import Loading from './Loading'
import kopi from '../../apis/kopi';
import history from '../../history';
import { ReactComponent as Logo} from '../../white-joni-authors-logo.svg';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux';
import { signOut } from '../../actions';

class Wallet extends React.Component{

    state = {earnings: "...", withdrawals: "...", submitted: false, error: false, probono: false, karma_count: 0};

    componentWillMount = async () => {
        if (!this.props.isSignedIn){
            history.push('/');
          }
        let response = await kopi.get("/moolah/wallet",{params: {userId: this.props.currentUser}});
        let response2 = await kopi.get("/user/probonostatus",{params: {userId: this.props.currentUser}});

        if (response.data[1]){
            this.setState({karma_count: response2.data.karma_count, probono: response2.data.probono, earnings: response.data[0].earnings.toFixed(2), withdrawals: response.data[0].withdrawals.toFixed(2), submitted: true})
        } else {
            this.setState({karma_count: response2.data.karma_count, probono: response2.data.probono, earnings: response.data[0].earnings.toFixed(2), withdrawals: response.data[0].withdrawals.toFixed(2)})
        }
    }

    submitForApproval = () => {
        if (this.state.earnings-this.state.withdrawals >= 25){
            kopi.post("/moolah/withdrawal",{
                userId: this.props.currentUser,
                creditAmount: (this.state.earnings-this.state.withdrawals)
            });
            this.setState({submitted: true})
        } else {
            this.setState({error: true})
        }
    }

    renderButton = () => {
        if (this.state.submitted) {
            // return <div><button onClick={this.submitForApproval} className="btn btn-dark btn-lg">Initiate Withdrawal</button></div>
            return <div><button className="btn btn-dark btn-lg">Withdrawal Pending</button></div>
        } else {
            return <div><button onClick={this.submitForApproval} className="btn btn-dark btn-lg">Initiate Withdrawal</button></div>
        }
    }

    renderError = () => {
        if (this.state.error) {
            return <div><small>Sorry, you need to have at least $25 credits in your wallet to initiate a withdrawal.</small></div>
        } else {
            return <></>
        }
    }

    renderEarnings = () => {
        if (this.state.withdrawals === "...") {
            return "counting..."
        } else {
            return `Wallet: $${(this.state.earnings - this.state.withdrawals).toFixed(2)}`
        }
    }

    renderProbonoToggle = () => {
        if (this.state.probono){
            return <div class="custom-control custom-switch">
                <input checked={this.state.probono} onChange={(e)=>{
                    this.setState({probono: e.target.checked})
                    this.toggleProbono();
                }} type="checkbox" class="custom-control-input" id="customSwitch1" />
                <label class="custom-control-label" for="customSwitch1"><b>Supporting students from less privileged backgrounds</b></label><br/>
                {/* <small onClick={()=>this.toggleProbonoTo(false)}>I want to support students from less privileged backgrounds</small> */}
            </div>
        } else {
            return <div class="custom-control custom-switch">
                <input checked={this.state.probono} onChange={(e)=>{
                    this.setState({probono: e.target.checked})
                    this.toggleProbono();
                }} type="checkbox" class="custom-control-input" id="customSwitch1" />
                <label class="custom-control-label" for="customSwitch1"><b>Not supporting students from less privileged background</b></label> <br/>
                {/* <small onClick={()=>this.toggleProbonoTo(true)}>I want all students to pay for my questions regardless of their background</small>             */}
            </div>
        }
    }

    toggleProbono = () => {
        kopi.post("/user/toggleprobono",{userId: this.props.currentUser});
    }

    renderChangemakerStatus = () => {
        if (this.state.probono){
            return <>
            <object className="img-fluid img-mid" data="/joni-tuition.svg" type="image/svg+xml" style={{display:"inline", maxHeight: "25vh"}} />
            <p className="lead black-txt">Joni is proud!
            <h1>Karma Points: {this.state.karma_count}</h1>
            </p>
            </>
        } else {
            return <>
            <object className="img-fluid img-mid greyscale" data="/joni-tuition.svg" type="image/svg+xml" style={{display:"inline", maxHeight: "25vh"}} />
            <p className="lead black-txt">Joni hopes you can help!</p>
            </>
        }
    }

    render(){
        return <div className="container">
        <div className="row">
                <div className="col-6">
                    <div className="row">
                    <p className="txt-black">
                        <h3>Joni.Authors Pricing</h3><hr/>
                        <small>
                            <ul>
                                <li>Base rate of S$0.02 per student attempt of a question part.</li>
                                <li>If the question is rated as Highly Challenging/Interesting by the editor, it generates 20% more revenue.</li>
                                <li>If the question is rated to have Real World Context by the editor, it generates 20% more revenue.</li>
                                <li>If the question comes with Diagrams/Illustrations (verified by the editor), it generates 20% more revenue.</li>
                            </ul>
                            <p>Eg. A 3-part question that is challenging, has real world context and comes with diagrams would generate $(0.02x1.6=<b>0.032</b>) per attempt of each of its parts. 
                                In other words, a full attempt of the 3-part question by one student would yield $(0.032*3=<b>0.096</b>).
                            </p>
                        </small>
                    </p>
                    </div>
                </div>
                <div className="col-6">
                    <br/>
                    <img src="https://image.flaticon.com/icons/svg/138/138292.svg" className="img-fluid" style={{maxHeight: "10vh"}} />
                    <br/>
                    <div align="center">
                        <p><h1>{this.renderEarnings()}</h1>
                        <small>Earnings: ${this.state.earnings}</small> | <small> Withdrawals: ${this.state.withdrawals}</small></p>
                        {this.renderButton()}
                        <span className="error-messages">{this.renderError()}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                    <p className="txt-black">
                    <h3>Joni Impact</h3><hr/>
                    <p> Joni.AI is a social enterprise. While many of our users are paying customers, we also offer free accounts to students from less privileged backgrounds.
                    Your questions will help many of them.
                    For more details, see <a target="blank" href="https://www.joni.ai/ourimpact"> Our Impact.</a></p>
                    <p>We invite you to support our cause by allowing free access to your questions to this group of students.</p>
                    
                    {this.renderProbonoToggle()}
                    <small>Don't worry, you can change this setting anytime!</small>
                    </p>

                    <small>By enabling this setting, you are allowing free access to your questions by our student beneficiaries. You acknowledge that you have read and agree with our <Link to="/legal" target='blank'>Terms and Conditions</Link> regarding this clause.</small>

                    <br/><br/><br/><br/>
                    </div>
                    <div className="col-6">
                        <div className="container" align="center">
                            <br/>
                            {this.renderChangemakerStatus()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        privilege: Number(state.auth.privilege),
    };
};

export default connect(mapStateToProps, {signOut})(Wallet);
