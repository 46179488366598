import React from 'react'

import kopi from '../../apis/kopi'
import Latex from '../form/Latex'

class ViewObj extends React.Component{

    state = {subobjectives:[], mode: null}

    changePage = (page, mode) => {
        this.props.changePage(page)
        this.props.setMode(mode)
    }

    deleteObjective = async () => {
        if (window.confirm(`Are you sure you want to delete "${this.props.objective.title}" and all of its subobjectives?`)){
            // delete objective from state
            // delete objective, all its subobjectives and it's ref in topic from db
            this.props.deleteObjective(this.props.objective._id)
            this.props.changePage(0)
            await kopi.delete('/admin/deleteobjective', {data: {objId: this.props.objective._id}})
        }
    }

    deleteSubobj = async (event) => {
        const subobj = JSON.parse(event.target.id)
        if (window.confirm(`Are you sure you want to delete "${subobj.title}"?`)){
            // delete subobjective from state
            this.props.deleteSubobj(subobj._id)
            // delete subobjective and its ref in objective's parent_objective
            await kopi.delete('/admin/deletesubobjective',{data: {subobjId: subobj._id}})
        }
    }
    
    renderSubobjectives = () => {
        return this.props.objective.subobjectives.map(subobj => {
            return (
                <div className="row">
                    <li className="col-9">
                    <Latex latex= {subobj.title} />
                    </li>
                    <a className="col-1" role="button" tabIndex="0">Edit</a>
                    <a className="col-2" role="button" tabIndex="0" id={JSON.stringify(subobj)} onClick={this.deleteSubobj}>Delete</a>
                </div>
                
            )
        })
    }

    render(){
        return (
            <div className="container">
                <br/>
                <h1>Subobjectives of</h1>
                <h4><Latex latex={this.props.objective.title} /></h4>
                <hr/>
                <button className="btn btn-dark" onClick={()=>this.props.changePage(0)}>Back</button> &nbsp; &nbsp;
                <button className="btn btn-info" onClick={()=>this.changePage(4,'Add')}>Add Subobjectives</button> &nbsp; &nbsp;
                <button className="btn btn-secondary" onClick={()=>this.changePage(5,'Edit')}>Edit Objective</button> &nbsp; &nbsp;
                <button className="btn btn-danger" onClick={this.deleteObjective}>Delete Objective</button>
                <br/><br/>
                <ul>
                    {this.renderSubobjectives()}
                </ul>
            </div>
        )
    }
}

export default ViewObj