import React from 'react';
import kopi from '../../apis/kopi'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class NeedVerification extends React.Component{

  componentWillMount = () => {
    window.scrollTo(0, 0);
   }
    render() {
        return (
            <div class="jumbotron jumbotron-fluid">
            <div class="container">
                <h1 class="display-4">Verify your email</h1>
                <p class="black-txt">Click on the link in your email to verify your Joni Author status.</p>
            </div>
            </div>
        );
      }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {})(NeedVerification);
