import React from 'react'
import history from '../history'
import {Link} from 'react-router-dom';


class Home extends React.Component {
    state = {loaded: false}

    render(){
        if (!this.state.loaded){
            return <div>
            <img className="img-small authors" src="/joniauthors.png" onLoad={()=>this.setState({loaded: true})} style={{display:"none"}} />
            </div>
        } else {
            return <div className="splash">
                <div className="new-container open-sans-txt">
                    <img className="img-homepage img-small fade-in one" src="/joniauthors.png" onLoad={()=>this.setState({loaded: true})} />
                    <div className="new-row">
                        <div className="comingsoon">
                            <h1 className="fade-in two homepage-title">Welcome to Joni Authors</h1>
                            <p className="fade-in two"> Earn while shaping the learning of
                                students around the world with
                                handcrafted questions. </p>
                                <br/>
                            <button className="btn btn-white-round btn-lg homepage-btn fade-in three">
                                <a href="https://mentor.joni.ai/signup"><span>Start Now</span></a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
    }    
}

export default Home