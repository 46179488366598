import {SIGN_IN, SIGN_OUT} from '../actions/types'; 

const INITIAL_STATE = {
    isSignedIn: null,
    currentUser: localStorage.getItem('currentUser'),
    name: localStorage.getItem('name'),
    privilege: localStorage.getItem('privilege'),
    isAngel: localStorage.getItem('isAngel')
};

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case SIGN_IN:
            localStorage.removeItem('currentUser');
            localStorage.removeItem('mode');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            localStorage.removeItem('privilege');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            localStorage.removeItem('name');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            localStorage.removeItem('isAngel');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            let isAngel = false;
            if (action.payload){
                if (action.payload.author){
                    localStorage.setItem('isAngel', action.payload.author.angel);
                    if(action.payload.author.angel){
                        isAngel = true;
                    }
                }
            }
            localStorage.setItem('currentUser', action.payload._id);
            localStorage.setItem('name', action.payload.first_name);
            localStorage.setItem('privilege', action.payload.privilege);
            return {...state,  isAngel: isAngel, isSignedIn: true, currentUser: action.payload._id, name: action.payload.first_name, privilege: action.payload.privilege};
        case SIGN_OUT:
            localStorage.removeItem('isAngel');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            localStorage.removeItem('currentUser');
            localStorage.removeItem('mode');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            localStorage.removeItem('privilege');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            localStorage.removeItem('name');  // bcos editor and author have diff dashboard modes - prevents non-existent mode from trying to be rendered
            return  {...state, isSignedIn: false, currentUser: null, name: null, privilege: null, isAngel: false};
        default: 
            if (!state.currentUser) {
                return state;
            } else{
                return {...state, isSignedIn: true};
            }
    }
};