import React from 'react';
import { connect } from 'react-redux';
import { signUp } from '../../actions';
import kopi from '../../apis/kopi';
import Dock from 'react-dock'

class Contact extends React.Component {

    // state = {isVisible: false, feedback: "", submitted: false};

    // onSubmit = () => {
    //     if (!this.state.submitted) {
    //         if (this.state.feedback.length > 2001) {
    //             window.alert(`Character limit exceeded by ${this.state.feedback.length-2001}`);
    //         } else if (this.state.feedback.length === 0) {
              
    //         } else {
    //         this.setState({submitted: true});
    //         let source;
    //         source = this.props.sourceRef ? this.props.sourceRef : null
    //         kopi.post('/admin/sendmessage',{
    //                 message: this.state.feedback,
    //                 userId: this.props.currentUser,
    //                 source: source
    //             })
    //         }
    //     }
    // }
    
    // renderContent = () => {
    //     if (this.state.submitted) {
    //         return this.renderContentAfterSend();
    //     } else {
    //         return this.renderContentBeforeSend();
    //     }
    // }

    // renderContentAfterSend = () => {
    //     return <> <div align="center" className="container">
    //     <br/><br/>
    //     <p><strong>Sent!</strong></p>
    //     <hr/>      
    //     <br/>
    //     <img src="https://www.joni.ai/landing/joni-waves.png" className="img-fluid" />
    //     </div>
    //     <br/>
    //     <div className="container" align="center">
    //         <p className="black-txt">Thanks for leaving a message! Our team will get back to you via email soon.</p>
    //     </div>
    //     <div className="chat-absolute-bottom">
    //     <button className="btn btn-submit-chat btn-block btn-dark" onClick={() => this.setState({ isVisible: !this.state.isVisible })}>Send Message</button>
    //     </div>
    //     <div className="absolute-bottom">
    //     <button className="btn btn-close-chat btn-block btn-danger" onClick={() => this.setState({ isVisible: !this.state.isVisible })}><small>Close</small></button>
    //     </div>
    //     </>
    // }

    // renderContentBeforeSend = () =>{
    // return <> <div align="center" className="container">
    //         <br/><br/>
    //         <p><strong>Drop Us A Message</strong></p>
    //         <hr/>      
    //         <textarea
    //             className="textarea-grey"
    //             style = {{height: "60vh", width: "100%"}}
    //             value={this.state.feedback}
    //             onChange={(e)=>{this.setState({feedback: e.target.value})}}
    //         />

    //         </div>
    //         <div className="chat-absolute-bottom">
    //         <button className="btn btn-submit-chat btn-block btn-dark" onClick={() => this.onSubmit()}>Send Message</button>
    //         </div>
    //         <div className="absolute-bottom">
    //         <button className="btn btn-close-chat btn-block btn-danger" onClick={() => this.setState({ isVisible: !this.state.isVisible })}><small>Close</small></button>
    //         </div>
    //         </>
    // }

    render(){
        return <></>
    // return <div className="container-fluid">
    //     <span onClick={()=>{this.setState({isVisible: true})}} href="#" class="float">
    //     <i class="fa fa-comments my-float"></i>
    //     </span>
    //     <Dock position='right' size="0.3" isVisible={this.state.isVisible}>
    //     {/* you can pass a function as a child here */}
        
    //     {this.renderContent()}
    //     </Dock>
        // </div>
}
}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, { signUp })(Contact);