import React from 'react'; 
import '../Author.css';

const JoniHeader = props => {

    const joni_img = "https://i.imgur.com/GQioire.png"

    return (
        <div className="row">
            <div align="center" className="col-4 col-md-2 no-padding">
                <img onClick={props.onJoniClick} src={joni_img} className="img-fluid" />
            </div>
            <div align="left" className="col-8 col-md-10 no-padding">
                <div className="joni-says speech alert alert-info">
                    <p>Here is your question preview!</p>
                </div>
            </div>
        </div>
    )
}

export default JoniHeader