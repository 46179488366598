import React from 'react'
import Solution from './Solution'
import '../Author.css';

class MultipartSolution extends React.Component{
    state = { selected_qn: this.props.multiparts[this.props.qn_index], selected_index: this.props.qn_index };

    selectQuestion = (qn, index) => {
        this.setState({ selected_qn: qn, selected_index: index })
        this.props.selectQuestion(index);
    }

    componentDidUpdate = () => {
        if (this.state.selected_qn && this.props.multiparts[this.props.qn_index]){
            if (this.state.selected_qn.sol_txt !== this.props.multiparts[this.props.qn_index].sol_txt || 
                this.state.selected_qn.sol_img !== this.props.multiparts[this.props.qn_index].sol_img ||
                this.state.selected_qn.ans !== this.props.multiparts[this.props.qn_index].ans){
                    this.setState({selected_qn: this.props.multiparts[this.props.qn_index], selected_index: this.props.qn_index})
            }
        }
    }

    render() {
        function nextChar(c, index) {
            return String.fromCharCode(c.charCodeAt(0) + index);
        }
        let indexArray = []
        let curIndex = -1
        let navigation = this.props.multiparts.map((qn, index) => {  // generate multipart navigation bar
            if (qn){
                curIndex++
                indexArray.push(curIndex)
                if (indexArray[index] === this.state.selected_index) {
                    if (this.state.selected_qn !== this.props.multiparts[index]){
                        this.setState({selected_qn: this.props.multiparts[index]})
                    }
                    return <li className="nav-item" key={qn._id} onClick={() => this.selectQuestion(qn, indexArray[index])}>
                        <small className="nav-link active">({nextChar("a", indexArray[index])})</small>
                    </li>
                } else {
                    return <li className="nav-item" key={qn._id} onClick={() => this.selectQuestion(qn, indexArray[index])}>
                        <small className="nav-link">({nextChar("a", indexArray[index])})</small>
                    </li>
                }
            }else{
                indexArray.push(null)
            }
        })

        return (
            <div>
                <ul className="nav nav-pills nav-fill">
                    {navigation}
                </ul>
                <br/>
                <Solution isLoading={this.props.isLoading} multi={true} qn={this.state.selected_qn} />
            </div>
        )
    }
}

export default MultipartSolution