import React from 'react';

import kopi from '../../apis/kopi';
import AddTopic from './AddEditTopic';
import AddObj from './AddObj';
import ViewObj from './ViewObj';
import AddSubobj from './AddSubobj';
import Latex from '../form/Latex';

class Admin extends React.Component{

    // page 0 => displays topics and objectives
    // page 1 => displays single objective and all subobjectives
    // page 2 => add/edit topic page
    // page 3 => add objective page
    // page 4 => add subobjective page
    // page 5 => edit objective page
    // page 6 => edit subobjective page
    state = {page: 0, 
        topics:[], 
        levels:['Secondary One','Secondary Two','Upper Secondary'],
        showLevel: false, 
        selected_level: 0, 
        selected_topic: null, // topic id
        selected_objective: null, // entire objective object
        mode: null }

    componentDidMount = async () => {
        const response = await kopi.get('/admin/admintopics')
        this.setState({topics: response.data.reverse()}) 
    }

    updateTopics = (topics) => {
        this.setState({topics})
    }

    updateObjective = (objective) => {
        this.setState({selected_objective: objective})
    }

    deleteTopic = async (event) => {
        const {_id, title} = JSON.parse(event.target.id)
        if (window.confirm(`Are you sure you want to delete "${title}" and all its learning objectives?`)){
            const topics = []
            this.state.topics.forEach(topic => topic._id === _id ? null : topics.push(topic))
            this.setState({topics})
            await kopi.delete('/admin/deletetopic',{data: {topicId: _id}})
        }
    }

    deleteObjective = (objId) => {
        const topics = [...this.state.topics]
        const topicIndex = topics.findIndex(topic => this.state.selected_topic === topic._id)
        const objectives = []
        topics[topicIndex].objectives.forEach(obj => objId === obj._id ? null : objectives.push(obj))
        topics[topicIndex].objectives = objectives
        this.setState({topics, selected_objective: null})
    }

    deleteSubobj = (subobjId) => {
        const topics = [...this.state.topics]
        const topicIndex = topics.findIndex(topic => this.state.selected_topic === topic._id)
        const objectiveIndex = topics[topicIndex].objectives.findIndex(objective => this.state.selected_objective._id === objective._id)

        const subobjectives = []
        const selected_objective = topics[topicIndex]
        
        this.state.selected_objective.subobjectives.forEach(subobj => subobj._id === subobjId ? null : subobjectives.push(subobj))
        topics[topicIndex][objectiveIndex] = subobjectives
        selected_objective.subobjectives = subobjectives

        this.setState({topics, selected_objective})
    }

    changePage = (page) => {
        this.setState({page})
    }

    setMode = (mode) => {
        this.setState({mode})
    }

    selectLevel = (event) => {
        this.setState({selected_level: Number(event.target.value), showLevel: false})
    }

    renderLevelItem = () => {
        return this.state.levels.map((level, index) => {
            return <button key={level} className="dropdown-item" type="button" name="level" value={index+1} onClick={this.selectLevel}>{level}</button>
        })
    }

    renderObjectives = (objectives, topicId) => {
        return objectives.map(obj => {
            return (
                <li key={obj._id}>
                    <a role="button" tabIndex="0" id={JSON.stringify(obj)} onClick={(event)=>this.setState({page: 1, selected_objective: obj, selected_topic: topicId})}>
                        <Latex latex={obj.title} />
                    </a>
                </li>
            )
        })
    }

    renderTopics = () => {
        const topics = this.state.topics.sort(function(a,b){
            if (a.level < b.level){
                return -1
            } else if (a.level === b.level){
                return a.index - b.index
            } else {
                return 1
            }
        })
        return topics.map(topic => {
            if (topic.level === this.state.selected_level || this.state.selected_level === 0){  // 0 means all levels
                return (
                    <div key={topic._id}>
                        <div className="row">
                            <span className="col-5">
                                {topic.title}
                            </span>
                            <i className="material-icons col-1" id={JSON.stringify(topic)} onClick={this.deleteTopic}>close</i>
                            <span className="col-6">
                                <button className="btn btn-info btn-sm" value={topic._id} onClick={(event) => this.setState({page: 3, selected_topic: event.target.value, mode: 'Add'})}>Add Objectives</button> &nbsp; &nbsp;
                                <button className="btn btn-secondary btn-sm" value={topic._id} onClick={(event) => this.setState({page: 2, selected_topic: event.target.value, mode: 'Edit'})}>Edit topic</button> &nbsp; &nbsp;
                            </span>
                        </div>
                        <ul>
                            {this.renderObjectives(topic.objectives, topic._id)}
                        </ul>
                        <hr/>
                    </div>
                )
            }   
        })
    }

    renderPage0 = () => {
        const showLevel = this.state.showLevel ? ' show' : ''
        return (
            <div className="container">
                <h1>Topics</h1>
                <hr/>
                <div className="row">
                    <div class="dropdown">
                        <span>Filter by level: &nbsp; &nbsp;
                            <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>this.setState({showLevel: !this.state.showLevel})}>
                                {this.state.selected_level ? this.state.levels[this.state.selected_level-1] : 'Show All Levels'}
                            </button>
                        </span>
                        <div class={`dropdown-menu${showLevel}`} aria-labelledby="dropdownMenuButton">
                            {this.renderLevelItem()} 
                            <button key={0} className="dropdown-item" type="button" name="level" value={0} onClick={this.selectLevel}>Show All Levels</button>
                        </div>
                    </div> &nbsp; &nbsp;
                    <button className="btn btn-info" onClick={() => this.setState({page: 2, mode: 'Add'})}>Add Topic</button>
                </div>
                <br/><br/>
                {this.renderTopics()}
            </div>
        )
    }

    render(){
        // console.log(this.state.topics)
        if (this.state.page === 0){
            return <div>{this.renderPage0()}</div>
        } 
        else if (this.state.page === 1){
            return <ViewObj setMode={this.setMode} changePage={this.changePage} objective={this.state.selected_objective} deleteObjective={this.deleteObjective} deleteSubobj={this.deleteSubobj} />
        } 
        else if (this.state.page === 2){
            return <AddTopic changePage={this.changePage} updateTopics={this.updateTopics} mode={this.state.mode} topicId={this.state.selected_topic} />
        } 
        else if (this.state.page === 3){
            return <AddObj changePage={this.changePage} updateTopics={this.updateTopics} selected_topic={this.state.selected_topic} mode={this.state.mode} />         
        } 
        else if (this.state.page === 4){
            return <AddSubobj changePage={this.changePage} updateTopics={this.updateTopics} updateObjective={this.updateObjective} objective={this.state.selected_objective} mode={this.state.mode} />
        }
    }
}

export default Admin