import React from 'react'

import ObjCard from './ObjCard'
import kopi from '../../apis/kopi'

class AddObj extends React.Component{

    state = {objectives: [{title: '', description: ''}]}

    onSaveClick = async () => {
        const response = await kopi.post('/admin/addobjectives', {objectives: this.state.objectives, topicId: this.props.selected_topic})
        this.props.updateTopics(response.data)
        this.props.changePage(0)
        
    }

    updateState = (value, name, index) => {
        const objectives = [...this.state.objectives]
        const objective = objectives[index]
        objective[name] = value
        objectives[index] = objective
        this.setState({objectives})
    }

    addNewObj = () => {
        const newObj = {title: '', description: ''}
        const objectives = [...this.state.objectives]
        objectives.push(newObj)
        this.setState({objectives})
    }

    renderObjCards = () => {
        return this.state.objectives.map((objective, index) => {
            return (
                <div>
                    <ObjCard key={index} addNewObj={this.addNewObj} updateState={this.updateState} index={index} />
                    <br/>
                </div>
            )
        })
    }

    render(){
        // console.log(this.state)
        return (
            <div className="container">
                <h1>{`${this.props.mode} Objectives`}</h1>
                <hr/>
                {this.renderObjCards()}
                <div className="row" style={{marginLeft: "0px", marginRight: "0px"}}>
                    <button className="btn btn-info btn-lg" onClick={this.onSaveClick}>Save</button> &nbsp; &nbsp;
                    <button className="btn btn-secondary btn-lg" onClick={()=> this.props.changePage(0)}>Cancel</button>
                </div>
            </div>
        )
    }
}

export default AddObj;