import React from 'react'
import { Sticky, StickyContainer } from 'react-sticky'
import TextareaAutosize from 'react-autosize-textarea';


import Smartphone from '../preview/Smartphone'
import QuestionCard from '../Editor/QuestionCard'
import Dropdown from '../Editor/Dropdown'
import EditorNavbar from './EditorNavbar'
import AuthorNavbar from '../Editor/AuthorNavbar'
import ErrorPage from '../Editor/Error'
import history from '../../history'

import { connect } from 'react-redux';
import { signOut } from '../../actions';

import kopi from '../../apis/kopi';
import '../Author.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-140836063-2');
ReactGA.pageview(window.location.pathname + window.location.search);


class EditQuestion extends React.Component {
  _isMounted = false;
  state = {
    subject_key: '',
    flags: [],
    view_flags: false,
    img_loading: false,
    src: "", qns: [{
      id: '',
      original_question: '',
      header_txt: '',
      header_img: '',
      qn_txt: '',
      qn_img: '',
      sol_txt: '',
      sol_img: '',
      prefix: '',
      suffix: '',
      units: '',
      ans: '',
      ans_alt1: '',
      ans_alt2: '',
      ans_alt3: '',
      qn_type: '',
      has_sol: false,
      subobjectives: [],
      objectives: [],
      prereq_subobjectives: [],
      difficulty: null,
      question_comments: null, solution_comments: null
    }],
    level: 0,
    topics: [],
    tags: [],
    subobjs: [],
    status: '',
    selected_topics: [],
    selected_tags: [],
    selected_qn_index: 0,  // updated in parent component (which qn card author is currently editing)
    view: 'question',  // 'question' or 'solution'
    levelIsOpen: false,
    topicIsOpen: false,
    tagIsOpen: false,
    modalOpen: false,
    ready: [{ level: false, topic: false, subobjectives: false, question: false, solution: false, ans: false, ans_alt: false, difficulty: false, qn_type: false, prereq_subobjectives: false }],
    loaded: false,
    saved: null,
    error: false,
    savedNewPart: true,

  }

  //=======================================================================================

  componentDidMount = async () => {
    this._isMounted = true;
    if (this.props.match.params.id) {
      const ready = [...this.state.ready]
      this.updateState(this.props.match.params.id, 'id', 0, null)
      //// console.log(this.props.match.params.id)
      const response4 = await kopi.get('/authoring/getquestion', { params: { id: this.props.match.params.id, path: window.location.pathname, privilege: this.props.privilege, callFrom: 'edit' } });
      // // console.log(response4.data)
      if (response4.data === 'error') {
        this.setState({ error: true, loaded: true })
      } else {
        ready[0].level = response4.data[0].level ? true : false
        ready[0].topic = response4.data[0].topics.length !== 0

        let flags = [];
        if (this.props.privilege === 1) {
          const response5 = await kopi.get('/authoring/getflags', { params: { id: this.props.match.params.id, path: window.location.pathname, privilege: this.props.privilege, callFrom: 'create' } });
          flags = response5.data;
        }
        console.log(response4.data[0].subject)
        this.setState({ subject_key: response4.data[0].subject == "A Math" ? 'a' : 'e', flags: flags, level: Number(response4.data[0].level), selected_topics: response4.data[0].topics, selected_tags: response4.data[0].tags, status: response4.data[0].status, ready })

        response4.data.forEach((qn, index) => {
          const ans_alt = []
          let ans_alt_count = 0
          Object.keys(this.state.qns[0]).forEach(key => {
            if (qn[key] === undefined) {
              if (key === 'objectives' || key === 'subobjectives' || key === 'prereq_subobjectives') {
                qn[key] = []
              } else {
                qn[key] = ''
              }
            }
            if (key === 'id') {
              qn[key] = qn._id
            }
            if (key === 'original_question') {
              qn[key] = qn.original_question
            }
            if (key.slice(0, 7) === 'ans_alt') {
              if (qn[key]) {
                ans_alt.push(qn[key])
              }
              ans_alt_count += 1
            }

            if (key.slice(0, 7) === 'ans_alt' && ans_alt_count === 3) {
              this.updateState(qn[key], key, index, null, null, ans_alt)
            } else {
              this.updateState(qn[key], key, index, null)
            }
          })
          if (index !== response4.data.length - 1) { // not last qn part
            this.addPart(true)
          }
        })
      }
      if (this._isMounted && !this.state.error) {
        const response = await kopi.get('/authoring/topics', { params: { subject_key: response4.data[0].subject == "A Math" ? 'a' : 'e' } })
        const response2 = await kopi.get('/authoring/getsubobj', { params: { subject_key: response4.data[0].subject == "A Math" ? 'a' : 'e' } })
        const response3 = await kopi.get('/edit/gettags')

        this.setState({ topics: response.data, subobjs: response2.data, tags: response3.data, loaded: true, saved: null, selected_qn_index: 0 })
      }
    }

  }



  componentDidUpdate = async () => {
    const { readyNum, readyStatus } = this.saveReadyToDb()
    await kopi.post('/authoring/sendreadystatus', { status: readyStatus, readyNum: readyNum, qnId: this.state.qns[0].id })
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    clearTimeout(this.timeout)
  }
  //=======================================================================================

  // Updating state when form field is changed
  updateState = (value, name, index, files, multipleCalls, ans_alt) => {
    const indexArray = this.getIndexArray()
    indexArray.push(this.state.qns.length - 1)
    const ready = [...this.state.ready]
    if (name === 'prereq_subobjectives' && value.length && typeof value !== 'object') {
      // adding prereq from selecting them
      let qns = [...this.state.qns]
      let qn = { ...qns[index] }
      qn[name].push(JSON.parse(value))
      qns[index] = qn;
      ready[index].prereq_subobjectives = true
      if (this._isMounted) {
        this.setState({ qns, ready, selected_qn_index: indexArray[index] });
      }

    }
    else if ((name === "header_img" || name === "qn_img" || name === "sol_img") && files) {
      this.onSelectFile(value, name, index, files);
      if (name === "qn_img") {
        ready[index].question = true
      } else if (name === "sol_img") {
        ready[index].solution = true
      }
      if (this._isMounted) {
        this.setState({ ready, selected_qn_index: indexArray[index], view: name === 'sol_img' ? 'solution' : 'question' })
      }
    }
    else {
      // updating all parts of qn + retrieving from db
      let qns = [...this.state.qns]
      let qn = { ...qns[index] }

      if (name === 'units' || name === 'ans') { // rendering units properly when both ans and units fields contain $...$ latex
        let latex_present_ans = 0
        let latex_present_units = 0
        const ans_arr = this.state.qns[index].ans ? this.state.qns[index].ans.split('') : []
        const units_arr = name === 'units' ? value.split('') : this.state.qns[index].units ? this.state.qns[index].units.split('') : []
        ans_arr.forEach(ch => ch === '$' ? latex_present_ans += 1 : void (0))
        units_arr.forEach(ch => ch === '$' ? latex_present_units += 1 : void (0))
        if ((latex_present_ans && latex_present_ans % 2 === 0) && (latex_present_units && latex_present_units % 2 === 0)) {
          if (name === 'ans' && !this.state.qns[index].units.startsWith(' ')) {
            const units = ' ' + this.state.qns[index].units
            qn.units = units
          } else if (name === 'units' && !value.startsWith(' ')) {
            value = ' ' + value
          }
        }
      }

      qn[name] = value;
      qns[index] = qn;

      // updating ready status
      if (name === "qn_txt") { name = "question" }
      if (name === "sol_txt") { name = "solution" }

      if (Object.keys(ready[0]).includes(name) || name.slice(0, 7) === 'ans_alt') {
        if (name === 'question') {
          ready[index][name] = qn.qn_txt !== '' || qn.qn_img !== ''
        }
        else if (name === 'qn_type' || name === 'ans') {
          ready[index][name] = qn[name] ? true : false
        }
        else if (name === 'subobjectives') {
          ready[index][name] = qn.subobjectives.length !== 0
        }
        else if (name === 'difficulty') {
          ready[index][name] = qn.difficulty !== null ? true : false
        }
        else if (name === 'prereq_subobjectives') {
          ready[index][name] = qn.prereq_subobjectives.length !== 0
        }
        else if (name === 'solution') {
          ready[index][name] = qn.sol_txt !== '' || qn.sol_img !== ''
        }
        else if (name.slice(0, 7) === 'ans_alt') {
          if (ans_alt) {
            ready[index].ans_alt = ans_alt.length ? true : false
          } else {
            ready[index].ans_alt = qn[name] ? true : false
          }
        }
      }

      if (name === 'subobjectives' && multipleCalls) {  // saveAndClose() in LOModal calls updateState twice in a row => this ensures update from first call is not lost
        if (this._isMounted) {
          this.setState((prevState, props) => {
            qn['objectives'] = prevState.qns[index].objectives
            qns[index] = qn
            return { qns, ready, selected_qn_index: indexArray[index], view: name === 'solution' || (qn.qn_type === 'text' && name === 'ans') || (qn.qn_type === 'mcq' && name === 'units') ? 'solution' : 'question' }
          })
        }

      }
      else {
        if (this._isMounted) {
          this.setState({ qns, ready, selected_qn_index: indexArray[index], view: name === 'solution' || name === 'solution_comments' || (qn.qn_type === 'text' && name === 'ans') || (qn.qn_type === 'mcq' && name === 'units') ? 'solution' : 'question' });
        }
      }
    }
  }

  onSelectFile = async (value, name, index, files) => {
    let qns = [...this.state.qns]
    let qn = { ...qns[index] }
    if (files && files.length > 0) {
      let formData = new FormData();
      formData.append("image", files[0]);
      formData.append("question_id", qn.id);
      formData.append("field", name);
      formData.append("first_part", qns[0].id)
      await this.setState({ img_loading: true })
      let img_url = await kopi.post('/authoring/add_img', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      await this.setState({ img_loading: false })
      qn[name] = img_url.data;
      qns[index] = qn
      if (this._isMounted) {
        this.setState({ qns })
      }
    }
  }

  updateSelectedIndex = (index) => {
    this.setState({ selected_qn_index: index })
  }

  saveReadyToDb = () => {
    let readyStatus = false
    let readyNum = 0
    const ready = { level: false, topic: false, subobjectives: false, question: false, solution: false, ans: false, ans_alt: false, difficulty: false, qn_type: false, prereq_subobjectives: false }
    let hasMCQ = false // if has mcq, nd to ensure alternative ans fields are filled in, at least 1 
    let selfAssess = false
    Object.keys(this.state.ready[0]).forEach(key => {
      if (key === 'level' || key === 'topic') {
        if (this.state.ready[0][key]) { readyNum += 1 }
        ready[key] = this.state.ready[0][key]
      } else {
        let completed = true // there is sth inside the fields
        for (let i = 0; i < this.state.ready.length; i++) {
          const readyQn = this.state.ready[i]
          const qn = this.state.qns[i]

          if (qn && qn.qn_type === 'mcq') {
            hasMCQ = true
          }
          if (qn && qn.qn_type === 'self') {
            selfAssess = true
          }

          if (readyQn && qn && !readyQn[key]) {
            if (key === "ans_alt" && qn.qn_type === 'text' || ((key === 'ans_alt' || key === 'ans') && qn.qn_type === 'self')) { }
            else {
              completed = false
            }
          }
        }
        if ((completed && key === 'ans_alt' && !hasMCQ) || (completed && (key === 'ans_alt' || key === 'ans') && selfAssess)) { }
        else if (completed) {
          readyNum += 1
          ready[key] = true
        }
      }
    })

    if (readyNum === 10 || (readyNum === 9 && !ready.prereq_subobjectives) || (readyNum === 8 && !hasMCQ && !ready.prereq_subobjectives && !ready.ans_alt) || (readyNum === 9 && !hasMCQ && !ready.ans_alt) || (readyNum === 7 && selfAssess && (!ready.ans_alt && !ready.ans && !ready.prereq_subobjectives) || (readyNum === 8 && selfAssess && (!ready.ans_alt || !ready.ans || !ready.prereq_subobjectives)))) {
      readyStatus = true
    }
    return { readyNum, readyStatus, ready }
  }
  // saving changes to database on blur events
  autosave = async (value, name, id) => {
    this.setState({ saved: false })
    const response = await kopi.post('/authoring/autosave', {
      id: id,
      key: name,
      change: value,
      first_part: this.state.qns[0].id
    })
    this.updateSaveStatus(response.data)
    // let path
    // if (window.location.pathname.slice(1,5) === 'edit'){path='edit'}
    // else if (window.location.pathname.slice(1,7) === 'review'){path='review'}
    // history.push(`/${path}/${this.state.qns[0].id}`)
  }

  // deleting question parts from database
  autodelete = async (id) => {
    await kopi.delete('/authoring/deletepart', { 
    data: 
      { 
        id: id, 
        first_part: this.state.qns[0].id, 
        userId: this.props.currentUser 
      } 
    })
  }

  // saving and deleting a few specific fields to/from state and/or db
  saveLevel = async (event) => {
    let confirm = true
    if (this.state.level) {
      confirm = window.confirm('Are you sure you want to change the level? All topics and learning objectives will be lost.')
    }
    if (confirm) {
      event.persist()
      const ready = [...this.state.ready]
      ready[0].level = true
      if (this._isMounted) {
        this.setState({ level: event.target.value, selected_topics: [], levelIsOpen: false, ready })
      }
      await kopi.post('/authoring/updatetopics', { topics: [], qns: this.state.qns })
      await this.autosave(event.target.value, event.target.name, this.state.qns[0].id)
    }
  }

  saveTopic = async (event) => {
    event.persist()
    const topic = JSON.parse(event.target.value)
    let selected_topics
    const ready = [...this.state.ready]

    if (event.target.checked) {
      selected_topics = [...this.state.selected_topics]
      selected_topics.push(topic)
      ready[0].topic = true
      if (this._isMounted) {
        this.setState({ selected_topics, saved: false, ready })
      }
    }
    else {
      selected_topics = []
      this.state.selected_topics.forEach(curTopic => curTopic._id === topic._id ? null : selected_topics.push(curTopic))
      ready[0].topic = selected_topics.length !== 0
      const qns = []
      this.state.qns.forEach((qn, index) => {
        if (qn) {
          const objectives = []
          qn.objectives.forEach(obj => {
            const objIds = topic.objectives.map(objective => objective._id)
            if (objIds.includes(obj._id)) {
              const subobjectives = []
              qn.subobjectives.forEach(subobj => {
                if (subobj.objective !== obj._id) {
                  subobjectives.push(subobj)
                }
              })
              qn.subobjectives = subobjectives
              if (ready[index].subobjectives) { ready[index].subobjectives = subobjectives.length !== 0 }
            } else {
              objectives.push(obj)
            }
          })
          qn.objectives = objectives
          qns.push(qn)
        }
      })
      if (this._isMounted) {
        this.setState({ selected_topics, qns, saved: false, ready })
      }
    }
    const response = await kopi.post('/authoring/updatetopics', { topics: selected_topics, qns: this.state.qns })
    this.save(response.data)
  }

  removePrereqFromState = (index, deletePrereq) => {
    let qns = [...this.state.qns]
    let qn = { ...qns[index] }
    let prereqs = []
    const ready = [...this.state.ready]

    qn.prereq_subobjectives.forEach(prereq => prereq._id !== JSON.parse(deletePrereq)._id ? prereqs.push(prereq) : null)
    qn.prereq_subobjectives = prereqs
    ready[index].prereq_subobjectives = prereqs.length !== 0
    qns[index] = qn
    this.setState({ qns, ready })
  }

  saveTag = async (event) => {
    event.persist()
    const tag = JSON.parse(event.target.value)
    let selected_tags

    if (event.target.checked) {
      selected_tags = [...this.state.selected_tags]
      selected_tags.push(tag)
    }
    else {
      selected_tags = []
      this.state.selected_tags.forEach(curTag => curTag._id === tag._id ? null : selected_tags.push(curTag))
    }
    this.setState({ selected_tags, saved: false })

    const response = await kopi.post('/edit/updatetags', { tags: selected_tags, qnId: this.state.qns[0].id })
    this.updateSaveStatus(response.data)
  }

  //=======================================================================================

  getIndexArray = () => {
    const indexArray = []
    let curIndex = -1
    this.state.qns.forEach(qn => {
      if (qn) {
        curIndex++
        indexArray.push(curIndex)
      } else {
        indexArray.push(null)
      }
    })
    indexArray.pop()
    return indexArray
  }

  // Create new question or part
  createQuestion = async (index) => {
    const indexArray = this.getIndexArray()
    let newPart
    let prevIndex = 0
    if (index) {
      newPart = true
      prevIndex = indexArray.reverse().findIndex(curIndex => curIndex !== null)
      prevIndex = indexArray.length - 1 - prevIndex
    }
    console.log({ newPart: newPart, prevId: this.state.qns[prevIndex].id, userId: null, first_part: this.state.qns[0].id })
    const response = await kopi.post('/authoring/createquestion', { newPart: newPart, prevId: this.state.qns[prevIndex].id, userId: this.props.currentUser, first_part: this.state.qns[0].id, path: window.location.pathname })
    if (response.data) {
      this.updateState(response.data, 'id', index)
      let path
      if (window.location.pathname.slice(1, 5) === 'edit') { path = 'edit' }
      else if (window.location.pathname.slice(1, 7) === 'review') { path = 'review' }
      history.push(`/${path}/${this.state.qns[0].id}`)
      return true
    }
  }

  // Adding and deleting question parts to/from state
  addPart = (onMount) => {
    const qn = { name: '', id: '', header_txt: '', header_img: '', qn_txt: '', qn_img: '', sol_txt: '', sol_img: '', prefix: '', suffix: '', units: '', ans: '', ans_alt1: '', ans_alt2: '', ans_alt3: '', qn_type: '', has_sol: false, objectives: [], subobjectives: [], prereq_subobjectives: [], difficulty: null, question_comments: null, solution_comments: null }
    const readyCheck = { level: false, topic: false, subobjectives: false, question: false, solution: false, correct_ans: false, alt_ans: false, difficulty: false, qn_type: false, prereq_subobjectives: false }
    this.setState({ qns: [...this.state.qns, qn], ready: [...this.state.ready, readyCheck] }, async () => {
      if (!onMount) {
        this.setState({ savedNewPart: false })
        const saved = await this.createQuestion(this.state.qns.length - 1)
        if (saved) {
          this.setState({ savedNewPart: true })
        }
      }
    })
  }

  deletePart = (index) => {  // deletes by giving the element at index a null value
    const qns = [...this.state.qns]
    const ready = [...this.state.ready]
    qns[index] = null
    ready[index] = null
    this.setState({ qns, ready })
  }

  //=======================================================================================

  // Add objectives on click handler - meant to use this to grey out background when modal is open but doesn't work
  onLOClick = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  //=======================================================================================

  // Check if question is multipart
  isMulti(qns) {
    let notNull = 0
    let returnVal = false
    qns.forEach(qn => {
      if (qn) {
        notNull++
      }
      if (notNull > 1) {
        returnVal = true
      }
    })
    return returnVal
  }

  // Update save status
  updateSaveStatus = (returnVal) => {
    if (returnVal) {
      this.setState({ saved: true })
    }
    this.timeout = setTimeout(() => {
      this.setState({ saved: null })
    }, 3000)
  }



  viewFlags = () => {
    this.state.view_flags ? this.setState({ view_flags: false }) : this.setState({ view_flags: true });
  }

  renderFlags = () => {
    if (this.state.view_flags) {

      const getOffending = (qn_id) => {
        let result;
        console.log('id:', qn_id);
        this.state.qns.forEach(qn => {
          console.log('compare:', qn.id);
          if (qn.id === qn_id) {
            console.log('match!');
            result = qn.qn_txt;
          }
        })
        return result;
      }

      return <div className="alert alert-danger" role="alert">
        {this.state.flags.map((flag) => {
          return <span>
            <div><strong>{flag.subject}</strong></div>
            <div><i>{flag.details}</i></div>
            <div><small>Student: {flag.user_name}</small></div>
            <div><small>Contact: {flag.user_contact}</small></div>
            <div><small>Part: {getOffending(flag.object_id_reference)}</small> </div>
            <hr />
          </span>
        })}
      </div>
    } else {
      return <span></span>
    }
  }

  //=======================================================================================

  // Display rendering
  renderViewToggle = () => {
    const question = this.state.view === 'question' ? ' active' : ''
    const solution = this.state.view === 'solution' ? ' active' : ''
    const status = this.state.saved ? 'saved!' : (this.state.saved === false ? 'saving...' : '')

    return (
      <div clasName="container-fluid">
        <div className="row">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className={`btn btn-secondary${question}`}>
              <input type="radio" name="view" id="option" value="question" autoComplete="off" onClick={(event) => this.setState({ view: event.target.value })} /> Question                  </label>
            <label className={`btn btn-secondary${solution}`}>
              <input type="radio" name="view" id="option" value="solution" autoComplete="off" onClick={(event) => this.setState({ view: event.target.value })} /> Solution                  </label>
            <span className="" style={{ padding: "0px", paddingLeft: ".5em" }}>{status}</span>
          </div>
        </div>
      </div>
    )
  }

  renderLevelItem = () => {
    return ['Secondary One', 'Secondary Two', 'Upper Secondary'].map((level, index) => {
      return <button key={level} className="dropdown-item" type="button" name="level" value={index + 1} onClick={this.saveLevel} onBlur={() => this.setState({ levelIsOpen: false })}>{level}</button>
    })
  }

  renderTopicItem = () => {
    const topics = this.state.topics.sort(function (a, b) {
      if (a.level < b.level) {
        return -1
      } else if (a.level === b.level) {
        return a.index - b.index
      } else {
        return 1
      }
    })
    return (topics.map(topic => {
      if (topic.level === Number(this.state.level)) {
        const checked = this.state.selected_topics.find(curTopic => curTopic._id === topic._id) ? true : false
        return (
          <div className="container">
            <label className="container form-check-label" htmlFor={topic}>
              <input type="checkbox" className="dropdown-item form-check-input" checked={checked} id={topic} name="topics" value={JSON.stringify(topic)}
                onClick={(e) => this.saveTopic(e)} />
              {topic.title}
            </label>
            <br />
          </div>
        )
      } else {
        return <span></span>
      }
    }))
  }

  renderTagItem = () => {
    return (this.state.tags.map(tag => {
      const checked = this.state.selected_tags.find(curTag => curTag._id === tag._id) ? true : false
      return (
        <div className="container" key={tag._id}>
          <label className="container form-check-label" htmlFor={tag}>
            <input type="checkbox" className="dropdown-item form-check-input" checked={checked} id={tag} name="tags" value={JSON.stringify(tag)}
              onChange={this.saveTag} />
            {tag.title}
          </label>
          <br />
        </div>
      )
    }))
  }

  renderTags() {
    let tagsDropdownDisplay = ''
    if (this.state.selected_tags.length) {
      const tags = this.state.selected_tags.map(tag => tag.title)
      tagsDropdownDisplay = tags.join(', ')
    } else {
      tagsDropdownDisplay = 'Select Tags'
    }

    return (
      <div>
        <br /><Dropdown display={tagsDropdownDisplay}
          isOpen={this.state.tagIsOpen}
          updateFlag={() => this.setState({ tagIsOpen: !this.state.tagIsOpen })}
          renderListItem={this.renderTagItem}
          closeMenu={() => this.setState({ tagIsOpen: false })}
          color="btn-danger" type='tag' />
      </div>
    )
  }


  renderHeader = () => {
    return (
      <div>
        <div className="form-group">
          Header (optional)
              <TextareaAutosize
            rows={2}
            className="form-control"
            type="text"
            placeholder="enter header text that will be shared across all parts of the question"
            name="header_txt"
            value={this.state.qns[0].header_txt}
            onChange={(event) => this.updateState(event.target.value, event.target.name, 0, null)}
            onBlur={(e) => this.autosave(e.target.value, e.target.name, this.state.qns[0].id)}
          />
        </div>
        <div className="form-group">
          {/* <input className="form-control-file" type='file' name='header_img' onChange={e => this.onSelectFile(e)}  /> */}
          <input className="form-control-file" type='file' name='header_img' onChange={(event) => this.updateState(event.target.value, event.target.name, 0, event.target.files)} />
          {this.renderDeleteFileButton('header_img', 0)}
          <small>Upload header image (optional)</small>
        </div>
      </div>
    )
  }

  deleteImage = async (name, index) => {
    this.updateState('', name, index)
    await kopi.post('/authoring/delete_img', { question_id: this.state.qns[index].id, field: name, first_part: this.state.qns[0].id })
  }

  renderDeleteFileButton = (name, index) => {
    let showButton = false
    if (name === 'header_img') {
      if (this.state.qns[0].header_img) {
        showButton = true
      }
    } else {
      if (this.state.qns[index][name]) {
        showButton = true
      }
    }
    if (showButton) {
      return <span className="col-2"><button className="clear-button" onClick={() => this.deleteImage(name, index)}><i className="material-icons">close</i></button></span>
    } else {
      return <span></span>
    }
  }

  getValidAns = (isValid, index) => {
    const ready = [...this.state.ready]
    ready[index].ans = isValid ? true : false
    this.setState({ ready })
  }

  renderQnCard = () => {
    let qns = [...this.state.qns]
    let indexArray = []
    let curIndex = -1
    return qns.map((qn, index) => {
      if (qn) {
        curIndex++
        indexArray.push(curIndex)
        return <span key={index}>
          <QuestionCard
            onLOClick={this.onLOClick}
            renderDeleteFileButton={this.renderDeleteFileButton}
            updateState={this.updateState}
            addPart={this.addPart}
            deletePart={this.deletePart}
            autosave={this.autosave}
            autodelete={this.autodelete}
            removePrereqFromState={this.removePrereqFromState}
            isMulti={this.isMulti}
            qns={this.state.qns}
            index={index}
            indexArray={indexArray}
            qn={qn}
            paramId={this.props.match.params.id}
            selected_topics={this.state.selected_topics}
            subobjs={this.state.subobjs}
            edit={true}
            getValidAns={this.getValidAns}
          />

          <br />
        </span>
      } else {
        indexArray.push(null)
        return <span></span>
      }
    })
  }


  toggleLoadingState = () => {
    this.setState({loaded: !this.state.loaded})
  }

  renderLoading() {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" style={{ position: "absolute", top: "50%", left: "50%" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  render() {
    // console.log(this.props.privilege)
    // console.log(this.state.qns)
    // // console.log(this.state.selected_topics)
    const levelDropdownDisplay = this.state.level ? `Sec ${this.state.level}` : 'Select Level'
    let topicDropdownDisplay = ''
    if (this.state.selected_topics.length) {
      const topics = this.state.selected_topics.map(topic => topic.title)
      topicDropdownDisplay = topics.join(', ')
    } else {
      topicDropdownDisplay = 'Select Topic'
    }

    if (!this.state.loaded) {
      return <div>{this.renderLoading()}</div>
    }
    else if (this.state.error) {
      return <ErrorPage />
    }
    else {
      const flags = this.props.privilege === 1 ? this.renderFlags() : <span></span>
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              {window.location.pathname.slice(1, 5) === 'edit' ? <AuthorNavbar qns={this.state.qns} saveReadyToDb={this.saveReadyToDb} renderLoading={this.renderLoading} userId={this.props.currentUser} /> : <EditorNavbar toggleLoadingState={this.toggleLoadingState} viewFlags={this.viewFlags} qns={this.state.qns} saved={this.state.saved} privilege={this.props.privilege}  userId={this.props.currentUser}/>}
              {flags}
              <fieldset disabled={window.location.pathname.slice(1, 5) === 'edit'}>
                {this.renderTags()}
              </fieldset>
              <fieldset disabled={window.location.pathname.slice(1, 7) === 'lol'}>
                <br /><Dropdown display={levelDropdownDisplay}
                  isOpen={this.state.levelIsOpen}
                  updateFlag={() => this.setState({ levelIsOpen: !this.state.levelIsOpen })}
                  closeMenu={() => this.setState({ levelIsOpen: false })}
                  renderListItem={this.renderLevelItem}
                  color="btn-dark" />

                <br /><Dropdown display={topicDropdownDisplay}
                  isOpen={this.state.topicIsOpen}
                  updateFlag={() => this.state.level ? this.setState({ topicIsOpen: !this.state.topicIsOpen }) : alert('Please select a level.')}
                  renderListItem={this.renderTopicItem}
                  closeMenu={() => this.setState({ topicIsOpen: false })}
                  color="btn-dark" type="topic" /><br />

                {this.renderHeader()}
              </fieldset>
              {this.renderQnCard()}
              <br /><br /><br /><br />
            </div>
            <StickyContainer>
              <Sticky>
                {({ style }) =>
                  <div className="col-lg-4 col-md-5" style={style}>
                    <br />{this.renderViewToggle()}<br />
                    <Smartphone selectedIndex={this.state.selected_qn_index} updateSelectedIndex={this.updateSelectedIndex} isLoading={this.state.img_loading} qns={this.state.qns} view={this.state.view} isMulti={this.isMulti} /><br /><br /><br />
                  </div>}
              </Sticky>
            </StickyContainer>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    isSignedIn: state.auth.isSignedIn,
    privilege: Number(state.auth.privilege),
  };
};

export default connect(mapStateToProps, { signOut })(EditQuestion);