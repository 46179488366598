import React from 'react'
import { Sticky, StickyContainer } from 'react-sticky'
import PopoverStickOnHover from './PopoverStickOnHover'
import TextareaAutosize from 'react-autosize-textarea';

import Smartphone from '../preview/Smartphone'
import QuestionCard from './QuestionCard'
import Dropdown from './Dropdown'
import AuthorNavbar from './AuthorNavbar'
import EditorNavbar from '../edit/EditorNavbar'
import ErrorPage from './Error'
import history from '../../history'

import { connect } from 'react-redux';
import { signOut } from '../../actions';

import kopi from '../../apis/kopi';
import '../Author.css';
import { ResponsiveEmbed } from 'react-bootstrap';
import Contact from '../Contact';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-140836063-2');
ReactGA.pageview(window.location.pathname + window.location.search);


class CreateQuestion extends React.Component {
  _isMounted = false;
  state = {
    subject_key: '',
    flags: [],
    view_flags: false,
    img_loading: false,
    qns: [{
      name: '',
      year: null,
      source: null,
      paper: null,
      qn_num: null,
      id: '',
      original_question: '',
      header_txt: '',
      header_img: '',
      qn_txt: '',
      qn_img: '',
      sol_txt: '',
      sol_img: '',
      prefix: '',
      suffix: '',
      units: '',
      ans: '',
      ans_alt1: '',
      ans_alt2: '',
      ans_alt3: '',
      qn_type: '',
      has_sol: false,
      subobjectives: [],
      tags: [],
      objectives: [],
      prereq_subobjectives: [],
      difficulty: null,
      question_comments: null, 
      solution_comments: null,
      weightage: null,
    }],
    level: 0,
    topics: [],
    subobjs: [],
    status: '',  // Draft, Review, Rejected, Published
    selected_topics: [],
    selected_qn_index: 0,  // updated in parent component (which qn card author is currently editing)
    view: 'question',  // 'question' or 'solution'
    levelIsOpen: false,
    topicIsOpen: false,
    modalOpen: false, // sans but
    ready: [{ level: false, topic: false, subobjectives: false, question: false, solution: false, ans: false, ans_alt: false, difficulty: false, qn_type: false, prereq_subobjectives: false }],
    //
    loaded: false,
    savedNewPart: true,
    saved: null, // saving status message thingy
    error: false,
  }

  //=======================================================================================

  // Updating state with topics, subobjectives and questions fetched from database
  componentDidMount = async () => {

    this._isMounted = true;

    let subject_key;
    if (this.props.match.params.id == "a" || this.props.match.params.id == "e") {
      subject_key = this.props.match.params.id;
      this.createQuestion(0, this.props.match.params.id)
      if (this._isMounted) {
        this.setState({ status: 'Draft', level: subject_key == 'e' ? null : 3 })
      }
    } else if (this.props.match.params.id) {
      const ready = [...this.state.ready]
      if (this._isMounted) {
        this.updateState(this.props.match.params.id, 'id', 0, null)
      }
      const response3 = await kopi.get('/authoring/getquestion', { params: { id: this.props.match.params.id, path: window.location.pathname, privilege: this.props.privilege, callFrom: 'create' } });
      // console.log(response3.data)
      if (response3.data === 'error') {
        this.setState({ error: true, loaded: true })
      } else {
        let flags = [];
        if (this.props.privilege === 1) {
          const response4 = await kopi.get('/authoring/getflags', { params: { id: this.props.match.params.id, path: window.location.pathname, privilege: this.props.privilege, callFrom: 'create' } });
          flags = response4.data;
        }

        ready[0].level = response3.data[0].level ? true : false
        ready[0].topic = response3.data[0].topics.length !== 0
        if (this._isMounted) {
          let subject = response3.data[0].subject;
          switch (subject) {
            case "A Math":
              subject_key = "a";
              break;
            case "E Math":
              subject_key = "e";
              break;
            default:
              window.alert(subject)
              subject_key = "e";
          }
          this.setState({ subject_key, flags: flags, tags: response3.data[0].tags, level: response3.data[0].level, selected_topics: response3.data[0].topics, status: response3.data[0].status, ready })
        }

        response3.data.forEach((qn, index) => {
          const ans_alt = []
          let ans_alt_count = 0
          Object.keys(this.state.qns[0]).forEach(key => {
            if (qn[key] === undefined) {
              if (key === 'objectives' || key === 'subobjectives' || key === 'prereq_subobjectives') {
                qn[key] = []
              } else {
                qn[key] = ''
              }
            }
            if (key === 'id') {
              qn[key] = qn._id
            }
            if (key === 'original_question') {
              qn[key] = qn.original_question
            }
            if (key.slice(0, 7) === 'ans_alt') {
              if (qn[key]) {
                ans_alt.push(qn[key])
              }
              ans_alt_count += 1
            }

            if (key.slice(0, 7) === 'ans_alt' && ans_alt_count === 3) {
              this.updateState(qn[key], key, index, null, null, ans_alt)
            } else {
              this.updateState(qn[key], key, index, null)
            }

          })
          if (index !== response3.data.length - 1) { // not last qn part
            this.addPart(true)
          }
        })
      }

    } else {
      this.createQuestion(0, 'e')
      subject_key = 'e';
      if (this._isMounted) {
        this.setState({ status: 'Draft' })
      }
    }

    if (this._isMounted && !this.state.error) {
      const response = await kopi.get('/authoring/topics', { params: { subject_key: subject_key } })
      const response2 = await kopi.get('/authoring/getsubobj', { params: { subject_key: subject_key } })
      this.setState({ subject_key, topics: response.data, subobjs: response2.data, loaded: true, saved: null, selected_qn_index: 0 })
    }
  }

  componentDidUpdate = async () => {
    const { readyNum, readyStatus } = this.saveReadyToDb()
    kopi.post('/authoring/sendreadystatus', { status: readyStatus, readyNum: readyNum, qnId: this.state.qns[0].id, path: window.location.pathname })
  }

  componentWillMount = () => {
    if (!this.props.isSignedIn) {
      history.push('/');
    }
  }

  componentWillUnmount = () => {
    // console.log('unmounting')
    this._isMounted = false;
    clearTimeout(this.timeout)
  }

  //=======================================================================================

  // Updating state when form field is changed
  updateState = (value, name, index, files, multipleCalls, ans_alt) => {
    if (this._isMounted) {
      const indexArray = this.getIndexArray()
      indexArray.push(this.state.qns.length - 1)
      const ready = [...this.state.ready]
      if (name === 'prereq_subobjectives' && value.length && typeof value !== 'object') {
        // adding prereq from selecting them
        let qns = [...this.state.qns]
        let qn = { ...qns[index] }
        qn[name].push(JSON.parse(value))
        qns[index] = qn;
        ready[index].prereq_subobjectives = true
        this.setState({ qns, ready, selected_qn_index: indexArray[index] });
      }
      else if ((name === "header_img" || name === "qn_img" || name === "sol_img") && files) {
        this.onSelectFile(value, name, index, files);
        if (name === "qn_img") {
          ready[index].question = true
        } else if (name === "sol_img") {
          ready[index].solution = true
        }
        this.setState({ ready, selected_qn_index: indexArray[index], view: name === 'sol_img' ? 'solution' : 'question' })
      }
      else {
        // updating all parts of qn + retrieving from db
        let qns = [...this.state.qns]
        let qn = { ...qns[index] }
        if (name === 'units' || name === 'ans') { // rendering units properly when both ans and units fields contain $...$ latex
          let latex_present_ans = 0
          let latex_present_units = 0
          const ans_arr = this.state.qns[index].ans ? this.state.qns[index].ans.split('') : []
          const units_arr = name === 'units' ? value.split('') : this.state.qns[index].units ? this.state.qns[index].units.split('') : []
          ans_arr.forEach(ch => ch === '$' ? latex_present_ans += 1 : void (0))
          units_arr.forEach(ch => ch === '$' ? latex_present_units += 1 : void (0))
          if ((latex_present_ans && latex_present_ans % 2 === 0) && (latex_present_units && latex_present_units % 2 === 0)) {
            if (name === 'ans' && !this.state.qns[index].units.startsWith(' ')) {
              const units = ' ' + this.state.qns[index].units
              qn.units = units
            } else if (name === 'units' && !value.startsWith(' ')) {
              value = ' ' + value
            }
          }
        }
        qn[name] = value;
        qns[index] = qn;

        // updating ready status
        if (name === "qn_txt") { name = "question" }
        if (name === "sol_txt") { name = "solution" }

        if (Object.keys(ready[0]).includes(name) || name.slice(0, 7) === 'ans_alt') {
          if (name === 'question') {
            ready[index][name] = qn.qn_txt !== '' || qn.qn_img !== ''
          }
          else if (name === 'qn_type' || name === 'ans') {
            ready[index][name] = qn[name] ? true : false
          }
          else if (name === 'subobjectives') {
            ready[index][name] = qn.subobjectives.length !== 0
          }
          else if (name === 'difficulty') {
            ready[index][name] = qn.difficulty !== null ? true : false
          }
          else if (name === 'prereq_subobjectives') {
            ready[index][name] = qn.prereq_subobjectives.length !== 0
          }
          else if (name === 'solution') {
            ready[index][name] = qn.sol_txt !== '' || qn.sol_img !== ''
          }
          else if (name.slice(0, 7) === 'ans_alt') {
            if (ans_alt) {
              ready[index].ans_alt = ans_alt.length ? true : false
            } else {
              ready[index].ans_alt = qn[name] ? true : false
            }
          }
        }

        if (name === 'subobjectives' && multipleCalls) {  // saveAndClose() in LOModal calls updateState twice in a row => this ensures update from first call is not lost
          this.setState((prevState, props) => {
            qn['objectives'] = prevState.qns[index].objectives
            qns[index] = qn
            return { qns, ready, selected_qn_index: indexArray[index], view: name === 'solution' || (qn.qn_type === 'text' && name === 'ans') || (qn.qn_type === 'mcq' && name === 'units') ? 'solution' : 'question' }
          })
        }
        else {
          this.setState({ qns, ready, selected_qn_index: indexArray[index], view: name === 'solution' || (qn.qn_type === 'text' && name === 'ans') || (qn.qn_type === 'mcq' && name === 'units') ? 'solution' : 'question' });
        }
      }
    }
  }

  onSelectFile = async (value, name, index, files) => {
    let qns = [...this.state.qns]
    let qn = { ...qns[index] }
    if (files && files.length > 0) {
      let formData = new FormData();
      formData.append("image", files[0]);
      formData.append("question_id", qn.id);
      formData.append("field", name);
      formData.append("first_part", qns[0].id)
      formData.append("path", window.location.pathname)
      await this.setState({ img_loading: true });
      let img_url = await kopi.post('/authoring/add_img', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      await this.setState({ img_loading: false });
      qn[name] = img_url.data;
      qns[index] = qn
      if (this._isMounted) {
        this.setState({ qns })
      }
    }
  }

  getValidAns = (isValid, index) => {
    const ready = [...this.state.ready]
    ready[index].ans = isValid ? true : false
    this.setState({ ready })
  }

  updateSelectedIndex = (index) => {
    this.setState({ selected_qn_index: index })
  }

  saveReadyToDb = () => {
    let readyStatus = false
    let readyNum = 0
    const ready = { level: false, topic: false, subobjectives: false, question: false, solution: false, ans: false, ans_alt: false, difficulty: false, qn_type: false, prereq_subobjectives: false }
    let hasMCQ = false // if has mcq, nd to ensure alternative ans fields are filled in, at least 1 
    let selfAssess = false
    Object.keys(this.state.ready[0]).forEach(key => {
      if (key === 'level' || key === 'topic') {
        if (this.state.ready[0][key]) { readyNum += 1 }
        ready[key] = this.state.ready[0][key]
      } else {
        let completed = true // there is sth inside the fields
        for (let i = 0; i < this.state.ready.length; i++) {
          const readyQn = this.state.ready[i]
          const qn = this.state.qns[i]

          if (qn && qn.qn_type === 'mcq') {
            hasMCQ = true
          }
          if (qn && qn.qn_type === 'self') {
            selfAssess = true
          }

          if (readyQn && qn && !readyQn[key]) {
            if (key === "ans_alt" && qn.qn_type === 'text' || ((key === 'ans_alt' || key === 'ans') && qn.qn_type === 'self')) { }
            else {
              completed = false
            }
          }
        }
        if ((completed && key === 'ans_alt' && !hasMCQ) || (completed && (key === 'ans_alt' || key === 'ans') && selfAssess)) { }
        else if (completed) {
          readyNum += 1
          ready[key] = true
        }
      }
    })

    if (readyNum === 10 || (readyNum === 9 && !ready.prereq_subobjectives) || (readyNum === 8 && !hasMCQ && !ready.prereq_subobjectives && !ready.ans_alt) || (readyNum === 9 && !hasMCQ && !ready.ans_alt) || (readyNum === 7 && selfAssess && (!ready.ans_alt && !ready.ans && !ready.prereq_subobjectives) || (readyNum === 8 && selfAssess && (!ready.ans_alt || !ready.ans || !ready.prereq_subobjectives)))) {
      readyStatus = true
    }
    return { readyNum, readyStatus, ready }
  }

  // saving changes to database on blur events
  autosave = async (value, name, id) => {
    this.setState({ saved: false })
    const response = await kopi.post('/authoring/autosave', {
      id: id,
      key: name,
      change: value,
      first_part: this.state.qns[0].id,
      path: window.location.pathname
    })
    this.save(response.data) // this is for activating saving msg
  }

  // deleting question parts from database
  autodelete = async (id) => {
    await kopi.delete('/authoring/deletepart', { 
      data: { 
        id: id, 
        first_part: this.state.qns[0].id, 
        userId: this.props.currentUser 
      } 
    })
  }

  // saving and deleting a few specific fields to/from state and/or db
  saveLevel = async (event) => {
    let confirm = true
    if (this.state.level) {
      confirm = window.confirm('Are you sure you want to change the level? All topics and learning objectives will be lost.')
    }
    if (confirm) {
      event.persist()
      const ready = [...this.state.ready]
      ready[0].level = true
      if (this._isMounted) {
        this.setState({ level: event.target.value, selected_topics: [], levelIsOpen: false, ready })
      }
      await kopi.post('/authoring/updatetopics', { topics: [], qns: this.state.qns, path: window.location.pathname })
      await this.autosave(event.target.value, event.target.name, this.state.qns[0].id)
    }
  }

  saveTopic = async (event) => {
    event.persist()
    const topic = JSON.parse(event.target.value)
    let selected_topics
    const ready = [...this.state.ready]

    if (event.target.checked) {
      selected_topics = [...this.state.selected_topics]
      selected_topics.push(topic)
      ready[0].topic = true
      if (this._isMounted) {
        this.setState({ selected_topics, saved: false, ready })
      }
    }
    else {
      selected_topics = []
      this.state.selected_topics.forEach(curTopic => curTopic._id === topic._id ? null : selected_topics.push(curTopic))
      ready[0].topic = selected_topics.length !== 0
      const qns = []
      this.state.qns.forEach((qn, index) => {
        if (qn) {
          const objectives = []
          qn.objectives.forEach(obj => {
            const objIds = topic.objectives.map(objective => objective._id)
            if (objIds.includes(obj._id)) {
              const subobjectives = []
              qn.subobjectives.forEach(subobj => {
                if (subobj.objective !== obj._id) {
                  subobjectives.push(subobj)
                }
              })
              qn.subobjectives = subobjectives
              if (ready[index].subobjectives) { ready[index].subobjectives = subobjectives.length !== 0 }
            } else {
              objectives.push(obj)
            }
          })
          qn.objectives = objectives
          qns.push(qn)
        }
      })
      if (this._isMounted) {
        this.setState({ selected_topics, qns, saved: false, ready })
      }
    }
    const response = await kopi.post('/authoring/updatetopics', { topics: selected_topics, qns: this.state.qns, path: window.location.pathname })
    this.save(response.data)
  }

  removePrereqFromState = (index, deletePrereq) => {
    let qns = [...this.state.qns]
    let qn = { ...qns[index] }
    let prereqs = []
    const ready = [...this.state.ready]

    qn.prereq_subobjectives.forEach(prereq => prereq._id !== JSON.parse(deletePrereq)._id ? prereqs.push(prereq) : null)
    qn.prereq_subobjectives = prereqs
    ready[index].prereq_subobjectives = prereqs.length !== 0
    qns[index] = qn
    this.setState({ qns, ready })
  }

  //=======================================================================================

  getIndexArray = () => {
    const indexArray = []
    let curIndex = -1
    this.state.qns.forEach(qn => {
      if (qn) {
        curIndex++
        indexArray.push(curIndex)
      } else {
        indexArray.push(null)
      }
    })
    indexArray.pop()
    return indexArray
  }

  // Create new question or part
  createQuestion = async (index, subject_key) => {
    const indexArray = this.getIndexArray()
    let subject;
    switch (subject_key) {
      case 'a':
        subject = 'A Math';
        break;
      case 'e':
        subject = 'E Math';
        break;
      default:
        subject = 'E Math';
    }
    let newPart
    let prevIndex = 0
    if (index) {
      newPart = true
      prevIndex = indexArray.reverse().findIndex(curIndex => curIndex !== null)
      prevIndex = indexArray.length - 1 - prevIndex
    }
    const response = await kopi.post('/authoring/createquestion', {
      subject, newPart: newPart, prevId: this.state.qns[prevIndex].id,
      userId: this.props.currentUser, first_part: this.state.qns[0].id,
      path: window.location.pathname
    })
    if (response.data) {
      this.updateState(response.data, 'id', index)
      history.push(`/create/${this.state.qns[0].id}`)
      return true
    }
  }

  // Adding and deleting question parts to/from state
  addPart = (onMount) => {
    const qn = { name: '', id: '', header_txt: '', header_img: '', qn_txt: '', qn_img: '', sol_txt: '', sol_img: '', prefix: '', suffix: '', units: '', ans: '', ans_alt1: '', ans_alt2: '', ans_alt3: '', qn_type: '', has_sol: false, objectives: [], subobjectives: [], prereq_subobjectives: [], difficulty: null, question_comments: null, solution_comments: null }
    const readyCheck = { level: false, topic: false, subobjectives: false, question: false, solution: false, correct_ans: false, alt_ans: false, difficulty: false, qn_type: false, prereq_subobjectives: false }
    if (this._isMounted) {
      this.setState({ qns: [...this.state.qns, qn], ready: [...this.state.ready, readyCheck] }, async () => {
        // console.log('added ready:', this.state.ready)
        if (!onMount) {
          if (this._isMounted) {
            this.setState({ savedNewPart: false })
          }
          const saved = await this.createQuestion(this.state.qns.length - 1, this.state.subject_key);
          if (saved) {
            if (this._isMounted) {
              this.setState({ savedNewPart: true })
            }
          }
        }
      })
    }
  }

  deletePart = (index) => {  // deletes by giving the element at index a null value
    const qns = [...this.state.qns]
    const ready = [...this.state.ready]
    qns[index] = null
    ready[index] = null
    this.setState({ qns, ready })
  }

  deleteImage = async (name, index) => {
    this.updateState('', name, index)
    await kopi.post('/authoring/delete_img', { question_id: this.state.qns[index].id, field: name, first_part: this.state.qns[0].id, path: window.location.pathname })
  }

  //=======================================================================================

  // Add objectives on click handler - meant to use this to grey out background when modal is open but doesn't work
  onLOClick = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  //=======================================================================================

  // Check if question is multipart
  isMulti(qns) {
    let notNull = 0
    let returnVal = false
    qns.forEach(qn => {
      if (qn) {
        notNull++
      }
      if (notNull > 1) {
        returnVal = true
      }
    })
    return returnVal
  }

  // Updating save status state
  save = (data) => {
    if (this._isMounted) {
      if (data) { // returns true
        this.setState({ saved: true })
      }
    }
    this.timeout = setTimeout(() => {
      this.setState({ saved: null })
      // // console.log('time out done')
    }, 3000)
  }

  viewFlags = () => {
    this.state.view_flags ? this.setState({ view_flags: false }) : this.setState({ view_flags: true });
  }

  renderFlags = () => {
    if (this.state.view_flags) {

      const getOffending = (qn_id) => {
        let result;
        console.log('id:', qn_id);
        this.state.qns.forEach(qn => {
          console.log('compare:', qn.id);
          if (qn.id === qn_id) {
            console.log('match!');
            result = qn.qn_txt;
          }
        })
        return result;
      }

      return <div className="alert alert-danger" role="alert">
        {this.state.flags.map((flag) => {
          return <span>
            <div><strong>{flag.subject}</strong></div>
            <div><i>{flag.details}</i></div>
            <div><small>Student: {flag.user_name}</small></div>
            <div><small>Contact: {flag.user_contact}</small></div>
            <div><small>Part: {getOffending(flag.object_id_reference)}</small> </div>
            <hr />
          </span>
        })}
      </div>
    } else {
      return <span></span>
    }
  }

  //=======================================================================================

  // Display rendering
  renderViewToggle = () => {
    let status
    if (this.state.saved) {
      status = 'saved!'
    } else if (this.state.saved === false) {
      status = 'saving...'
    } else {
      status = ''
    }

    const question = this.state.view === 'question' ? ' active' : ''
    const solution = this.state.view === 'solution' ? ' active' : ''
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ padding: "0px" }}>
            <label className={`btn btn-secondary${question}`}>
              <input type="radio" name="view" id="option" value="question" autoComplete="off" onClick={(event) => this.setState({ view: event.target.value })} /> Question                </label>
            <label className={`btn btn-secondary${solution}`}>
              <input type="radio" name="view" id="option" value="solution" autoComplete="off" onClick={(event) => this.setState({ view: event.target.value })} /> Solution                </label>
            <span className="" style={{ padding: "0px", paddingLeft: ".5em" }}>{status}</span>
          </div>
        </div>
      </div>

    )
  }

  renderLevelItem = () => {
    return ['Secondary One', 'Secondary Two', 'Upper Secondary'].map((level, index) => {
      return <button key={level} className="dropdown-item" type="button" name="level" value={index + 1} onClick={this.saveLevel} onBlur={() => this.setState({ levelIsOpen: false })}>{level}</button>
    })
  }

  renderTopicItem = () => {
    const topics = this.state.topics.sort(function (a, b) {
      if (a.level < b.level) {
        return -1
      } else if (a.level === b.level) {
        return a.index - b.index
      } else {
        return 1
      }
    })
    return (topics.map(topic => {
      if (topic.level === Number(this.state.level)) {
        const checked = this.state.selected_topics.find(curTopic => curTopic._id === topic._id) ? true : false
        return (
          <div className="container" key={topic._id}>
            <label className="container form-check-label" htmlFor={topic._id}>
              <input type="checkbox" className="dropdown-item form-check-input" defaultChecked={checked} id={topic._id} name="topics" value={JSON.stringify(topic)}
                onClick={(e) => this.saveTopic(e)} />
              {topic.title}
            </label>
            <br />
          </div>
        )
      } else {
        return <span key={topic._id}></span>
      }
    }))
  }

  renderDeleteFileButton = (name, index) => {
    let showButton = false
    if (name === 'header_img') {
      if (this.state.qns[0].header_img) {
        showButton = true
      }
    } else {
      if (this.state.qns[index][name]) {
        showButton = true
      }
    }
    if (showButton) {
      return <span className="col-2"><button className="clear-button" onClick={() => this.deleteImage(name, index)}><i className="material-icons">close</i></button></span>
    } else {
      return <span></span>
    }
  }


  renderHeader = () => {
    return (
      <div>
        <div className="form-group">
          Header (optional)
          <TextareaAutosize rows={2}
            className="form-control"
            type="text"
            placeholder="enter header text that will be shared across all parts of the question"
            name="header_txt"
            value={this.state.qns[0].header_txt}
            onChange={(event) => this.updateState(event.target.value, event.target.name, 0, null)}
            onBlur={(e) => this.autosave(e.target.value, e.target.name, this.state.qns[0].id)} />
        </div>
        <div className="form-group">
          {/* <input className="form-control-file" type='file' name='header_img' onChange={e => this.onSelectFile(e)}  /> */}
          <span className="row justify-content-start">
            <input className="form-control-file col-6" type='file' name='header_img' onChange={(event) => this.updateState(event.target.value, event.target.name, 0, event.target.files)} />
            {this.renderDeleteFileButton('header_img', 0)}
          </span>
          <small>Upload header image (optional)</small>
        </div>
      </div>
    )
  }

  renderMetaData = () => {
    return (
      <div>
        <div className="form-group">
          Year
          <input
            className="form-control"
            type="text"
            placeholder="Enter year of question"
            name="year"
            value={this.state.qns[0].year}
            onChange={(event) => this.updateState(event.target.value, event.target.name, 0, null)}
            onBlur={(e) => this.autosave(e.target.value, e.target.name, this.state.qns[0].id)} />
        </div>
        <div className="form-group">
          Source
          <input
            className="form-control"
            type="text"
            placeholder="Enter where question is from."
            name="source"
            value={this.state.qns[0].source}
            onChange={(event) => this.updateState(event.target.value, event.target.name, 0, null)}
            onBlur={(e) => this.autosave(e.target.value, e.target.name, this.state.qns[0].id)} />
        </div>
        <div className="form-group">
          Paper
          <input
            className="form-control"
            type="Number"
            placeholder="Paper number"
            name="paper"
            value={this.state.qns[0].paper}
            onChange={(event) => this.updateState(event.target.value, event.target.name, 0, null)}
            onBlur={(e) => this.autosave(e.target.value, e.target.name, this.state.qns[0].id)} />
        </div>
        <div className="form-group">
          Question Number
          <input
            className="form-control"
            type="Number"
            placeholder="Question number"
            name="qn_num"
            value={this.state.qns[0].qn_num}
            onChange={(event) => this.updateState(event.target.value, event.target.name, 0, null)}
            onBlur={(e) => this.autosave(e.target.value, e.target.name, this.state.qns[0].id)} />
        </div>
      </div>
    )
  }

  renderQnCard = () => {
    let qns = [...this.state.qns]
    let indexArray = []
    let curIndex = -1
    return qns.map((qn, index) => {
      if (qn && (this.state.savedNewPart || (!this.state.savedNewPart && index !== this.state.qns.length - 1))) {
        curIndex++
        indexArray.push(curIndex)
        return <span key={qn.id}>
          <QuestionCard
            onLOClick={this.onLOClick}
            updateState={this.updateState}
            addPart={this.addPart}
            deletePart={this.deletePart}
            autosave={this.autosave}
            autodelete={this.autodelete}
            removePrereqFromState={this.removePrereqFromState}
            isMulti={this.isMulti}
            qns={this.state.qns}
            renderDeleteFileButton={this.renderDeleteFileButton}
            index={index}
            indexArray={indexArray}
            createQuestion={this.createQuestion}
            qn={qn}
            selected_topics={this.state.selected_topics}
            subobjs={this.state.subobjs}
            edit={false}
            getValidAns={this.getValidAns}
            userId={this.props.currentUser} />
          <br />
        </span>
      } else if (!this.state.savedNewPart && index === this.state.qns.length - 1) {
        return (
          <div className="card" style={{ width: "18rem" }}>
            <div className="card-body">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        )
      } else {
        indexArray.push(null)
        return <span></span>
      }
    })
  }

  toggleLoadingState = () => {
    this.setState({loaded: !this.state.loaded})
  }

  renderLoading() {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" style={{ position: "absolute", top: "50%", left: "50%" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  renderTags = () => {
    if (this.state.tags) {
      //console.log(this.state.tags)
      if (this.state.tags.length !== 0) {
        return (<span><strong>These are the tags the editor has added for this question:</strong> {this.state.tags.map(tag => <li> {tag.title} (+20%) </li>)}
        </span>)
      } else {
        return <span></span>
      }
    }
  }

  render() {
    // console.log(this.state.qns)
    // console.log(this.state.ready)
    //// console.log(this.state.selected_topics)
    console.log(this.state.view)
    const levelArray = ['Secondary One', 'Secondary Two', 'Upper Secondary']
    const levelDropdownDisplay = this.state.level ? levelArray[this.state.level - 1] : 'Select Level'
    let topicDropdownDisplay = ''
    if (this.state.selected_topics.length) {
      const topics = this.state.selected_topics.map(topic => topic.title)
      topicDropdownDisplay = topics.join(', ')
    } else {
      topicDropdownDisplay = 'Select Topic'
    }

    if (!this.state.loaded) {
      return <div>{this.renderLoading()}</div>
    } else if (this.state.error) {
      return <ErrorPage />
    } else {
      const navbar = this.props.privilege === 1 ?
        <EditorNavbar viewFlags={this.viewFlags} qns={this.state.qns} saved={this.state.saved} privilege={this.props.privilege} userId={this.props.currentUser} toggleLoadingState={this.toggleLoadingState}/> : 
        <AuthorNavbar qns={this.state.qns} saveReadyToDb={this.saveReadyToDb} renderLoading={this.renderLoading} userId={this.props.currentUser} />
      const flags = this.props.privilege === 1 ? this.renderFlags() : <span></span>
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              {navbar}
              {flags}
              <fieldset disabled={window.location.pathname.slice(1, 7) !== 'create'}>
                <br />Name <small>(optional, for your own reference and display only)</small>
                <h4>{this.state.subject_key.toUpperCase()} Math</h4>

                <input className="form-control" type='text' value={this.state.qns[0].name} name='name' onChange={(e) => this.updateState(e.target.value, e.target.name, 0)} onBlur={(e) => this.autosave(e.target.value, e.target.name, this.state.qns[0].id)} />

                <br /><Dropdown display={levelDropdownDisplay}
                  isOpen={this.state.levelIsOpen}
                  updateFlag={() => this.setState({ levelIsOpen: !this.state.levelIsOpen })}
                  renderListItem={this.renderLevelItem}
                  closeMenu={() => this.setState({ levelIsOpen: false })}
                  color="btn-dark" />

                <br /><Dropdown display={topicDropdownDisplay}
                  isOpen={this.state.topicIsOpen}
                  updateFlag={() => this.state.level ? this.setState({ topicIsOpen: !this.state.topicIsOpen }) : alert('Please select a level.')}
                  renderListItem={this.renderTopicItem}
                  closeMenu={() => this.setState({ topicIsOpen: false })}
                  color="btn-dark" type="topic" /><br />

                {this.props.currentUser === '5cde0767f1a1203958228704' ? this.renderMetaData(): ''}
                {this.renderHeader()}
                {this.renderQnCard()}
              </fieldset>
              <br /><br /><br /><br />
            </div>
            <StickyContainer>
              <Sticky>
                {({ style }) =>
                  <div className="col-lg-4 col-md-5" style={style}>
                    <br />{this.renderViewToggle()}<br />
                    <Smartphone
                      isLoading={this.state.img_loading}
                      qns={this.state.qns}
                      view={this.state.view}
                      isMulti={this.isMulti}
                      selectedIndex={this.state.selected_qn_index}
                      updateSelectedIndex={this.updateSelectedIndex} />
                    <br />
                  </div>}
              </Sticky>
            </StickyContainer>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    isSignedIn: state.auth.isSignedIn,
    privilege: Number(state.auth.privilege),
  };
};

export default connect(mapStateToProps, { signOut })(CreateQuestion);