import React from 'react';

import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

import Loading from '../dashboard/Loading'

import '../Author.css';

const Question = props => {
    let seperator = "\${1,2}";
    let header_txt, header_img, qn_txt, qn_img;

    if (props.qn){
        if (!props.multi){  // single part must render header
            if (props.qn.header_txt) {
                if (props.qn.header_txt.startsWith("\\begin")){
                    header_txt = <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={props.qn.header_txt} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>    
                } else {
                    header_txt = <div><InlineTex texSeperator={seperator} texContent={props.qn.header_txt} /></div>
                }
            }  else {
                header_txt = <span>Your header will appear here.</span>
            }  
    
            if (props.qn.header_img === '') {  // if there is question image, render question image
                header_img = <span></span>
            } else {
                header_img = <div><img className="img-fluid" src={`${props.qn.header_img}`} alt="lol" /></div>
            }
        } 

        if (props.isLoading){
            header_img = <Loading />
        }
    
        if (props.qn.qn_txt) {  // if there is question text, render question text
            if (props.qn.qn_txt.startsWith("\\begin")){
                qn_txt = <div className="table-responsive">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td>
                                <Tex texContent={props.qn.qn_txt} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>    
            } else {
                qn_txt = <div><InlineTex texSeperator={seperator} texContent={props.qn.qn_txt} /></div>
            }
        } else {
            qn_txt = <span>Your question will appear here.</span>
        }
    
        if (props.qn.qn_img === '') {  // if there is question image, render question image
            qn_img = <span></span>
        } else {
            qn_img = <div><img className="img-fluid" src={`${props.qn.qn_img}`} alt="lol" /></div>
        }
    }
    
    
    return (  // render full question to page - will not show header text if multipart bcos multipart component will render header alr
        <span>          
            <p className="question-text">{header_txt}</p>
            {header_img} 
            {qn_img}
            <p className="question-text">{qn_txt}</p>
        </span>
    )
}

export default Question