import {FILTER_LEVEL, FILTER_TOPIC, SIGN_IN, SIGN_OUT} from './types';

import kopi from '../apis/kopi';
import history from '../history'; 

export const filterLevel = (level, topic) => dispatch => {
    dispatch({type: FILTER_LEVEL, payload: [level,topic]});
};

export const filterTopic = topic => dispatch => {
    dispatch({type: FILTER_TOPIC, payload: topic});
};

export const signOut = () => {
    history.push('/');
    return {
        type: SIGN_OUT
    };
};

export const signIn = formValues => async (dispatch) => { // remember that both dispatch and getState are passed to reducer
    kopi.post('/login', {
        username: formValues.email, password: formValues.password
    })
    .then(response=>{
        if (response.data === "verify email") {
            history.push('/verify');
        } else if (!response.data) {
            history.push({pathname: '/login', state: { wrongInfo: true }})
        } else if (response.data === 'wrong'){
            history.push({pathname: '/login', state: { wrongInfo: true }})
        } else {
            dispatch({type: SIGN_IN, payload: response.data});
            history.push('/dashboard');
        }
    })
    .catch(err=>{
        history.push({pathname: '/login', state: { wrongInfo: true }})
    })
    
};

export const signUp = formValues => async (dispatch) => { // remember that both dispatch and getState are passed to reducer
    // console.log("sign up")
    const response = await kopi.post('/register', {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        date_of_birth: formValues.date_of_birth,
        email: formValues.email,
        password: formValues.password
    });
    if (response.data){
        dispatch({type: SIGN_IN, payload: response.data});
        history.push('/verify');
    } else {
        history.push({pathname: '/onboarding', state: { email: false } });
    }
};

